import React, { useEffect } from "react";
import { useState } from "react";
import ViewRemarks from "./ViewRemarks";
import Modal from "../../Common/CommonModal";
import { getReemarks } from "../../Utils/ApiManager";
import Loader from "../../Common/Loader/Loader";

function QuickUpdatesProviders(props) {
  const [ProviderDataforForm, setProviderData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remarksArray, setRemarksArray] = useState();
  const [btnloader, setBtnLoader] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);

  console.log("ProviderDataforForm", ProviderDataforForm);

  useEffect(() => {
    setProviderData(props.data);
  }, [props.data]);

  const getRemarks = async (re) => {
    console.log(re);
    setBtnLoader(true);
    try {
      let payload = {
        payer_id: re.payer_id,
        provider_id: re.provider_id,
      };
      const responce = await getReemarks(payload);
      console.log(responce);
      setRemarksArray(responce?.data);
      setBtnLoader(false);
      setLoadingIndex(null);
      if (responce.status == 200) {
        openModal();
      }
    } catch (error) {
      console.log(error, "errorR");
      setBtnLoader(false);
      setLoadingIndex(null);
    }
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div className="mb-3">
        <div className="providerBg">
          <div className="row">
            <div className="col-sm-12">
              <div class="smHd02">
                <div>Provider Info</div>
                <div className="iconBlue"></div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="patientBgSummary">
                <div className="SummaryBlock">
                  <div className="label">Provider Full Name</div>
                  <div className="entity">{ProviderDataforForm?.name}</div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Provider Lname</div>
                  <div className="entity">{ProviderDataforForm?.last_name}</div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">SSN</div>
                  <div className="entity">{ProviderDataforForm?.ssn}</div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Individual NPI</div>
                  <div className="entity">
                    {" "}
                    {ProviderDataforForm?.individual_npi}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">DOB</div>
                  <div className="entity">{ProviderDataforForm?.dob}</div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Tax ID</div>
                  <div className="entity">{ProviderDataforForm?.tax_id}</div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">phone #/Cell #</div>
                  <div className="entity">
                    {false ? (
                      ProviderDataforForm?.cell
                    ) : (
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    )}
                  </div>
                </div>
                <div className="SummaryBlock">
                  {/* <div className="label">This filed in pending</div>
                  <div className="entity">No</div> */}
                </div>
                <div className="SummaryBlock">
                  <div className="label">Provider Email</div>
                  <div className="entity">
                    {false ? (
                      ProviderDataforForm?.email
                    ) : (
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    )}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Ind Medicare ID</div>
                  <div className="entity">
                    {ProviderDataforForm?.medicare_id}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Ind Medicad ID</div>
                  <div className="entity">
                    {ProviderDataforForm?.medicaid_id}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Grp Medicaid ID</div>
                  <div className="entity">
                    {ProviderDataforForm?.grp_madicaid_id}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Speciality</div>
                  <div className="entity">
                    {ProviderDataforForm?.speciality}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Group MRC ID</div>
                  <div className="entity">
                    {ProviderDataforForm?.grp_mrc_id}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Medicaid User</div>
                  <div className="entity">
                    {ProviderDataforForm?.medicaid_user}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Gender</div>
                  <div className="entity">{ProviderDataforForm?.gender}</div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Office Mgr Name</div>
                  <div className="entity">
                    {ProviderDataforForm?.manager_name}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Office Mgr Email</div>
                  <div className="entity">
                    {ProviderDataforForm?.manager_email}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Website</div>
                  <div className="entity">{ProviderDataforForm?.website}</div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 mt-2">
              <div class="smHd02">
                <div>Services</div>
                <button></button>
              </div>

              {ProviderDataforForm &&
                ProviderDataforForm.locations.map((field) => {
                  return (
                    <>
                      <div class="moreServices">
                        <div class="row">
                          <div class="col-sm-6 col-lg-3">
                            <div class="form-group form-inline mb-2">
                              <label class="inputLabel">Service Loc :</label>
                              <p>{field?.service_location}</p>
                            </div>
                          </div>
                          <div class="col-sm-6 col-lg-3">
                            <div class="form-group form-inline mb-2">
                              <label class="inputLabel">Service phone #</label>
                              {field?.phone}
                            </div>
                          </div>
                          <div class="col-sm-6 col-lg-3">
                            <div class="form-group form-inline mb-2">
                              <label class="inputLabel">Service Fax #</label>
                              {field?.fax}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>

            <div className="col-sm-12">
              <div class="smHd02">DEA</div>
            </div>
            <div className="col-sm-12">
              <div className="patientBgSummary">
                <div className="SummaryBlock">
                  <div className="label">Certification #</div>
                  <div className="entity">
                    {ProviderDataforForm?.dea_certification_number}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Effective</div>
                  <div className="entity">
                    {ProviderDataforForm?.dea_effective_date?.split("T")[0]}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Expiry</div>
                  <div className="entity">
                    {ProviderDataforForm?.dea_expiry_date?.split("T")[0]}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12">
              <div class="smHd02">Board Certification</div>
            </div>
            <div className="col-sm-12">
              <div className="patientBgSummary">
                <div className="SummaryBlock">
                  <div className="label">Certification #</div>
                  <div className="entity">
                    {ProviderDataforForm?.board_certification}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Effective</div>
                  <div className="entity">
                    {ProviderDataforForm?.board_effective_date?.split("T")[0]}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Expiry</div>
                  <div className="entity">
                    {ProviderDataforForm?.dea_expiry_date?.split("T")[0]}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12">
              <div class="smHd02">State License</div>
            </div>
            <div className="col-sm-12">
              <div className="patientBgSummary">
                <div className="SummaryBlock">
                  <div className="label">License#</div>
                  <div className="entity">
                    {ProviderDataforForm?.state_license}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Effective</div>
                  <div className="entity">
                    {ProviderDataforForm?.state_license_date?.split("T")[0]}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Expiry</div>
                  <div className="entity">
                    {ProviderDataforForm?.state_license_expiry?.split("T")[0]}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12">
              <div class="smHd02">Home Address</div>
            </div>
            <div className="col-sm-12">
              <div className="patientBgSummary">
                <div className="SummaryBlock">
                  <div className="label">Street</div>
                  <div className="entity">
                    {" "}
                    {ProviderDataforForm?.home_street}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Suite/Other</div>
                  <div className="entity">
                    {" "}
                    {ProviderDataforForm?.home_suite}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">City</div>
                  <div className="entity">
                    {" "}
                    {ProviderDataforForm?.home_city}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">State</div>
                  <div className="entity">
                    {" "}
                    {ProviderDataforForm?.home_state}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">County</div>
                  <div className="entity">
                    {" "}
                    {ProviderDataforForm?.home_county}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Zip</div>
                  <div className="entity"> {ProviderDataforForm?.home_zip}</div>
                </div>
              </div>
            </div>

            <div className="col-sm-12">
              <div class="smHd02">Billing/Mailing Address</div>
            </div>
            <div className="col-sm-12">
              <div className="patientBgSummary">
                <div className="SummaryBlock">
                  <div className="label">Street</div>
                  <div className="entity">
                    {" "}
                    {ProviderDataforForm?.mailing_street}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Suite/Other</div>
                  <div className="entity">
                    {ProviderDataforForm?.mailing_suite}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">City</div>
                  <div className="entity">
                    {ProviderDataforForm?.mailing_city}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">State</div>
                  <div className="entity">
                    {ProviderDataforForm?.mailing_state}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">County</div>
                  <div className="entity">
                    {ProviderDataforForm?.mailing_county}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">Zip</div>
                  <div className="entity">
                    {ProviderDataforForm?.mailing_zip}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12">
              <div class="smHd02">Web Portal Access:</div>
            </div>
            <div className="col-sm-12">
              <div className="patientBgSummary">
                <div className="SummaryBlock">
                  <div className="label">CAQH User</div>
                  <div className="entity">{ProviderDataforForm?.caqh_user}</div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">CAQH Pass</div>
                  <div className="entity">{ProviderDataforForm?.caqh_pass}</div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">CAQH ID</div>
                  <div className="entity">{ProviderDataforForm?.caqh_id}</div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">PECOS User</div>
                  <div className="entity">
                    {ProviderDataforForm?.pecos_user}
                  </div>
                </div>
                <div className="SummaryBlock">
                  <div className="label">PECOS Pass</div>
                  <div className="entity">
                    {ProviderDataforForm?.pecos_pass}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        {ProviderDataforForm?.payer_providers?.map((el, index) => {
          let value = index;

          return (
            <div className="addmoreBg">
              <div className="smHd02 p-2">
                <div>Payer</div>
              </div>
              <div className="d-flex ">
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group form-inline mb-2">
                    <label
                      class="inputLabel  "
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Payer Name
                    </label>

                    <>{el?.Payer?.name}</>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group form-inline mb-2">
                    <label
                      class="inputLabel  "
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Status
                    </label>
                    {el?.status}
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group form-inline  mb-2">
                    <label
                      class="inputLabel  "
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Payment Date
                    </label>
                    <div> </div>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group form-inline mb-2">
                    <label
                      class="inputLabel  "
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Submit Date
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex ">
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group form-inline mb-2">
                    <label
                      class="inputLabel  "
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Provider Identifier
                    </label>
                    {el?.provider_identifier}
                  </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                  <div class="form-group form-inline  mb-2">
                    <label
                      class="inputLabel  "
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Effective date
                    </label>
                    {el?.effective_date?.split("T")[0]}
                  </div>
                </div>
                <div class="col-sm-6 col-lg-12">
                  <div class="form-group form-inline  mb-2">
                    <label
                      class="inputLabel  "
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Remarks
                    </label>

                    {el.id != "" && el.payer_id != "" ? (
                      <>
                        {" "}
                        <div className="d-flex justify-content-end mt-3">
                          <button
                            type="button"
                            className="btnSave mb-3"
                            onClick={() => {
                              setLoadingIndex(index);
                              getRemarks(el);
                            }}
                          >
                            {btnloader && loadingIndex === index ? (
                              <Loader small={true} large={false} />
                            ) : (
                              <i
                                class="fa fa-eye  "
                                aria-hidden="true"
                                style={{ color: "white" }}
                              ></i>
                            )}
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal isOpen={isModalOpen} closeModal={closeModal} title={"Remarks"}>
        <ViewRemarks data={remarksArray || ""} />{" "}
      </Modal>
    </div>
  );
}

export default QuickUpdatesProviders;
