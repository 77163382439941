import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Jan 2023', value: 10 },
  { name: 'Jan 2022', value: 10 },
  { name: 'Feb 2022', value: 20 },
  { name: 'Mar 2022', value: 15 },
  { name: 'Apr 2023', value: 25 },
  { name: 'May 2023', value: 18 },
  { name: 'Jun 2023', value: 30 },
  { name: 'Jul 2023', value: 22 },
  { name: 'Aug 2023', value: 17 },
  { name: 'Sep 2023', value: 28 },
  { name: 'Oct 2023', value: 12 },
  { name: 'Nov 2023', value: 20 },
  { name: 'Dec 2023', value: 15 },
];

const FilterButtons = ({ handleFilter }) => {
  return (
		<div className="mb-3">
			<button className="btnStyle_outline" onClick={() => handleFilter("all")}>
				All
			</button>
			<button
				className="btnStyle_outline"
				onClick={() => handleFilter("month")}
			>
				This Month
			</button>
			<button
				className="btnStyle_outline btnOutlineActive"
				onClick={() => handleFilter("year")}
			>
				This Year
			</button>
		</div>
	);
};

const BarChartComponent = () => {
  const [filteredData, setFilteredData] = useState(data);

  const handleFilter = (filter) => {
    let filteredResult = data;

    if (filter === 'month') {
      filteredResult = data.filter(item => {
        const date = new Date(item.name);
        return date.getMonth() === new Date().getMonth();
      });
    } else if (filter === 'year') {
      const selectedYear = window.prompt('Enter a year:');
      filteredResult = data.filter(item => {
        const date = new Date(item.name);
        return date.getFullYear() === parseInt(selectedYear, 10);
      });
    } else if (filter === 'all') {
      filteredResult = data;
    }

    setFilteredData(filteredResult);
  };

  const getColor = (value) => {
    if (value >= 20) {
      return '#FF0000'; // Red color for values >= 20
    } else if (value >= 10) {
      return '#00FF00'; // Green color for values >= 10 and < 20
    } else {
      return '#0000FF'; // Blue color for values < 10
    }
  };

  return (
		<div className="">
			<div className="card">
				<div className="card-body">
					<FilterButtons handleFilter={handleFilter} />
					<div className="chart-container">
						<BarChart width={550} height={400} data={filteredData}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="name" />
							<YAxis />
							<Tooltip />
							<Legend />
							{/* <Bar
								dataKey="value"
								fill={(_, index) => getColor(filteredData[index].value)}
							/> */}
							<Bar dataKey="value" fill="#46A8DF" />
						</BarChart>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BarChartComponent;
