import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Layout from "./../../Components/layout/Layout";
import Dashboard from "../Dashboard/Dashboard";
import PageNotFound from "../PageNotFound/PageNotFound";
import Login from "../Login/Login";
import PublicLayout from "../Publiclayout";
import { useSelector } from "react-redux";
import ViewDocument from "../Documents/viewDocument/viewDocument";
import ViewPayer from "../Payers/viewPayer/viewPayer";
import ViewUsers from "../Users/viewUsers/ViewUsers";
import InactiveUsers from "../Users/InactiveUsers/InactiveUsers";
import AppStatusReport from "../Reports/AppStatusReport/AppStatusReport";
import UserProductivity from "../Reports/UserProductivity/UserProductivity";
import QuickMain from "../QuickUpdates/QuickMain";
import ViewAllEntriesData from "../../Common/ViewAllRecords/ViewAllEntriesData";
import PracticeMain from "../Practice/ViewAllPractices/PracticeMainForm";
import ProviderMainForm from "../Providers/ProviderMainForm";
import ViewEdiRecordMainForm from "../NAST/ViewAllEdirecord/ViewEdiRecordMainForm";

import LogDailyActivity from "../Activity/LogdailyActivity/LogDailyActivity";
import TeamWiseReports from "../Reports/TeamwiseReport/TeamwiseReport";
import { useState } from "react";
import AuditRemarks from "../Audits/Remarks/AuditRemarks";
import ProvidersAudits from "../Audits/Providers/ProvidersAudits";
import UsersAudits from "../Audits/Users/UsersAudits";

const MainRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [addNewButton, setAddNewButton] = useState("");
  const addNew = (name) => {
    setAddNewButton(name);
  };

  let { onLoginCredentialing } = useSelector((state) => state);

  useEffect(() => {
    if (!onLoginCredentialing?.accessToken && location.pathname !== "/") {
      navigate("/");
    }
  }, [onLoginCredentialing, location, navigate]);

  if (onLoginCredentialing?.accessToken) {
    return (
      <>
        {
          <Routes>
            <Route exact="true" path="/" element={<Layout />}>
              <Route path="/" exact="true" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/QuickUpdateForm1" element={<QuickMain />} />

              <Route path="/Practices">
                <Route
                  path="ViewPractices"
                  element={
                    <ViewAllEntriesData
                      title={"Add New Practice"}
                      addNew={addNew}
                      component={
                        <PracticeMain
                          addNewButton={addNewButton}
                          addNew={addNew}
                          name={"View Practice"}
                        />
                      }
                      name={"View Practice"}
                    />
                  }
                />
              </Route>

              <Route path="/NAST">
                <Route
                  path="ViewAllEntry"
                  element={
                    <ViewAllEntriesData
                      addNew={addNew}
                      title={"Add Edi Record"}
                      component={
                        <ViewEdiRecordMainForm
                          addNewButton={addNewButton}
                          addNew={addNew}
                          name={"View All Entries"}
                        />
                      }
                      name={"View All Entries"}
                    />
                  }
                />
              </Route>
              <Route path="/Providers">
                <Route
                  path="ViewAllProividers"
                  element={
                    <ViewAllEntriesData
                      title={"Add New Provider"}
                      addNew={addNew}
                      component={
                        <ProviderMainForm
                          addNewButton={addNewButton}
                          addNew={addNew}
                          name={"View All Proividers"}
                        />
                      }
                      name={"View All Proividers"}
                    />
                  }
                />
              </Route>
              <Route path="/Document">
                <Route
                  path="ViewAllDocument"
                  element={
                    <ViewAllEntriesData
                      addNew={addNew}
                      title={"Add New Document"}
                      component={
                        <ViewDocument
                          name={"View All Document"}
                          addNewButton={addNewButton}
                          addNew={addNew}
                        />
                      }
                      name={"View All Document"}
                    />
                  }
                />
              </Route>
              <Route path="/Payers">
                <Route
                  path="ViewAllPayers"
                  element={
                    <ViewAllEntriesData
                      title={"Add New Payer"}
                      addNew={addNew}
                      component={
                        <ViewPayer
                          addNewButton={addNewButton}
                          addNew={addNew}
                          name={"View All Payers"}
                        />
                      }
                      name={"View All Payers"}
                    />
                  }
                />
              </Route>
              <Route path="/Users">
                <Route
                  path="ViewAllUsers"
                  element={
                    <ViewAllEntriesData
                      title={"Add New User"}
                      addNew={addNew}
                      component={
                        <ViewUsers
                          addNewButton={addNewButton}
                          addNew={addNew}
                          name={"View All Users"}
                        />
                      }
                      name={"View All Users"}
                    />
                  }
                />

                {/* <Route path="InactiveUsers" element={<InactiveUsers />} /> */}
              </Route>
              <Route path="/Reports">
                <Route
                  path="AppstatusReports"
                  element={
                    <ViewAllEntriesData
                      title={""}
                      component={<AppStatusReport />}
                      name={"Providers' Application Status Report"}
                    />
                  }
                />

                <Route
                  path="TeamWiseReports"
                  element={
                    <ViewAllEntriesData
                      title={""}
                      component={<TeamWiseReports />}
                      name={"Team Wise Reports"}
                    />
                  }
                />
                <Route
                  path="UserProductivity"
                  element={
                    <ViewAllEntriesData
                      title={""}
                      component={<UserProductivity />}
                      name={"User Productivity Report"}
                    />
                  }
                />
              </Route>

              <Route path="/Audit">
                <Route
                  path="AuditRemarks"
                  element={
                    <ViewAllEntriesData
                      title={""}
                      component={<AuditRemarks />}
                      name={"Remarks Audits"}
                    />
                  }
                />
                <Route
                  path="ProvidersAudits"
                  element={
                    <ViewAllEntriesData
                      title={""}
                      component={<ProvidersAudits />}
                      name={"Providers Audits"}
                    />
                  }
                />

                <Route
                  path="UsersAudits"
                  element={
                    <ViewAllEntriesData
                      title={""}
                      component={<UsersAudits />}
                      name={"Users Audits"}
                    />
                  }
                />
              </Route>

              <Route path="Activity">
                <Route
                  path="AllActivites"
                  element={
                    <ViewAllEntriesData
                      title={""}
                      component={<UserProductivity />}
                      name={"View All Activites"}
                    />
                  }
                />
                <Route
                  path="LogDailyActivity"
                  element={
                    <ViewAllEntriesData
                      title={""}
                      component={<LogDailyActivity />}
                      name={"Log Daily Activity"}
                    />
                  }
                />
              </Route>
            </Route>
            <Route>
              {" "}
              <Route path="/*" element={<PageNotFound />} />
            </Route>
          </Routes>
        }
      </>
    );
  } else {
    return (
      <>
        <Routes>
          <Route exact="true" path="/" element={<PublicLayout />}>
            <Route path="/" element={<Login />} />
          </Route>
        </Routes>
      </>
    );
  }
};

export default MainRoutes;
