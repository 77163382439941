import React, { useState, useEffect } from "react";
import AddNewProvider from "./AddNewProvider";
import Modal from "../../Common/CommonModal";
import Select from "react-select";
import {
  deleteProvider,
  getDataForviewDate,
  getProviderRecords,
  getUsaSatesCodes,
  updateProviderStatus,
} from "../../Utils/ApiManager";
import DataTable from "../../Common/RsuitDataGrid/userTaskViewTable";
import { useDispatch, useSelector } from "react-redux";
import { statesCodes } from "../../Actions/Actions";
import Toggle from "rsuite/Toggle";
import { toast } from "react-toastify";
import ViewPayerRemarks from "./viewPayer";
import { Loader } from "rsuite";
import Swal from "sweetalert2";

function ProviderMainForm(props) {
  // State variables using useState hook
  const [formD, setFormD] = useState({
    practice_id: "",
  });
  const [formData, setFormData] = useState({
    id: 0,
    state: useSelector((state) => state),
    dispatch: useDispatch(),
    providerData: [],
    paginationData: {
      totalPages: 0,
      limit: 10,
      page: 1,
      count: 0,
    },
    taxanomySpeciality: [],
    Loadin: false,
    isModalOpenRemark: false,
    loading: false,
    dataForEdit: "",
    IdForloader: "",
    ProviderDataforForm: "",
    options: [],
    practice_id: "",
    status: "",
    startDate: "",
    endDate: "",
    specilaity: "",
    usaStatesOptions: "",
    UsaState: "",
    selectedOption: null,
  });

  // Custom styles for react-select component
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)",
        cursor: "pointer",
        color: "white",
      },
    }),
  };

  // Fetch data on initial render and when pagination changes
  useEffect(() => {
    const payload = {
      page: Number(formData.paginationData.page),
      limit: Number(formData.paginationData.limit),
      practice_id: formD.practice_id ? formD.practice_id : "",
      taxonomy: formData.specilaity ? formData.specilaity : "",
      status: formData.status ? formData.status : "",
      home_state: formData.UsaState ? formData.UsaState : "",
      date_from: formData.startDate ? formData.startDate : "",
      date_to: formData.endDate ? formData.endDate : "",
    };
    if (
      payload.practice_id !== "" ||
      payload.taxonomy !== "" ||
      payload.status !== "" ||
      payload.home_state !== "" ||
      payload.date_from !== "" ||
      payload.date_to !== ""
    ) {
      fetchData(payload);
    }
    fetchData(payload);
    fetchUsaStates();
  }, [formData.paginationData.page, formData.paginationData.limit]);

  // Fetch data on initial render
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      options: formData.state?.practiceName ?? [],
    }));

    extractspecilaites();
  }, []);

  // Extract and format specialities
  const extractspecilaites = () => {
    if (formData.state?.Specialities.length > 0) {
      const formattedOptions = formData.state?.Specialities?.map((item) => ({
        value: item.taxonomy,
        label: item.taxonomy,
      }));
      setFormData((prevFormData) => ({
        ...prevFormData,
        taxanomySpeciality: formattedOptions,
      }));
    }
  };

  // Handle selection change for Practice dropdown
  const handleChangeselect = (selectedValue, selectedOption) => {
    setFormD((prevFormData) => ({
      ...prevFormData,
      selectedOption,
      practice_id: selectedValue,
    }));
  };

  // Handle Active/Inactive status change for a provider
  const handelActive = (id, status) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      loading: true,
      id: Number(id),
    }));
    const obj = {
      id: Number(id),
      status,
    };

    updateProviderStatus(obj)
      .then((res) => {
        toast(
          status === "active"
            ? "User Successfully Inactive"
            : "User Successfully Activated"
        );
        setFormData((prevFormData) => ({
          ...prevFormData,
          loading: false,
        }));
        fetchData();
      })
      .catch((err) => {
        toast(err);
      });
  };

  // Handle State selection change
  const handleStateChange = (selectedValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      UsaState: selectedValue.value,
    }));
  };

  // Handle Speciality selection change
  const handleChangeForSpecilaity = (selectedValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      specilaity: selectedValue.label,
    }));
  };

  // Handle Status selection change
  const handleChangeForStatus = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: value,
    }));
  };

  // Fetch USA states data
  const fetchUsaStates = () => {
    try {
      getUsaSatesCodes().then((res) => {
        const formattedOptions = res.data.StatesCodes.map((item) => ({
          value: item.code,
          label: item.state,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          usaStatesOptions: formattedOptions,
        }));
        formData.dispatch(statesCodes(res.data));
      });
    } catch (error) {
      // Handle error if any
    }
  };

  // Handle provider record deletion
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this record?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          Loadin: true,
        }));
        deleteProvider(Number(id))
          .then((res) => {
            setFormData((prevFormData) => ({
              ...prevFormData,
              Loadin: false,
            }));
            toast("Deleted Successfully");
            fetchData();
          })
          .catch((err) => {
            setFormData((prevFormData) => ({
              ...prevFormData,
              Loadin: false,
            }));
            toast.info(err?.message);
          });
      }
    });
  };

  // Handle provider record editing
  const handleEdit = async (id) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dataForEdit: { id, type: "edit" },
    }));
    props.addNew(props.name);
  };

  // Handle filter button click
  const handleFilter = () => {
    const payload = {
      page: Number(1),
      limit: Number(10),
      practice_id: formD.practice_id ? formD.practice_id : "",
      taxonomy: formData.specilaity ? formData.specilaity : "",
      status: formData.status ? formData.status : "",
      home_state: formData.UsaState ? formData.UsaState : "",
      date_from: formData.startDate ? formData.startDate : "",
      date_to: formData.endDate ? formData.endDate : "",
    };
    if (
      payload.practice_id !== "" ||
      payload.taxonomy !== "" ||
      payload.status !== "" ||
      payload.home_state !== "" ||
      payload.date_from !== "" ||
      payload.date_to !== ""
    ) {
      fetchData(payload);
    }
  };

  // Fetch provider data based on filters
  const fetchData = async (payload) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      providerData: [],
      Loadin: true,
    }));
    try {
      const data = await getProviderRecords(payload);
      console.log(data?.data?.providers);
      const newArray = data?.data?.providers?.map((ob) => ({
        ...ob,
        statusid: `${ob.status}  ${ob.id}`,
        practice_name: !ob.practice_name
          ? formData.state?.practiceName?.find((i) =>
              i.value ? i.value === ob?.practice_id : ""
            )?.label || ""
          : ob.practice_name,
      }));

      setFormData((prevFormData) => ({
        ...prevFormData,
        providerData: newArray,
        paginationData: {
          ...prevFormData.paginationData,
          count: data.data.totalCount,
        },
        Loadin: false,
      }));
    } catch (error) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        Loadin: false,
      }));
    }
  };

  // Fetch provider data for modal
  const fetchProviderData = (id) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      IdForloader: id,
    }));
    let obj = {
      id: id,
      name: "",
    };
    getDataForviewDate(obj)
      .then((res) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ProviderDataforForm: res.data,
          IdForloader: "",
          isModalOpenRemark: true,
        }));
      })
      .catch((error) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          IdForloader: "",
        }));
        toast("Error While Fetching");
      });
  };

  const handleChangeLimit = (newLimit) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      paginationData: {
        ...prevFormData.paginationData,
        limit: newLimit,
        page: 1,
      },
    }));
  };

  const handleChangePage = (newPage) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      paginationData: {
        ...prevFormData.paginationData,
        page: newPage,
      },
    }));
  };

  const closeModal = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dataForEdit: { id: "", type: "" },
    }));
    props.addNew("");
  };

  const closeModalRemarks = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      isModalOpenRemark: false,
    }));
  };

  const arr = [
    { colName: "Practice Name", flexGrow: 1.6, dataKey: "practice_name" },
    { colName: "Individual Npi", flexGrow: 1.0, dataKey: "individual_npi" },
    { colName: "Provider Name", flexGrow: 0.9, dataKey: ["full_name"] },
    {
      colName: "Email",
      flexGrow: 1.2,
      dataKey: "id",
      CustomCell: ({ data }) => {
        let dsta = formData.providerData?.find((item) => item?.id == data);
        console.log(dsta);
        return (
          <div>
            {false ? (
              dsta?.email
            ) : (
              <>
                <div>
                <i class="fa fa-eye-slash" aria-hidden="true"></i>
                </div>
              </>
            )}
          </div>
        );
      },
    },

    { colName: "Status", flexGrow: 0.4, dataKey: "status" },
    {
      colName: "Created Date",
      flexGrow: 0.7,
      dataKey: "created_at",
      CustomCell: ({ data }) => {
        return <div>{data?.split("T")[0]}</div>;
      },
    },
    {
      colName: "Action",
      flexGrow: 0.8,
      dataKey: "statusid",
      CustomCell: ({ data }) => {
        return (
          <div className="d-flex">
            {formData.IdForloader === data?.match(/\d+/)[0] ? (
              <Loader size="xs" />
            ) : (
              <i
                className="fa fa-info-circle iconCol01 pe-2"
                onClick={() => {
                  fetchProviderData(data?.match(/\d+/)[0]);
                }}
              />
            )}
            <i
              className="fas fa-pencil-alt iconCol02 pe-2"
              onClick={() => {
                handleEdit(data?.match(/\d+/)[0]);
              }}
            />
            <i
              className="fas fa-trash iconCol03 pe-2"
              onClick={() => {
                handleDelete(data?.match(/\d+/)[0]);
              }}
            />
            {
              <Toggle
                loading={
                  formData.id === +data?.match(/\d+/)[0]
                    ? formData.loading
                    : false
                }
                checked={data?.match(/[a-zA-Z]+/)[0] === "active"}
                checkedChildren={
                  data?.match(/[a-zA-Z]+/)[0] === "active"
                    ? "Active"
                    : undefined
                }
                unCheckedChildren={
                  data?.match(/[a-zA-Z]+/)[0] !== "active"
                    ? "InActive"
                    : undefined
                }
                size="sm"
                onClick={() => {
                  handelActive(
                    data?.match(/\d+/)[0],
                    data?.match(/[a-zA-Z]+/)[0]
                  );
                }}
              />
            }
          </div>
        );
      },
    },
  ];

  const handleActionbtn = (row) => {
    debugger;
  };

  const handleChangeForDate = (event) => {
    const { name, value } = event.target;
    if (name === "start") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        startDate: value,
      }));
    }
    if (name === "end") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        endDate: value,
      }));
    }
  };

  const handleClear = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      specilaity: "",
      practice_id: "",
      status: "",
      startDate: "",
      endDate: "",
      selectedOption: null,
      UsaState: "",
    }));

    const emptyPayload = {
      page: 1,
      limit: 10,
      practice_id: "",
      taxonomy: "",
      status: "",
      home_state: "",
      date_from: "",
      date_to: "",
    };

    fetchData(emptyPayload);
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Practices</label>
            <Select
              styles={customStyles}
              options={formData.options}
              value={formData.selectedOption ? formData.selectedOption : ""}
              placeholder="Select an option"
              onChange={(selectedOption) =>
                handleChangeselect(Number(selectedOption.value), selectedOption)
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Status</label>
            <Select
              options={[
                { value: "active", label: "Active" },
                { value: "inactive", label: "Inactive" },
              ]}
              placeholder={"Select an option"}
              styles={customStyles}
              value={formData.status ? { label: formData.status } : ""}
              onChange={(selectedOption) =>
                handleChangeForStatus(
                  selectedOption.value === "active" ? "active" : "inactive"
                )
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Start Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="start"
              placeholder=""
              value={formData.startDate}
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">End Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="end"
              value={formData.endDate}
              placeholder=""
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Specialities</label>
            <Select
              options={formData.taxanomySpeciality}
              placeholder="Select an option"
              styles={customStyles}
              value={formData.specilaity ? { label: formData.specilaity } : ""}
              onChange={(val) => {
                handleChangeForSpecilaity(val);
              }}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">States</label>
            <Select
              options={formData.usaStatesOptions}
              placeholder="Select an option"
              value={formData.UsaState ? { label: formData.UsaState } : ""}
              styles={customStyles}
              onChange={(value) => handleStateChange(value)}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mt-2">
            <div className="d-flex align-items-center mt-4">
              <button className="btnClear mr-1" onClick={handleFilter}>
                Filter
              </button>
              <button type="button" onClick={handleClear} className="btnSave">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        Array={arr}
        dateForgrid={formData.providerData}
        loader={formData.Loadin}
        onChangePop={handleActionbtn}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        totalPages={formData.paginationData.count}
        wordWrap={false}
      />

      {props.addNewButton === props.name && (
        <Modal isOpen={true} closeModal={closeModal} title="Add New Provider">
          <AddNewProvider
            dataForEdit={formData.dataForEdit}
            closeModal={closeModal}
            usaStatesOptions={formData.usaStatesOptions}
          />
        </Modal>
      )}

      {formData.isModalOpenRemark && (
        <Modal
          isOpen={formData.isModalOpenRemark}
          closeModal={closeModalRemarks}
          title={"Provider Information"}
        >
          <ViewPayerRemarks data={formData.ProviderDataforForm || ""} />
        </Modal>
      )}
    </>
  );
}

export default ProviderMainForm;
