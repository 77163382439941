import React from "react";
import './Nast.css'


import ViewAllRecordMain from "./ViewAllRecordMainForm";





function ViewAllEntriesData(props) {



  return (
    <>
    

        <div className="row">

          <div className={"col-lg-12"}>
            <div className="p-3 navcolor bg-white rounded shadow mb-">
              <div>
                <ViewAllRecordMain {...props} />
              </div>
            </div>
          </div>
        </div>


    

    </>
  );
}



export default ViewAllEntriesData
