import React from "react";

export default function Modal({ isOpen, closeModal, children,title }) {
  return (
		<div
			className={`modal  fade${isOpen ? " show d-flex" : ""}`}
			style={{ background: "#121212ad" }}
			role="dialog"
			aria-labelledby="exampleModalLabel"
			aria-hidden={!isOpen}
		>
			
			{isOpen && (
				<div
					className="modal-dialog  modal-lg"
					role="document"
				>
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{title}</h5>
							<button
								type="button"
								className="close"
								onClick={closeModal}
								style={{ marginTop: "-13px" }}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div
							className="modal-body"
							style={{ overflowY: "auto", maxHeight: "550px" }}
						>
							<div>{children}</div>
						</div>
						{/* <div className="modal-footer">
							<button type="button" className="btnClear" onClick={closeModal}>
								Close
							</button>
						</div> */}
					</div>
				</div>
			)}
		</div>
	);
}
