import React, { useEffect, useState } from "react";
import Select from "react-select";
import { TimePicker } from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { timeVariable, formVariable, applicationStatus } from "./variables ";
import { createProvider, getDataProviderDate } from "../../Utils/ApiManager";
import { useSelector } from "react-redux";
import { vlidation } from "./validation";
import { toast } from "react-toastify";
import { formatPhoneNumber } from "./phoneformate";

const AddProviderForm = ({ practiceId, dataForEdit, closeModal, practiceValidation, handleChangeselect, usaStatesOptions }) => {
  const [timeVariables, setTimeVariable] = useState(timeVariable)
  const [formVariables, setFormVariables] = useState(formVariable)
  const { payerName, onLoginCredentialing } = useSelector((state) => state)
  // Application status options
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)",
        cursor: "pointer",
        color: "white",
      },
    }),
  };
  const [activeTab, setActiveTab] = useState(true);
  // State variables
  let objj = {
    individual_npi: false,
    last_name: false,
    name: false,
    email: false
  }
  const [deleteServiceFields, setDeleteServiceFields] = useState([]);
  const [deleteWebFields, setDeleteWebFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payerDelete, setForPayerDelete] = useState('');
  const [remarksCompare, setremarksCompare] = useState([])
  const [webFields, setWebFields] = useState([
    { loginweb: "", loginuser: "", loginpass: "", additional_information: "" },
  ]);

  const [payerFields, setPayerFields] = useState([
    { payer_id: "", effective_date: "", status: "", provider_identifier: '', remarks: "", remarksid: 0, id: 0 },
  ]);
  const [error, setError] = useState(objj)
  const [serviceFields, setServiceFields] = useState(
    [{ service_location: "", phone: "", fax: "", id: 0 }]
  );

  useEffect(() => {
    if (dataForEdit?.type == 'edit') {
      fetchData(dataForEdit.id)
    }
  }, [dataForEdit?.id])

  //fetch data for edit 
  const fetchData =  (id) => {
    setLoading(true)
     getDataProviderDate(id).then((res) => {

      setLoading(false)
      let data = res.data
      const {
        mon_hours,
        tue_hours,
        wed_hours,
        thu_hours,
        fri_hours,
        sat_hours,
        sun_hours,
        practice_id,
        practice_name
      } = data.provider;
      let label = { label: practice_name }
      handleChangeselect(practice_id, label)

      setTimeVariable({
        mon_open: mon_hours ? (mon_hours.split('@')[0] === '00:00' ? '' : mon_hours.split('@')[0]) : '',
        mon_close: mon_hours ? (mon_hours.split('@')[1] === '00:00' ? '' : mon_hours.split('@')[1]) : '',
        tue_open: tue_hours ? (tue_hours.split('@')[0] === '00:00' ? '' : tue_hours.split('@')[0]) : '',
        tue_close: tue_hours ? (tue_hours.split('@')[1] === '00:00' ? '' : tue_hours.split('@')[1]) : '',
        wed_open: wed_hours ? (wed_hours.split('@')[0] === '00:00' ? '' : wed_hours.split('@')[0]) : '',
        wed_close: wed_hours ? (wed_hours.split('@')[1] === '00:00' ? '' : wed_hours.split('@')[1]) : '',
        thu_open: thu_hours ? (thu_hours.split('@')[0] === '00:00' ? '' : thu_hours.split('@')[0]) : '',
        thu_close: thu_hours ? (thu_hours.split('@')[1] === '00:00' ? '' : thu_hours.split('@')[1]) : '',
        fri_open: fri_hours ? (fri_hours.split('@')[0] === '00:00' ? '' : fri_hours.split('@')[0]) : '',
        fri_close: fri_hours ? (fri_hours.split('@')[1] === '00:00' ? '' : fri_hours.split('@')[1]) : '',
        sat_open: sat_hours ? (sat_hours.split('@')[0] === '00:00' ? '' : sat_hours.split('@')[0]) : '',
        sat_close: sat_hours ? (sat_hours.split('@')[1] === '00:00' ? '' : sat_hours.split('@')[1]) : '',
        sun_open: sun_hours ? (sun_hours.split('@')[0] === '00:00' ? '' : sun_hours.split('@')[0]) : '',
        sun_close: sun_hours ? (sun_hours.split('@')[1] === '00:00' ? '' : sun_hours.split('@')[1]) : '',
      });

      // Usage example:

      setWebFields(prevWebFields => data?.logins.length !== 0 ? data?.logins : prevWebFields);
      setServiceFields(prevServiceFields => data?.locations.length !== 0 ? data?.locations : prevServiceFields);


      setFormVariables(data?.provider)

      const generateResponse = (data, type) => {
        let fArray = [[], []];
        return data[type].map((item) => {
          fArray[type === "remarks" ? 0 : 1].push(item.id);
          return {
            ...item,
            remarks:
              type === 'remarks'
                ? item.remarks
                : data.remarks.find(
                  (remark) =>
                    remark.payer_id === item.payer_id && !fArray[0].includes(remark.id)
                )?.remarks || '',
            remarksid:
              type === 'remarks'
                ? item.id
                : data.remarks.find(
                  (remark) =>
                    remark.payer_id === item.payer_id && !fArray[0].includes(remark.id)
                )?.id || '',
            ...(type === "remarks" ? data.payerProviders.find(prov => prov.payer_id === item.payer_id && !fArray[1].includes(prov.id)) : {}),

          };
        });
      };


      let finalResponse =
        data.payerProviders.length > data.remarks.length
          ? generateResponse(data, 'payerProviders')
          : generateResponse(data, 'remarks');

      if (finalResponse.length > 0) {
        let arr = finalResponse.map(item => ({ ...item }))
        setPayerFields(finalResponse)
        setremarksCompare(arr)
      }
    }).catch(() => {

    })
  };

  const addServiceField = () => {
    setServiceFields([...serviceFields, { service_location: "", phone: "", fax: "", id: 0 }]);
  };

  // Function to remove a service field
  const removeServiceField = (index) => {
    const updatedFields = [...serviceFields];
    if (updatedFields[index].id != 0) {
      let object = updatedFields[index]
      setDeleteServiceFields((prev) => [...prev, object]);
    }
    updatedFields.splice(index, 1);
    setServiceFields(updatedFields);
  };


  // Function to handle input change for service fields
  const handleChange = (event, index, field) => {
    const { value } = event.target;
    if (field == 'phone' || field == "fax") {
      let valu = formatPhoneNumber(value)
      const updatedFields = [...serviceFields];
      updatedFields[index][field] = valu;
      setServiceFields(updatedFields);

    }
    else {
      const updatedFields = [...serviceFields];
      updatedFields[index][field] = value;
      setServiceFields(updatedFields);
    }

  };



  // Function to handle input change for web fields
  const handleWebInputChange = (event, index, field) => {
    const { value } = event.target;
    const updatedFields = [...webFields];
    updatedFields[index][field] = value;
    setWebFields(updatedFields);
  };




  // Function to add more web fields
  const handleWebAddMoreClick = () => {
    setWebFields([...webFields, { loginweb: "", loginuser: "", loginpass: "", additional_information: "", id: 0 }]);
  };

  // Function to remove web fields
  const handleWebRemoveClick = (index) => {
    const updatedFields = [...webFields];
    if (updatedFields[index].id != 0) {
      let object = updatedFields[index]
      setDeleteWebFields((prev) => [...prev, object]);
    }
    updatedFields.splice(index, 1);
    setWebFields(updatedFields);
  };

  const validationFunction = async (name) => {

    const CALLOBJ = { isAllowApiCall: true };

    if (name) {

      setError({ ...error, [name]: false });
    } else {
      practiceValidation();
      const objj = {
        individual_npi: formVariables.individual_npi,
        last_name: formVariables.last_name,
        name: formVariables.name,
        email: formVariables.email,
        cell: formVariables.cell,
        password: formVariables.password,
      };
      const a = await vlidation(objj);
      console.log(a);

      setError({
        ...error,
        individual_npi: a.individual_npi?.hasError || false,
        name: a.name?.hasError || false,
        last_name: a.last_name?.hasError || false,
        email: a.email?.hasError || false,
        cell: a.cell?.hasError || false,
        password: a.password?.hasError || false,
      });

      if (a.individual_npi?.hasError ||
        a.name?.hasError ||
        a.last_name?.hasError ||
        a.email?.hasError ||
        a.cell?.hasError ||
        a.password?.hasError || error.practiceid) {
        CALLOBJ.isAllowApiCall = false;
      }
    }
    return CALLOBJ;
  };

  console.log(error);
  // Function to add payer fields
  const addPayerField = async () => {
    if (payerFields[payerFields.length - 1].payer_id !== "" &&
      payerFields[payerFields.length - 1].effective_date !== "" &&
      payerFields[payerFields.length - 1].status !== ""

    ) {

    }
    setPayerFields([...payerFields,
    { payer_id: "", effective_date: "", status: "", provider_identifier: '', remarks: "", remarksid: 0, id: 0 },
    ]);
  };

  const onClear = () => {
    setServiceFields([{ service_location: "", phone: "", fax: "", id: 0 }])
    setWebFields([{ loginweb: "", loginuser: "", loginpass: "", additional_information: "", id: 0 }]);
    setPayerFields([{ payer_id: "", effective_date: "", status: "", provider_identifier: '', remarks: "", remarksid: 0, id: 0 }]);
    setFormVariables(formVariable)
    setTimeVariable(timeVariable)
  }
  // Function to remove payer fields
  const removePayerField = (index) => {

    const updatedFields = [...payerFields];
    if (updatedFields[index].remarksid != 0 || updatedFields[index].id != 0) {
      let object = updatedFields[index]
      setForPayerDelete((prev) => [...prev, object]);
    }
    updatedFields.splice(index, 1);
    setPayerFields(updatedFields);
  };


  // Function to handle payer field change
  const handlePayerFieldChange = (index, field, value) => {
    const updatedFields = [...payerFields];
    updatedFields[index][field] = value;
    setPayerFields(updatedFields);


  };

  function filterChangedData(current, previous) {
    return current.filter((item, index) => {
      return JSON.stringify(item) !== JSON.stringify(previous[index])
    });

  }

  // Function to handle time change

  const postData = async () => {
    setActiveTab(true)
    let res = await validationFunction()
    if (res.isAllowApiCall) {
      if (dataForEdit?.type == 'edit') {
        const formVariablesCopy = { ...formVariables }
        let previousPayerFields = [...remarksCompare];
        // Filter and store the changed data in payerFieldss
        const payerField = filterChangedData(payerFields, previousPayerFields);

        // Update the previous state with the current state for the next comparison
        previousPayerFields = [];


        const payerFieldss = payerFields[0].payer_id !== '' && payerFields[0].payer_id !== '' ?
          [...payerField] : [];
        const location = serviceFields[0].service_location !== '' ? [...serviceFields] : [];
        const webField = webFields[0].loginpass !== ''
          && webFields[0].loginweb !== ''
          && webFields[0].loginuse !== '' ?
          webFields?.map((item) => ({ ...item, provider_id: '' })) : [];
payerFields.forEach(f=> delete f.updated_at)
        let obj = {
          webField,
          payerFieldss,
          formVariablesCopy,
          timeVariables,
          location,
          payerDelete,
          deleteServiceFields,
          deleteWebFields
        }


        setLoading(true);
        await createProvider(obj).then(() => {
          onClear()
          toast.info("Edit successfully");
          setLoading(false);
          closeModal()
        }).catch((() => {
          setLoading(false);
        }))
      }


      else {
        let formVariablesCopy = {
          ...formVariables,
          practice_id: practiceId.value,
          practice_name: practiceId?.label ? practiceId?.label?.split('-')[1] : '',
          user_id: onLoginCredentialing.user.id
        };

        let payerField = [...payerFields];

        let payerFieldss = payerField?.map((item) => {
         
          return { ...item, provider_id: "", user_id: onLoginCredentialing.user.id };
        });
        const location = serviceFields[0].service_location !== '' ? [...serviceFields] : [];
        const webField = webFields[0].loginpass !== '' && webFields[0].loginweb !== '' && webFields[0].loginuse !== ''
          ? webFields?.map((item) => ({ ...item, provider_id: '' }))
          : [];

        let obj = {
          webField,
          payerFieldss,
          formVariablesCopy,
          timeVariables,
          location
        };

        setLoading(true);
        await createProvider(obj)
          .then(() => {
            onClear();
            setLoading(false);
            closeModal();
          })
          .catch(() => {
            setLoading(false);
          });
      }

    }
    else {
      toast('Please fill all required fields')
    }
  };


  const onChange = (day, timeType, value) => {
    setTimeVariable((prevState) => ({
      ...prevState,
      [`${day}_${timeType}`]: value,
    }));
  };


  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name == 'cell' || name == "owner_cell_number") {
      let valu = formatPhoneNumber(value)
      setFormVariables((prevFormVariables) => ({
        ...prevFormVariables,
        [name]: valu,
      }));
    }
    else {
      setFormVariables((prevFormVariables) => ({
        ...prevFormVariables,
        [name]: value,
      }));

    }

  };





  return (
    <div className="" style={{}}>
      {loading ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}
      {/* collapse section */}
      <>
        <div class="cust_tabs">
          {/* <div>af</div> */}
          <input
            class="cust_radiotab"
            name="tabs"
            tabindex="1"
            type="radio"
            id="Provider_Info"
            onClick={() => setActiveTab(true)}
            checked={activeTab}
          />
          <label class="cust_label " for="Provider_Info">
            Info
          </label>
          {activeTab ? <div class="cust_panel">
            <h2>Provider Information</h2>
            <div className="row accordionModalForm">
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Individual NPI <p style={{ color: error.individual_npi ? 'red' : '', paddingLeft: '5px' }}> *</p>
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="number"
                    name="individual_npi"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.individual_npi}
                    onFocus={() => validationFunction("individual_npi")}
                  />
                  <p className="text-danger">
                    {error.individual_npi ? "Only Number are allowed" : ""}
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Last Name <p style={{ color: error.last_name ? 'red' : '', paddingLeft: '5px' }}> *</p>
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="last_name"
                    placeholder=""
                    onChange={handleFormChange}
                    onFocus={() => validationFunction("last_name")}
                    value={formVariables?.last_name}
                  />
                  <p className="text-danger">
                    {error.last_name && "Please enter last name"}
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Middle Initial
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="middle_initial"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.middle_initial}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    First Name<p style={{ color: error.name ? 'red' : '', paddingLeft: '5px' }}> *</p>
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="name"
                    placeholder=""
                    onFocus={() => validationFunction("name")}
                    onChange={handleFormChange}
                    value={formVariables?.name}
                  />
                  <p className="text-danger">
                    {error.name && "Please enter first name"}
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Tax Id
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="number"
                    name="tax_id"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.tax_id}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    SSN
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="number"
                    name="ssn"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.ssn}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Provider Title
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="speciality"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.speciality}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Signup Date
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="date"
                    name="provider_signup_date"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.provider_signup_date?.split('T')[0]}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Taxonomy
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="taxonomy"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.taxonomy}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Taxonomy2
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="taxonomy2"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.taxonomy2}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Provider Cell <p style={{ color: error.cell ? 'red' : '', paddingLeft: '5px' }}> *</p>
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="cell"
                    placeholder=""
                    onFocus={() => validationFunction("cell")}
                    maxLength={14}
                    onChange={handleFormChange}
                    value={formVariables?.cell}
                  />
                  <p className="text-danger">
                    {error.cell && "Please enter provider phone"}
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Gender
                  </label>
                  <select
                    className="form-control inputField w-100"
                    type="text"
                    name="gender"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.gender}
                    style={{ color: 'black' }} // Apply black text color
                  >
                    <option>Select Gender</option>
                    <option value={'M'}>Male</option>
                    <option value={'F'}>Female</option>
                  </select>

                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Birth Country
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="birth_county"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.birth_county}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-0">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Birth State
                  </label>
                </div>
                <Select
                  options={usaStatesOptions}
                  placeholder="Select an option"
                  styles={customStyles}
                  value={formVariables?.birth_state&& usaStatesOptions?.find((item)=>formVariables?.birth_state==item.value)}
                  onChange={(value) =>
                    handleFormChange({
                      target: { value: value.label, name: "birth_state" },
                    })
                  }
                />
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Provider Email<p style={{ color: error.email ? 'red' : '', paddingLeft: '5px' }}> *</p>
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="email"
                    placeholder=""
                    onFocus={() => validationFunction("email")}
                    onChange={handleFormChange}
                    value={formVariables?.email}
                  />
                  <p className="text-danger">
                    {error.email && "Please enter  provider email"}
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    DOB
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="date"
                    name="dob"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.dob?.split('T')[0]}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Assign Password<p style={{ color: error.password ? 'red' : '', paddingLeft: '5px' }}> *</p>
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="password"
                    placeholder=""
                    onFocus={() => validationFunction("password")}
                    onChange={handleFormChange}
                    value={formVariables?.password}
                  />
                  <p className="text-danger">
                    {error.password && "Please enter password"}
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    EMR/Software
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="software_name"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.software_name}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-12">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    <h5>Home Address:</h5>
                  </label>
                </div>
              </div>
              <div className="col-sm-6 col-lg-9">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Street
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="home_street"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.home_street}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Suite/Other
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="home_suite"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.home_suite}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    City
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="home_city"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.home_city}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-0">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    State
                  </label>{" "}
                </div>

                <Select
                  options={usaStatesOptions}
                  placeholder="Select an option"
                  styles={customStyles}
                  value={formVariables?.home_state&& usaStatesOptions?.find((item)=>formVariables?.home_state==item.value)}
                  onChange={(value) =>
                    handleFormChange({
                      target: { value: value.label, name: "home_state" },
                    })
                  }
                />
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    County
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="home_county"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.home_county}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Zip
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="home_zip"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.home_zip}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-12">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    <h5>Mailing Address:</h5>
                  </label>
                </div>
              </div>
              <div className="col-sm-6 col-lg-9">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Street
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="mailing_street"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.mailing_street}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Suite/Other
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="mailing_suite"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.mailing_suite}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    City
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="mailing_city"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.mailing_city}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-0">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    State
                  </label>{" "}
                </div>

                <Select
                  options={usaStatesOptions}
                  placeholder="Select an option"
                  styles={customStyles}
                  value={formVariables?.mailing_state&&usaStatesOptions?.find((item)=>formVariables?.mailing_state==item.value) }
                  onChange={(value) =>
                    handleFormChange({
                      target: { value: value.label, name: "mailing_state" },
                    })
                  }
                />
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    County
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="mailing_county"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.mailing_county}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="form-group form-inline mb-2">
                  <label
                    className="inputLabel pt-2 w-100"
                    style={{ color: "black", marginRight: "6px" }}
                  >
                    Zip
                  </label>
                  <input
                    className="form-control inputField w-100"
                    type="text"
                    name="mailing_zip"
                    placeholder=""
                    onChange={handleFormChange}
                    value={formVariables?.mailing_zip}
                  />
                </div>
              </div>
            </div>
          </div> : ''}

          <input
            class="cust_radiotab"
            tabindex="1"
            name="tabs"
            type="radio"
            id="Service_location"
            onClick={() => setActiveTab(false)}
          />
          <label class="cust_label" for="Service_location">
            Service location
          </label>
          <div class="cust_panel" tabindex="1">
            <h2>Service location</h2>
            <>
              {" "}
              <div className="row accordionModalForm">
                <div className="d-flex flex-column" style={{}}>
                  {serviceFields.map((field, index) => (
                    <div className="row accordionModalForm" key={index}>
                      <div className="col-sm-6 col-lg-3">
                        <div className="form-group form-inline mb-2">
                          <label
                            className="inputLabel pt-2 w-100"
                            style={{ color: "black", marginRight: "6px" }}
                          >
                            Service location
                          </label>
                          <input
                            className="form-control inputField w-100"
                            type="text"
                            name="service_location"
                            placeholder=""
                            value={serviceFields[index].service_location}
                            onChange={(event) =>
                              handleChange(event, index, "service_location")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <div className="form-group form-inline mb-2">
                          <label
                            className="inputLabel pt-2 w-100"
                            style={{ color: "black", marginRight: "6px" }}
                          >
                            Phone
                          </label>
                          <input
                            className="form-control inputField w-100"
                            type="text"
                            name="phone"
                            placeholder=""
                            maxLength={14}
                            value={serviceFields[index].phone}
                            onChange={(event) =>
                              handleChange(event, index, "phone")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <div className="form-group form-inline mb-2">
                          <label
                            className="inputLabel pt-2 w-100"
                            style={{ color: "black", marginRight: "6px" }}
                          >
                            Fax
                          </label>
                          <input
                            className="form-control inputField w-100"
                            type="text"
                            name="fax"
                            maxLength={14}
                            placeholder=""
                            value={serviceFields[index].fax}
                            onChange={(event) => handleChange(event, index, "fax")}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 justify-content-center">
                        <div className="d-flex justify-content-center align-items-center mt-5">
                          {serviceFields.length > 1 ? (
                            <i
                              className="fas fa-times"
                              style={{ color: "#ec2b09" }}
                              onClick={() => removeServiceField(index)}
                            ></i>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-sm-6 col-lg-12 mt-2">
                  <div className="form-group">
                    <div className="d-flex justify-content-start">
                      <button className="btnSave" onClick={addServiceField}>
                        Add More Fields
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>

          <input
            class="cust_radiotab"
            tabindex="1"
            name="tabs"
            type="radio"
            id="Contact_Info"
            onClick={() => setActiveTab(false)}
          />
          <label class="cust_label" for="Contact_Info">
            Contact Info
          </label>
          <div class="cust_panel" tabindex="1">
            <h2>Contact Info</h2>
            <>
              <div className="row accordionModalForm">
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Office Manager Name
                    </label>
                    <input
                      className="form-control inputField w-100"
                      type="text"
                      name="manager_name"
                      placeholder=""
                      value={formVariables?.manager_name}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Office Manager Email
                    </label>
                    <input
                      className="form-control inputField w-100"
                      type="text"
                      name="manager_email"
                      placeholder=""
                      value={formVariables?.manager_email}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </div>
            </>
          </div>

          <input
            class="cust_radiotab"
            tabindex="1"
            name="tabs"
            type="radio"
            id="Owner_Info"
            onClick={() => setActiveTab(false)}
          />
          <label class="cust_label" for="Owner_Info">
            Owner Info
          </label>
          <div class="cust_panel" tabindex="1">
            <h2>Owner Info</h2>
            <>
              <div className="row accordionModalForm">
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      F Name
                    </label>
                    <input
                      className="form-control inputField  w-100 w-100"
                      type="text"
                      name="owner_first_name"
                      placeholder=""
                      onChange={handleFormChange}
                      value={formVariables?.owner_first_name}
                    />
                  </div>
                </div>{" "}
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Middle Initial
                    </label>
                    <input
                      className="form-control inputField  w-100 w-100"
                      type="text"
                      name="owner_middle_initial"
                      placeholder=""
                      onChange={handleFormChange}
                      value={formVariables?.owner_middle_initial}
                    />
                  </div>
                </div>{" "}
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Last Name
                    </label>
                    <input
                      className="form-control inputField  w-100 w-100"
                      type="text"
                      name="owner_last_name"
                      placeholder=""
                      onChange={handleFormChange}
                      value={formVariables?.owner_last_name}
                    />
                  </div>
                </div>{" "}
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Email
                    </label>
                    <input
                      className="form-control inputField  w-100 w-100"
                      type="text"
                      name="owner_email"
                      placeholder=""
                      onChange={handleFormChange}
                      value={formVariables?.owner_email}
                    />
                  </div>
                </div>{" "}
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Cell Number
                    </label>
                    <input
                      className="form-control inputField  w-100 w-100"
                      type="text"
                      name="owner_cell_number"
                      placeholder=""
                      onChange={handleFormChange}
                      value={formVariables?.owner_cell_number}
                      maxLength={14}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      SSN
                    </label>
                    <input
                      className="form-control inputField  w-100"
                      type="text"
                      name="owner_ssn"
                      placeholder=""
                      onChange={handleFormChange}
                      value={formVariables?.owner_ssn}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Driving License{" "}
                    </label>

                    <input
                      className="form-control inputField  w-100"
                      type="text"
                      name="owner_driving_license"
                      onChange={handleFormChange}
                      value={formVariables?.owner_driving_license}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      License Date
                    </label>
                    <input
                      className="form-control inputField  w-100"
                      type="date"
                      onChange={handleFormChange}
                      value={formVariables?.owner_driving_license_date?.split('T')[0]}
                      name="owner_driving_license_date"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      License Expiry
                    </label>

                    <input
                      className="form-control inputField  w-100"
                      type="date"
                      name="owner_driving_license_expiry"
                      onChange={handleFormChange}
                      value={formVariables?.owner_driving_license_expiry?.split('T')[0]}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Owner DOB
                    </label>
                    <input
                      className="form-control inputField  w-100"
                      type="date"
                      name="owner_dob"
                      onChange={handleFormChange}
                      value={formVariables?.owner_dob?.split('T')[0]}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Owner Home Address
                    </label>

                    <input
                      className="form-control inputField  w-100"
                      type="text"
                      name="owner_home_address"
                      onChange={handleFormChange}
                      value={formVariables?.owner_home_address}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>{" "}
            </>
          </div>

          <input
            class="cust_radiotab"
            tabindex="1"
            name="tabs"
            type="radio"
            id="Web_Portal_Access"
            onClick={() => setActiveTab(false)}
          />
          <label class="cust_label" for="Web_Portal_Access">
            Portal Access
          </label>
          <div class="cust_panel" tabindex="">
            <h2>Web_Portal_Access</h2>
            <>
              {" "}
              <div className="row accordionModalForm">
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black" }}
                    >
                      CAQH User
                    </label>
                    <input
                      className="form-control inputField w-100"
                      type="text"
                      name="caqh_user"
                      placeholder=""
                      value={formVariables?.caqh_user}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      CAQH Pass
                    </label>
                    <input
                      className="form-control inputField w-100"
                      type="text"
                      name="caqh_pass"
                      placeholder=""
                      value={formVariables?.caqh_pass}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      CAQH Id
                    </label>
                    <input
                      className="form-control inputField w-100"
                      type="text"
                      name="caqh_id"
                      placeholder=""
                      value={formVariables?.caqh_id}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                <div
                  className="col-sm-6 col-lg-3"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      PECOS User
                    </label>
                    <input
                      className="form-control inputField w-100"
                      type="text"
                      name="pecos_user"
                      placeholder=""
                      value={formVariables?.pecos_user}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      PECOS Pass
                    </label>
                    <input
                      className="form-control inputField w-100"
                      type="text"
                      name="pecos_pass"
                      placeholder=""
                      value={formVariables?.pecos_pass}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
              </div>
            </>
          </div>

          <input
            class="cust_radiotab"
            tabindex="1"
            name="tabs"
            type="radio"
            id="Provider_Identification"
            onClick={() => setActiveTab(false)}
          />
          <label class="cust_label" for="Provider_Identification">
            Identification
          </label>
          <div class="cust_panel" tabindex="1">
            <h2>Provider_Identification</h2>
            <>
              <div className="row accordionModalForm">
                <div className="col-sm-6 col-lg-6">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Medicaid ID
                    </label>
                    <input
                      className="form-control inputField w-100"
                      type="text"
                      name="medicaid_id"
                      placeholder=""
                      value={formVariables?.medicaid_id}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-6">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Medicare ID
                    </label>
                    <input
                      className="form-control inputField w-100"
                      type="text"
                      name="medicare_id"
                      placeholder=""
                      value={formVariables?.medicare_id}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      DEA{" "}
                    </label>
                    <input
                      type="checkbox"
                      name="dea"
                      value={formVariables?.dea === "on" ? "" : "on"}
                      checked={formVariables?.dea === "on"}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                {formVariables?.dea ? (
                  <>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          DEA Certification #
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="text"
                          name="dea_certification_number"
                          placeholder=""
                          value={formVariables?.dea_certification_number}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          DEA Effective
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="date"
                          name="dea_effective_date"
                          placeholder=""
                          value={formVariables?.dea_effective_date?.split('T')[0]}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          DEA Expiry
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="date"
                          name="dea_expiry_date"
                          placeholder=""
                          value={formVariables?.dea_expiry_date?.split('T')[0]}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-9"></div>
                )}
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Board Certification{" "}
                    </label>
                    <input
                      type="checkbox"
                      name="board_certification"
                      checked={formVariables?.board_certification === "on"}
                      onChange={handleFormChange}
                      placeholder=""
                      value={
                        formVariables?.board_certification === "on" ? "" : "on"
                      }
                    />
                  </div>
                </div>
                {formVariables?.board_certification ? (
                  <>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          Board Certification #
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="text"
                          name="board_certification_number"
                          placeholder=""
                          value={formVariables?.board_certification_number}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          Board Effective{" "}
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="date"
                          name="board_effective_date"
                          placeholder=""
                          value={formVariables?.board_effective_date?.split('T')[0]}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          Board Expiry{" "}
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="date"
                          name="board_expiry_date"
                          placeholder=""
                          value={formVariables?.board_expiry_date?.split('T')[0]}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-9"></div>
                  </>
                )}
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Hospital Affiliation{" "}
                    </label>
                    <input
                      type="checkbox"
                      name="hospital_affiliation"
                      value={
                        formVariables?.hospital_affiliation === "on" ? "" : "on"
                      }
                      onChange={handleFormChange}
                      checked={formVariables?.hospital_affiliation === "on"}
                    />
                  </div>
                </div>
                {formVariables?.hospital_affiliation ? (
                  <>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          Hospital Name
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="text"
                          name="hospital_name"
                          placeholder=""
                          value={formVariables?.hospital_name}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          Hospital Effective
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="date"
                          name="hospital_effective_date"
                          placeholder=""
                          value={formVariables?.hospital_effective_date?.split('T')[0]}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          Hospital Expiry
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="date"
                          name="hospital_expiry_date"
                          placeholder=""
                          value={formVariables?.hospital_expiry_date?.split('T')[0]}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-9"></div>
                )}
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline mb-2">
                    <label
                      className="inputLabel pt-2 w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      State License{" "}
                    </label>
                    <input
                      type="checkbox"
                      name="state_license_option"
                      value={
                        formVariables?.state_license_option === "on" ? "" : "on"
                      }
                      onChange={handleFormChange}
                      checked={formVariables?.state_license_option === "on"}
                    />
                  </div>
                </div>
                {formVariables?.state_license_option ? (
                  <>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          State License#
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="text"
                          name="state_license_number"
                          placeholder=""
                          value={formVariables?.state_license_number}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          License Effective{" "}
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="date"
                          name="state_license_date"
                          placeholder=""
                          value={formVariables?.state_license_date?.split('T')[0]}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          License Expiry{" "}
                        </label>
                        <input
                          className="form-control inputField w-100"
                          type="date"
                          name="state_license_expiry"
                          placeholder=""
                          value={formVariables?.state_license_expiry?.split('T')[0]}
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-9"></div>
                )}
              </div>
            </>
          </div>

          <input
            class="cust_radiotab"
            tabindex="1"
            name="tabs"
            type="radio"
            id="Office_Hours_Schedule"
            onClick={() => setActiveTab(false)}
          />
          <label class="cust_label" for="Office_Hours_Schedule">
            Schedule
          </label>
          <div class="cust_panel" tabindex="1">
            <h2>Office Hours Schedule</h2>
            <>
              <div className="row accordionModalForm">
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Monday
                    </label>
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Open
                    </label>
                    <TimePicker
                      disableClock={true}
                      is12hour
                      onChange={(value) => onChange("mon", "open", value)}
                      value={timeVariables?.mon_open}
                    />
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Close
                    </label>
                    <TimePicker
                      disableClock={true}
                      autoFocus={true}


                      onChange={(value) => onChange("mon", "close", value)}
                      value={timeVariables?.mon_close}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Tuesday
                    </label>
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Open
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("tue", "open", value)}
                      value={timeVariables?.tue_open}
                    />
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Close
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("tue", "close", value)}
                      value={timeVariables?.tue_close}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Wednesday
                    </label>
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Open
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("wed", "open", value)}
                      value={timeVariables?.wed_open}
                    />
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Close
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("wed", "close", value)}
                      value={timeVariables?.wed_close}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Thursday
                    </label>
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Open
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("thu", "open", value)}
                      value={timeVariables?.thu_open}
                    />
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Close
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("thu", "close", value)}
                      value={timeVariables?.thu_close}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Friday
                    </label>
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Open
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("fri", "open", value)}
                      value={timeVariables?.fri_open}
                    />
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Close
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("fri", "close", value)}
                      value={timeVariables?.fri_close}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Saturday
                    </label>
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Open
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("sat", "open", value)}
                      value={timeVariables?.sat_open}
                    />
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Close
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("sat", "close", value)}
                      value={timeVariables?.sat_close}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="form-group form-inline  mb-2">
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Sunday
                    </label>
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Open
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("sun", "open", value)}
                      value={timeVariables?.sun_open}
                    />
                    <label
                      className="inputLabel pt-2  w-100"
                      style={{ color: "black", marginRight: "6px" }}
                    >
                      Close
                    </label>
                    <TimePicker
                      disableClock={true}
                      onChange={(value) => onChange("sun", "close", value)}
                      value={timeVariables?.sun_close}
                    />
                  </div>
                </div>
              </div>
            </>
          </div>

          <input
            class="cust_radiotab"
            tabindex="1"
            name="tabs"
            type="radio"
            id="Other_Logins_for_Web_Portal_Access"
            onClick={() => setActiveTab(false)}
          />
          <label class="cust_label" for="Other_Logins_for_Web_Portal_Access">
            Other Logins
          </label>
          <div class="cust_panel" tabindex="1">
            <h2>Other Logins for Web Portal Access</h2>
            <>
              <div className="d-flex flex-column" style={{}}>
                {webFields.map((_, index) => (
                  <div key={index}>
                    <div className="d-flex">
                      <div className="col-sm-6 col-lg-3">
                        <div className="form-group form-inline mb-2">
                          <label
                            className="inputLabel pt-2 w-100"
                            style={{ color: "black", marginRight: "6px" }}
                          >
                            Web Portal Name
                          </label>

                          <input
                            className="form-control inputField w-100"
                            type="text"
                            name="loginweb"
                            value={webFields[index].loginweb}
                            placeholder=""
                            onChange={(event) =>
                              handleWebInputChange(event, index, "loginweb")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <div className="form-group form-inline mb-2">
                          <label
                            className="inputLabel pt-2 w-100"
                            style={{ color: "black", marginRight: "6px" }}
                          >
                            User Name
                          </label>

                          <input
                            className="form-control inputField w-100"
                            type="text"
                            name="loginuser"
                            value={webFields[index].loginuser}
                            placeholder=""
                            onChange={(event) =>
                              handleWebInputChange(event, index, "loginuser")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <div className="form-group form-inline mb-2">
                          <label
                            className="inputLabel pt-2 w-100"
                            style={{ color: "black", marginRight: "6px" }}
                          >
                            Password
                          </label>

                          <input
                            className="form-control inputField w-100"
                            type="text"
                            name="loginpass"
                            placeholder=""
                            value={webFields[index].loginpass}
                            onChange={(event) =>
                              handleWebInputChange(event, index, "loginpass")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-sm-6 col-lg-6">
                        <div className="form-group form-inline mb-2">
                          <label
                            className="inputLabel pt-2 w-100"
                            style={{ color: "black", marginRight: "6px" }}
                          >
                            Security Question/Notes
                          </label>
                          <textarea
                            id="w3review"
                            name="additional_information"
                            rows="2"
                            cols="70"
                            value={webFields[index].additional_information}
                            onChange={(event) =>
                              handleWebInputChange(
                                event,
                                index,
                                "additional_information"
                              )
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-2 justify-content-center">
                        <div className="d-flex justify-content-center align-items-center mt-5">
                          {webFields.length > 1 ? (
                            <i
                              className="fas fa-times"
                              style={{ color: "#ec2b09" }}
                              onClick={() => {
                                handleWebRemoveClick(index);
                              }}
                            ></i>
                          ) : (
                            " "
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-sm-6 col-lg-12 mt-2">
                <div className="form-group">
                  <div className="d-flex justify-content-start">
                    <button className="btnSave" onClick={handleWebAddMoreClick}>
                      Add More Logins
                    </button>
                  </div>
                </div>
              </div>
            </>
          </div>

          <input
            class="cust_radiotab"
            tabindex="1"
            name="tabs"
            type="radio"
            id="Applications"
            onClick={() => setActiveTab(false)}
          />
          <label class="cust_label" for="Applications">
            Applications
          </label>
          {/* <div className="custTabArrow">x</div> */}
          <div class="cust_panel" tabindex="1">
            <h2>Applications</h2>
            <>
              <div className="d-flex flex-column" style={{}}>
                <div>
                  {payerFields?.map((_, index) => (
                    <div key={index}>
                      <div className="d-flex">
                        <div className="col-sm-6 col-lg-3">
                          <div className="form-group form-inline mb-0">
                            <label
                              className="inputLabel pt-2 w-100"
                              style={{ color: "black", marginRight: "6px" }}
                            >
                              Select Payer
                            </label>
                          </div>
                          <Select
                            options={payerName ? payerName : []}
                            placeholder="Please Select Payer"
                            value={payerName?.find(
                              (id) => id.value == payerFields[index]?.payer_id
                            )}
                            onChange={(value) =>
                              handlePayerFieldChange(index, "payer_id", value.value)
                            }
                            style={customStyles}
                          />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                          <div className="form-group form-inline mb-2">
                            <label
                              className="inputLabel pt-2 w-100"
                              style={{ color: "black", marginRight: "6px" }}
                            >
                              Effective Date
                            </label>
                            <input
                              className="form-control inputField w-100 w-100"
                              type="date"
                              name="sbf"
                              placeholder=""
                              value={payerFields[index].effective_date?.split('T')[0]}
                              onChange={(e) =>
                                handlePayerFieldChange(
                                  index,
                                  "effective_date",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                          <div className="form-group form-inline mb-0">
                            <label
                              className="inputLabel pt-2 w-100"
                              style={{ color: "black", marginRight: "6px" }}
                            >
                              Status
                            </label>
                          </div>
                          <Select
                            options={applicationStatus}
                            placeholder="Please Select Payer"
                            value={applicationStatus.find(
                              (status) =>
                                status.label.toLowerCase() ===
                                payerFields[index]?.status.toLowerCase()
                            )}
                            style={customStyles}
                            onChange={(value) =>
                              handlePayerFieldChange(index, "status", value.label)
                            }
                          />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                          <div className="form-group form-inline mb-2">
                            <label
                              className="inputLabel pt-2 w-100"
                              style={{ color: "black", marginRight: "6px" }}
                            >
                              Provider Id
                            </label>
                            <input
                              className="form-control inputField w-100 w-100"
                              type="type"
                              name="sbf"
                              placeholder=""
                              value={payerFields[index].provider_identifier}
                              onChange={(e) =>
                                handlePayerFieldChange(
                                  index,
                                  "provider_identifier",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-sm-6 col-lg-9">
                          <div className="form-group form-inline mb-2">
                            <label
                              className="inputLabel pt-2 w-100"
                              style={{ color: "black", marginRight: "6px" }}
                            >
                              Remarks
                            </label>
                            <input
                              className="form-control inputField w-100 w-100"
                              type="type"
                              name="sbf"
                              placeholder=""
                              value={payerFields[index].remarks}
                              onChange={(e) =>
                                handlePayerFieldChange(
                                  index,
                                  "remarks",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 justify-content-start">
                          <div className="d-flex justify-content-center align-items-center mt-5">
                            {payerFields.length > 1 ? (
                              <i
                                className="fas fa-times"
                                style={{ color: "#ec2b09" }}
                                onClick={() => removePayerField(index)}
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-sm-6 col-lg-12 mt-2">
                <div className="form-group ">
                  <div className="d-flex justify-content-end">
                    <button className="btnSave " onClick={addPayerField}>
                      Add More Payer
                    </button>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </>

      <div className="row col-lg-12 d-flex"></div>
      <div className="col-sm-6 col-lg-12 mt-2">
        <div className="form-group ">
          <div className="d-flex justify-content-end mt-4">
            <button className="btnClear mr-1 " onClick={onClear}>
              Clear
            </button>
            <button type="button" className="btnSave" id="Provider_Info" tabindex="1" onClick={postData}  >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProviderForm;

