import { combineReducers } from "redux";



function userList(state = false, action) {
  switch (action.type) {
    case "userlist":
      return action.payload;
    default:
      return state;
  }
}

function userNames(state = false, action) {
  switch (action.type) {
    case "userNames":
      return action.payload;
    default:
      return state;
  }
}
function Specialities(state = false, action) {
  switch (action.type) {
    case "Specialities":
      return action.payload;
    default:
      return state;
  }
}
function statesCodes(state = false, action) {
  switch (action.type) {
    case "statesCodes":
      return action.payload;
    default:
      return state;
  }
}
function practiceName(state = false, action) {
  switch (action.type) {
    case "practiceName":
      return action.payload;
    default:
      return state;
  }
}
function payerName(state = false, action) {
  switch (action.type) {
    case "payerName":
      return action.payload;
    default:
      return state;
  }
}
function practiceIdies(state = false, action) {
  switch (action.type) {
    case "practiceIdies":
      return action.payload;
    default:
      return state;
  }
}
function onLoginCredentialing(state = false, action) {
  
  switch (action.type) {
    case "Credentialing":
      return action.payload;
    default:
      return state;
  }
}
const rootReducer = combineReducers({

 

  //sIGN OFF REDUX
  onLoginCredentialing:onLoginCredentialing,
  userList:userList,
  userNames:userNames,
  practiceIdies:practiceIdies,
  practiceName:practiceName,
  Specialities:Specialities,
  statesCodes:statesCodes
  ,payerName:payerName
});


export default rootReducer;
