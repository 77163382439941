import React, { useState } from "react";
import Select from "react-select";
import Custominput from "./Custominput";
import "./Quick.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  createNewPractices,
  getPracticeDataForUpdate,
  getQuickprovData,
} from "../../Utils/ApiManager";
import { toast } from "react-toastify";

import Loader from "../../Common/Loader/Loader";
import QuickUpdatesProviders from "./QuickUpdatesProviders";
import EditQuickUpdate from "./EditQuickUpdate";
const QuickUpdateForm1 = () => {
  let arrayofIpnput = [
    { name: "field1", label: "Field 1" },
    { name: "field2", label: "Field 2" },
  ];
  const { state, Specialities, practiceName, onLoginCredentialing } =
    useSelector((state) => state);
  console.log(onLoginCredentialing, "onLoginCredentialing");
  let practicesDataobj = {
    clia: "",
    clia_expiry: "",
    client_name: "",
    contact1_email: "",
    contact1_mobile: "",
    contact1_name: "",
    contact1_phone: "",
    contact2_email: "",
    contact2_mobile: "",
    contact2_name: "",
    contact2_phone: "",
    created_at: "",
    deleted: null,
    group_npi: "",
    id: "",
    onwer_email: "",
    owner_name: "",
    pay_address: "",
    pay_city: "",
    pay_country: "",
    pay_state: "",
    pay_zip: "",
    practice_code: "",
    practice_name: "",
    practice_tax_id: "",
    service_address: "",
    service_city: "",
    service_country: "",
    service_fax: "",
    service_phone: "",
    service_state: "",
    service_zip: "",
    speciality: "",
    taxnomy: "",
  };
  const dataOfaprovider = {
    id: "",
    practice_id: "",
    practice_name: "",
    group_npi: "",
    individual_npi: "",
    last_name: "",
    middle_initial: "",
    name: "",
    tax_id: "",
    ssn: "",
    speciality: "",
    provider_signup_date: "",
    taxonomy: "",
    taxonomy2: "",
    home_street: "",
    home_suite: "",
    home_city: "",
    home_state: "",
    home_county: "",
    home_zip: "",
    mailing_street: "",
    mailing_suite: "",
    mailing_city: "",
    mailing_state: "",
    mailing_county: "",
    mailing_zip: "",
    cell: "",
    gender: "",
    birth_county: "",
    birth_state: "",
    email: "",
    dob: "",
    password: "",
    software_name: "",
    owner_last_name: "",
    owner_middle_initial: "",
    owner_first_name: "",
    owner_email: "",
    owner_cell_number: "",
    owner_ssn: "",
    owner_driving_license: "",
    owner_driving_license_date: "",
    owner_driving_license_expiry: "",
    owner_dob: "",
    owner_home_address: "",
    caqh_user: "",
    caqh_pass: "",
    caqh_id: "",
    pecos_user: "",
    pecos_pass: "",
    manager_name: "",
    manager_email: "",
    medicaid_id: "",
    grp_madicaid_id: "",
    grp_mrc_id: "",
    medicaid_user: "",
    medicare_id: "",
    website: "",
    dea: "",
    dea_certification_number: "",
    dea_effective_date: "",
    dea_expiry_date: "",
    state_license: "",
    board_certification: "",
    board_certification_number: "",
    board_effective_date: "",
    board_expiry_date: "",
    hospital_affiliation: "",
    hospital_name: "",
    hospital_effective_date: "",
    hospital_expiry_date: "",
    state_license_option: "",
    state_license_number: "",
    state_license_date: "",
    state_license_expiry: "",
    mon_hours: "",
    tue_hours: "",
    wed_hours: "",
    thu_hours: "",
    fri_hours: "",
    sat_hours: "",
    sun_hours: "",
    deleted: "",
    user_id: "",
    status: "",

    payer_providers: [
      {
        id: "",
        payer_id: "",
        provider_id: "",
        provider_identifier: "",
        status: "",
        effective_date: "",
        business_line: "",
        business_effective_date: "",
        Payer: {
          id: "",
          name: "",
          deleted: 0,
        },
      },
      // ... other payer_providers
    ],
    appstatuschanges: [
      {
        id: "",
        payer_id: "",
        provider_id: "",
        before_status: "",
        after_status: "",
        status_change_date: "",
      },
      // ... other appstatuschanges
    ],
    remarks: [
      {
        id: "",
        payer_id: "",
        provider_id: "",
        user_id: "",
        remarks: "",
        deleted: false,
      },
      // ... other remarks
    ],
    locations: [
      {
        fax: "",
        id: "",
        phone: "",
        provider_id: "",
        service_location: "",
      },
    ],
  };
  const [loadingn, setLoading] = useState(false);
  const [numFields, setNumFields] = useState(arrayofIpnput);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [practice_id, setPraacticeId] = useState();
  const [providerOptions, setProviderOptions] = useState([]);
  const [providerOptionsId, setProviderOptionsId] = useState();
  const [proviValue, setProviderValue] = useState("");
  const [ProviderDataforForm, setProviderData] = useState(dataOfaprovider);
  const [PracticesDataforForm, setPracticesData] = useState(practicesDataobj);
  const [practicesValues, setPracticesValues] = useState(practicesDataobj);
  const [edit, setEdit] = useState(false);
  const handleChangeselect = (selectedValue, selectedOption) => {
    console.log(selectedValue);
    setSelectedOption(selectedOption);
    setPraacticeId(selectedValue);

    fetchPracticesData(selectedValue);
    setPracticesData((prevState) => ({
      ...prevState,
      id: selectedValue,
    }));
    setProviderData(dataOfaprovider);
  };
  console.log(proviValue, "id");

  const getProvider =
    Specialities.length > 0 &&
    Specialities?.filter((item) => {
      return practice_id === item.practice_id;
    }).map((item) => ({
      label: item.name + " " + item.last_name,
      value: item.id,
    }));
  const handleChangeProvider = (selectedValue, selectedOption) => {
    setProviderOptionsId(selectedValue);
    setProviderValue(selectedOption);
    fetchProviderData(selectedValue ? selectedValue : "");
  };
  // console.log(getProvider, "getProviders");
  useEffect(() => {
    setOptions(practiceName);
    setProviderValue("");
    setProviderOptions(getProvider);
  }, [practice_id]);
  // console.log(options, "options");
  const fetchPracticesData = async (id) => {
    setLoading(true);
    setPracticesData("");
    try {
      const responce = await getPracticeDataForUpdate(id);
      if (responce.status == 200) {
        // toast.success("success");
      }

      let finalObj = {};
      Object.keys(responce?.data).forEach((k) => {
        finalObj[k] = responce.data[k] || "";
      });

      setPracticesData(finalObj);
      console.log("don");

      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };
  const fetchProviderData = async (id) => {
    setLoading(true);
    try {
      const responce = await getQuickprovData({ id });
      if (responce.status == 200) {
        // toast.success("success");
      }
      console.log(responce, "responce");

      setProviderData(responce?.data[0]);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };
  console.log(ProviderDataforForm, "ProviderDataforForm");
  const handleAddMoreClick = () => {
    setNumFields([
      ...numFields,
      {
        name: "claims pending",
        type: "input",
      },
    ]);
  };
  const handleChnageForPractices = (e) => {
    const { name, value } = e.target;
    setPracticesData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  console.log(PracticesDataforForm, "practicesValues");
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)",
        cursor: "pointer",
        color: "white",
      },
    }),
  };
  const updatePracticesData = async () => {
    const responce = await createNewPractices(PracticesDataforForm);
    setPracticesData(practicesDataobj);

    if (responce.status == 201) toast.info("success");
  };
  const handleEditClick = () => {
    setEdit(!edit); // Toggles the state of 'edit'
  };
  console.log(practice_id, "practice_id");
  return (
    <>
      {loadingn ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (""
      )}
      <div
        className="parent001"
        style={{ justifyContent: "space-between", display: "flex" }}
      >
        <form class="form-inline">
          <div class="form-group mx-sm-3 mb-2 ">
            <label for="inputPassword2 " class="mx-4">
              <div className="labelSzCus"> Select Practice </div>
            </label>
            <Select
              style={customStyles}
              options={options ? options : []}
              isDisabled={edit}
              className="width"
              value={selectedOption ? selectedOption : ""}
              placeholder="Select an option"
              // Use the original Select component
              onChange={(selectedOption) =>
                handleChangeselect(Number(selectedOption.value), selectedOption)
              }
            />
          </div>
          <div class="form-group mb-2">
            <label for="staticEmail2 " class="mx-4">
              <div className="labelSzCus">Select Provider </div>
            </label>
            <Select
              options={providerOptions ? providerOptions : []}
              placeholder="Please Select Provider"
              value={proviValue ? proviValue : ""}
              isDisabled={edit}
              className="width"
              onChange={(selectedOption) =>
                handleChangeProvider(
                  Number(selectedOption.value),
                  selectedOption
                )
              }
            />{" "}
          </div>
          {/* <button type="submit" class="btnClearBig mr-1 ml-2 mb-2">
            Export Csv
          </button> */}
          <div className="d-flex align-items-center ml-2">
            <div className=" iconBlue" onClick={handleEditClick}>
              {!edit ? (
                <>
                  {PracticesDataforForm.id !== null &&
                  PracticesDataforForm.id !== "" ? (
                    <>
                      {" "}
                      <button type="submit" class="btnClearBig mb-2">
                        {" "}
                        Edit Information <i class="fas fa-user-edit"></i>
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <button type="submit" class="btn btn-primary mb-2">
                    Close
                    <i
                      class="fas fa-times ml-2"
                      style={{ color: "#7ac0f5" }}
                    ></i>
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      </div>

     
        <>
          <>
            {edit ? (
              <EditQuickUpdate
                PractData={PracticesDataforForm}
                ProviData={ProviderDataforForm}
                handleEditClick={handleEditClick}
              />
            ) : (
              <>
                <div className="mb-3">
                  {/* Patient summary start           */}
                  <div className="patientBg">
                    <div className="row">
                      <div className="col-sm-12">
                        <div class="smHd">
                          <div className="d-flex align-items-center justify-content-between">
                            <div>Practice Info</div>
                            {/* <div
															className=" iconBlue"
															onClick={handleEditClick}
														>
															<button> Edit Information </button>{" "}
															<i class="fas fa-user-edit"></i>
														</div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="patientBgSummary">
                          <div className="SummaryBlock">
                            <div className="label">Client Full Name</div>
                            <div className="entity">
                              {PracticesDataforForm?.client_name}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Legal Business Name</div>
                            <div className="entity">
                              {" "}
                              {PracticesDataforForm?.practice_name}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Group NPI</div>
                            <div className="entity">
                              {PracticesDataforForm?.group_npi}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Tax ID</div>
                            <div className="entity">
                              {" "}
                              {PracticesDataforForm?.practice_tax_id}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Speciality</div>
                            <div className="entity">
                              {PracticesDataforForm?.speciality}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Taxonomy</div>
                            <div className="entity">
                              {PracticesDataforForm?.taxnomy}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">CLIA</div>
                            <div className="entity">
                              {PracticesDataforForm?.clia}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">CLIA Expiry</div>
                            <div className="entity">
                              {PracticesDataforForm?.clia_expiry}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div class="smHd mt-2">Services </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="patientBgSummary">
                          <div className="SummaryBlock">
                            <div className="label">Service Address</div>
                            <div className="entity">
                              {PracticesDataforForm?.service_address}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">City</div>
                            <div className="entity">
                              {PracticesDataforForm?.service_city}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">State</div>
                            <div className="entity">
                              {PracticesDataforForm?.service_state}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Zip</div>
                            <div className="entity">
                              {PracticesDataforForm?.service_zip}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Country</div>
                            <div className="entity">
                              {PracticesDataforForm?.service_country}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Fax #</div>
                            <div className="entity">
                              {PracticesDataforForm?.service_fax}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Office phone #</div>
                            <div className="entity">
                              {PracticesDataforForm?.service_phone}
                            </div>
                          </div>
                          {/* <div className="SummaryBlock">
                            <div className="label">field is pending by me</div>
                            <div className="entity">John Smith</div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div class="smHd mt-2">Pay to Address</div>
                      </div>
                      <div className="col-sm-12">
                        <div className="patientBgSummary">
                          <div className="SummaryBlock">
                            <div className="label">Service Address</div>
                            <div className="entity">
                              {PracticesDataforForm?.pay_address}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">City</div>
                            <div className="entity">
                              {PracticesDataforForm?.pay_city}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">State</div>
                            <div className="entity">
                              {PracticesDataforForm?.pay_state}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Zip</div>
                            <div className="entity">
                              {PracticesDataforForm?.pay_zip}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Country</div>
                            <div className="entity">
                              {PracticesDataforForm?.pay_country}
                            </div>
                          </div>
                          {/* <div className="SummaryBlock">
                            <div className="label">Fax</div>
                            <div className="entity">John Smith</div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Office Phone</div>
                            <div className="entity">John Smith</div>
                          </div> */}
                          {/* <div className="SummaryBlock">
                            <div className="label">field is pending by me</div>
                            <div className="entity">John Smith</div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div class="smHd mt-2">List of Contacts</div>
                      </div>
                      <div className="col-sm-12">
                        <div className="patientBgSummary">
                          <div className="SummaryBlock">
                            <div className="label"> Contact Person/Name</div>
                            <div className="entity">
                              {PracticesDataforForm?.contact1_name}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Contact1 Email</div>
                            <div className="entity">
                              {PracticesDataforForm?.contact1_email}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Contact1 phone #</div>
                            <div className="entity">
                              {" "}
                              {PracticesDataforForm?.contact1_phone}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Contact1 Mobile</div>
                            <div className="entity">
                              {" "}
                              {PracticesDataforForm?.contact1_mobile}
                            </div>{" "}
                          </div>
                          {/* <div className="SummaryBlock">
                            <div className="label">Contact2</div>
                            <div className="entity">John Smith</div>
                          </div> */}
                          <div className="SummaryBlock">
                            <div className="label">Contact2 Email</div>
                            <div className="entity">
                              {" "}
                              {PracticesDataforForm?.contact2_email}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Contact2 phone #</div>
                            <div className="entity">
                              {PracticesDataforForm?.contact2_phone}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Contact2 Mobile</div>
                            <div className="entity">
                              {" "}
                              {PracticesDataforForm?.contact2_mobile}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Owner</div>
                            <div className="entity">
                              {" "}
                              {PracticesDataforForm?.onwer_name}
                            </div>
                          </div>
                          <div className="SummaryBlock">
                            <div className="label">Email</div>
                            <div className="">
                              {" "}
                              {PracticesDataforForm?.onwer_email}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Patient summary end           */}
                </div>

                <QuickUpdatesProviders
                  data={ProviderDataforForm ?? ""}
                />

                {/* <div class="col-sm-6 col-lg-12 ">
									<div class="form-group ">
										<div className="d-flex justify-content-end">
											<button className="btnClear mr-1  mb-3">Clear</button>
											<button
												type="button"
												className="btnSave mb-3"
												onClick={updatePracticesData}
											>
												Save
											</button>
										</div>
									</div>
								</div> */}
              </>
            )}
          </>
        </>
      

      <div class="row col-lg-12 d-flex"></div>
    </>
  );
};

export default QuickUpdateForm1;
