import { ApiCall } from "./ApiCall";

//--------------------------Sign Off---------------------------


let localnode = process.env.REACT_APP_LOCAL_SERVER_URL;



export const LoginCredientials = (obj) => {
  console.log(localnode);
  let path = localnode + "user/login";
  return ApiCall(path, "post", obj);
};



export const getProviderRecords = (obj) => {
  const queryParam = `getProvidersRecords`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getNasts = (obj) => {
  const queryParam = `nasts/get`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const getPayerData = (obj) => {
  const queryParam = `payer/getPayers`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const payersForDropDown = () => {
  const queryParam = `payer/payersdropdown`;
  let path = localnode + queryParam;
  return ApiCall(path, "get");
};
export const getPracticesRecords = (obj) => {
  const queryParam = `getPracticesRecords`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getPracticesForDropDown = () => {
  const queryParam = `getPracticeDataForDropDown`;
  let path = localnode + queryParam;
  return ApiCall(path, "get");
};

export const gettaxonomyasspecialityAndProvidersAgainstPractices = () => {
  const queryParam = `gettaxonomyasspeciality`;
  let path = localnode + queryParam;
  return ApiCall(path, "get");
};



export const createProvider = (obj) => {
  const queryParam = `createProvider`;
  let path = localnode + queryParam;
  return ApiCall(path, "post",obj);
};
export const getDataProviderDate = (obj) => {
  const queryParam = `getDataProviderDate/${obj}`;
  let path = localnode + queryParam;
  return ApiCall(path, "get",obj);
};
export const getDataForviewDate = (obj) => {
  const queryParam = `getDataForviewDate`;
  let path = localnode + queryParam;
  return ApiCall(path, "post",obj);
};
export const getUsaSatesCodes = () => {
  const queryParam = `getUsaSatesCodes`;
  let path = localnode + queryParam;
  return ApiCall(path, "get");
};
export const getDocuments = (obj) => {
  const queryParam = `getDocuments`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getDocumentTypes = () => {
  const queryParam = `getDocumentTypes`;
  let path = localnode + queryParam;
  return ApiCall(path, "get");
};
export const postDocumentsData = (obj) => {
  const queryParam = `upload`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const updatePayerName = (id, obj) => {
  const queryParam = `payer/payers/${id}`;
  let path = localnode + queryParam;
  return ApiCall(path, "put", obj);
};
export const createNewPayer = (obj) => {
  const queryParam = `payer/addPayers`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const createNewPractices = (obj) => {
  const queryParam = `postDataIntoPractice`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const deletePractice = (obj) => {
  const queryParam = `deletePractice/${obj}`;
  let path = localnode + queryParam;
  return ApiCall(path, "delete");
};
export const deleteProvider = (obj) => {
  const queryParam = `deleteProvider/${obj}`;
  let path = localnode + queryParam;
  return ApiCall(path, "delete");
};

export const getPracticeDataForUpdate = (obj) => {
  const queryParam = `getPracticeDataForUpdate/${obj}`;
  let path = localnode + queryParam;
  return ApiCall(path, "get");
};

export const updateProviderStatus = (obj) => {
  const queryParam = `updateProviderStatus`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

// All insert

export const viewDocumnets = (fileName) => {
  const queryParam = `getDocuments/${fileName}`;
  let path = localnode + queryParam;
  return ApiCall(path, "get");
};

export const viewNastDocumnets = (folderName,fileName) => {
  const queryParam = `getdocuments/${folderName}/${fileName}`;
  let path = localnode + queryParam;
  return ApiCall(path, "get","",true);
};
export const addNastsRecords = (obj) => {
  const queryParam = `nasts/addNasts`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const postEdiRemarks = (obj) => {
  const queryParam = `postEdiremarksData`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const postEdidocuments = (obj) => {
  const queryParam = `postEdiDocuments`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getSummaryData = (obj) => {
  const queryParam = `getSummaryData`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const getPaginatedData = (obj) => {
  const queryParam = `getPaginatedData`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};
export const updateNastsdata = (id, obj) => {
  const queryParam = `nasts/updateNastsRecord/${id}`;
  let path = localnode + queryParam;
  return ApiCall(path, "put", obj);
};
export const ediremarksupdatede = (id, obj) => {
  const queryParam = `updateediremarks/${id}`;
  let path = localnode + queryParam;
  return ApiCall(path, "put", obj);
};
export const allUsers = ( obj) => {
  const queryParam = `user/users`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const deleteNastsdata = (id, obj) => {
  const queryParam = `nasts/deleteNastsRecords/${id}`;
  let path = localnode + queryParam;
  return ApiCall(path, "delete", obj);
};

// export const getdataForQuick = (obj) => {
//   const queryParam = `getPracticeDataForUpdateForQuick`;
//   let path = localnode + queryParam;
//   return ApiCall(path, "post", obj);
// };
export const getQuickprovData = (obj) => {
  const queryParam = `getQuick`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
};

export const getReemarks=(obj)=>{
  const queryParam = `remarks`;
  let path = localnode + queryParam;
  return ApiCall(path, "post", obj);
}
export const UpdateQuickFormUpdate = (obj) => {
  const queryParam = `updateQuickUpdates`;
  let path = localnode + queryParam;
  return ApiCall(path, "post",obj);
};




export const getProvidersRecordsByIds = (obj) => {
  const queryParam = `getProvidersRecordsByIds`;
  let path = localnode + queryParam;
  return ApiCall(path, "post",obj);
};


export const getPayerForProvider = (obj) => {
  const queryParam = `getPyers`;
  let path = localnode + queryParam;
  return ApiCall(path, "post",obj);
};

export const postDataToactivity = (obj) => {
  const queryParam = `postActivityRecords`;
  let path = localnode + queryParam;
  return ApiCall(path, "post",obj);
};
export const getUserNames = () => {
  const queryParam = `getUseNmaesfromActivity`;
  let path = localnode + queryParam;
  return ApiCall(path, "get");
};
export const getTeamWiseData = (obj) => {
  const queryParam = `getTeamWiseData`;
  let path = localnode + queryParam;
  return ApiCall(path, "post",obj);
};
export const registerUser = (obj) => {
  const queryParam = `user/register`;
  let path = localnode + queryParam;
  return ApiCall(path, "post",obj);
};

export const UserAudit = (obj) => {
  const queryParam = `Useraudits`;
  let path = localnode + queryParam;
  return ApiCall(path, "post",obj);
};
export const ProviderAudit = (obj) => {
  const queryParam = `providerAudits`;
  let path = localnode + queryParam;
  return ApiCall(path, "post",obj);
};
export const RemarksAudit = (obj) => {
  const queryParam = `remarksAudit`;
  let path = localnode + queryParam;
  return ApiCall(path, "post",obj);
};
export const UploadDocumentstoS3 = (obj,edi) => {
  const queryParam = `/saveDocuments/${edi}`;
  let path = localnode + queryParam;
  return ApiCall(path, "post" ,obj,false,true);
};