import React, { useEffect, useState } from "react";

import Select from "react-select";
import ImageUploaderr from "../../../Common/FileUpload";
import { useSelector } from "react-redux";
import {
  gettaxonomyasspecialityAndProvidersAgainstPractices,
  getDocumentTypes,
  postDocumentsData,
  getPayerForProvider,
  postDataToactivity,
} from "../../../Utils/ApiManager";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader/Loader";
import axios from "axios";

const LogDailyActivity = () => {
  const { state, onLoginCredentialing, Specialities } = useSelector(
    (state) => state
  );

  console.log(onLoginCredentialing, "state");
  const [options, setOptionsForPracticeDropdown] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);

  const [activityType, setActivityType] = useState([
    { value: "submitted", label: "Submitted" },
    { value: "followup", label: "followup" },
    { value: "pending-provider", label: "	pending-provider" },
    { value: "in-process", label: "in-process" },
    { value: "approved", label: "approved" },
    { value: "call", label: "call" },
    { value: "refered", label: "Referred" },
    { value: "researcg", label: "Research" },

    { value: "other", label: "other" },
  ]);

  const [isLoading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    payerId: "",
    providerOptionsId: null,
    activityTypeee: "",
    remarks: "",
  });

  const getProvider =
    Specialities.length > 0 &&
    Specialities?.map((item) => ({
      value: item.id,
      label:item.last_name+ "  " + item.name  ,
    }));

  const handleDateChange = (event) => {
    const { name, value } = event.target;

    setSelectedOption((prevState) => ({
      ...prevState,
      remarks: value ? value : "",
    }));
  };

  useEffect(() => {
    // setOptionsForPracticeDropdown(practiceNames);
    setProviderOptions(getProvider);
  }, [selectedOption?.payerId]);
  console.log();
  const handleChangeselect = (selectedValue, selectedOption) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      payerId: selectedOption ? selectedOption : "",
    }));
  };

  const handleChangeProvider = (selectedValue, selectedOption) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      providerOptionsId: selectedOption ? selectedOption : "",
    }));
    setSelectedOption((prevState) => ({
      ...prevState,
      payerId: "",
      activityTypeee: "",
      remarks: "",
    }));
    fetchPyers(selectedOption);
  };

  console.log(selectedOption, "selectedOption");
  const handleChangeDocumnetTyp = (selectedValue, selectedOption) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      activityTypeee: selectedOption.value ? selectedOption.value : "",
    }));
  };

  const fetchPyers = async (id) => {
    try {
      const payers = await getPayerForProvider({ providerId: id.value });
      console.log(payers.data.payers, "payers");
      const getPyers =
        payers?.data?.payers?.length > 0 &&
        payers.data.payers?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      setOptionsForPracticeDropdown(getPyers);
    } catch (error) {
      console.log(error, "payers");
    }
  };
  let payload = {
    provider_name: selectedOption?.providerOptionsId?.label,
    payer: selectedOption?.payerId?.label,
    user: onLoginCredentialing?.user?.name,
    user_id: onLoginCredentialing?.user?.id,
    email: onLoginCredentialing?.user?.email,
    action: selectedOption?.activityTypeee,
    description: selectedOption?.remarks,
  };
  console.log(payload.provider_name, "payload.provider_name");
  const newActivityRecords = async () => {
    try {
      if (payload.provider_name == undefined && payload.payer == undefined) {
        toast.error("Some Fileds are missing");
      } else {
        setLoading(true);

        const responce = await postDataToactivity(payload);
        console.log(responce, "activity");
        if (responce.status == 201) {
          toast.success("Record created Successfully");
          onClear()
        }
        setLoading(false);
      }
    } catch (error) {
      toast.success("Something went Wrong");
      setLoading(false);
    }
  };
  const onClear = () => {
    setSelectedOption((prevState) => ({
      ...prevState,
      payerId: "",
      providerOptionsId: "",
      activityTypeee: "",
      remarks: "",
    }));
  };
  return (
    <div class="container w-100">
      <div
        class="row"
        style={{
          backgroundColor: "#fff",

          border: "1px solid #F0F8FF",
        }}
      >
        <div class="col-sm-6 col-lg-4">
          <div class="form-group mb-2 ">
            <label for="staticEmail2 " class=" mt-3">
              <h4>Select Provider </h4>
            </label>
            <Select
              options={providerOptions ? providerOptions : ""}
              placeholder="Please Select Practice"
              value={selectedOption.providerOptionsId}
              isDisabled={isLoading}
              onChange={(selectedOption) =>
                handleChangeProvider(
                  Number(selectedOption.value),
                  selectedOption
                )
              }
            />{" "}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 mb-3">
          <div class="form-group mb-2 ">
            <label for="staticEmail2 " class=" mt-3">
              <h4>Application </h4>
            </label>
            <Select
              options={options ? options : ""}
              placeholder="Select an option"
              value={selectedOption.payerId}
              isDisabled={isLoading}
              // Use the original Select component
              onChange={(selectedOption) => {
                handleChangeselect(
                  Number(selectedOption.value),
                  selectedOption
                );
              }}
            />{" "}
          </div>
        </div>

        <div class="col-sm-6 col-lg-4">
          <div class="form-group mb-2 ">
            <label for="staticEmail2 " class=" mt-3">
              <h4>Activity </h4>
            </label>
            <Select
              options={activityType ? activityType : ""}
              placeholder="Please Select Payer Practice"
              value={selectedOption.activityTypeee}
              isDisabled={isLoading}
              onChange={(selectedOption) =>
                handleChangeDocumnetTyp(
                  Number(selectedOption.value),
                  selectedOption
                )
              }
            />{" "}
          </div>
        </div>
        <div class="col-sm-6 col-lg-12">
          <div class="form-group form-inline  mb-2">
            <label class="inputLabel pt-2  w-100" style={{ color: "black" }}>
              Reamrks/Description
            </label>
            <textarea
              className="form-control inputField w-100"
              type="test"
              name="remarks"
              isDisabled={isLoading}
              placeholder=""
              value={selectedOption.remarks}
              onChange={handleDateChange}
            />
          </div>
        </div>

        <div class="row col-lg-12 d-flex"></div>
        <div class="col-sm-6 col-lg-12 mt-2">
          <div class="form-group ">
            <div className="d-flex justify-content-end mt-4">
              <button onClick={onClear} className="btnClear mr-1 ">
                Clear
              </button>
              <button
                onClick={newActivityRecords}
                type="button"
                className="btnSave"
              >
                Save{" "}
                {isLoading ? (
                  <Loader small={true} large={false} forButton={true} />
                ) : undefined}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogDailyActivity;
