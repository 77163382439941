import React, { useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getProvidersRecordsByIds,
  registerUser,
} from "../../../Utils/ApiManager";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import MultiSelect from "../../../Common/multiselect";
import Loader from "../../../Common/Loader/Loader";

const AddUsers = () => {
  const { practiceName } = useSelector((state) => state);
  const UserRoleoptions = [
    { value: "1", label: "Agent" },
    { value: "2", label: "Team Lead" },
    { value: "3", label: "Provider" },
    { value: "4", label: "Manager" },
    { value: "5", label: "Super" },
    { value: "6", label: "Prvider Office" },
    { value: "7", label: "Ops Manager" },
  ];

  const [providerPayer, setProviderPayer] = useState({
    practiceId: [],
    providerId: [],
    role_Id: "",
  });
  const [options, setOptions] = useState([]);
  const [provider, setProviderData] = useState([]);
  const [loadingn, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    pass: "",
    conpass: "",
    providerId: [],
  });
  const [selectIdss, setSelelectIdss] = useState();
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handleVlaues = (value, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value.trim(), // Trim spaces from the value
    }));
  };

  const handlePasswordChange = (value) => {
    handleVlaues(value, "pass");
    if (formData.conpass) {
      setPasswordsMatch(value === formData.conpass);
    }
  };

  const handleConfirmPasswordChange = (value) => {
    handleVlaues(value, "conpass");
    setPasswordsMatch(formData.pass === value);
  };

  useEffect(() => {
    if (practiceName.length > 0) {
      setOptions(practiceName);
    }
  }, [practiceName]);

  console.log(options, "options");
  const fetchProviderData = async (ids) => {
    let newProviders = [];
    setLoading(true);
    try {
      const responce = await getProvidersRecordsByIds({ ids: ids });
      // if (responce.status == 200) {
      //   // toast.success("success");
      // }
      console.log(responce, "responce");
      const newData = responce?.data?.providers.map((item) => ({
        ...item,
        label: `${item.name} ${item.last_name}`,
      }));
      const uniqueProviderIds = new Set();
      const filteredData = newData.filter((item) => {
        if (!uniqueProviderIds.has(item.id)) {
          uniqueProviderIds.add(item.id);
          return true;
        }
        return false;
      });
      setProviderData(filteredData);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };
  const handleData = (data, propertyName) => {
    if (true) {
      console.log(data, propertyName, "dataaa");

      if (data.length > providerPayer.practiceId.length) {
        setProviderPayer({
          ...providerPayer,
          practiceId: data.map((v) => ({ id: v.id })),
        });
      } else if (data.length < providerPayer.practiceId.length) {
        let newIds = data.map((v) => v.value);
        let filteredProviderIds = providerPayer.practiceId.filter((v) =>
          newIds.includes(v.value)
        );
        setProviderPayer({
          ...providerPayer,
          practiceId: filteredProviderIds.map((v) => ({ id: v.id })),
        });
      } else {
        // Handle other cases if needed
      }
    }

    if (propertyName == "Roles") {
      setProviderPayer({
        ...providerPayer,
        role_Id: data.value,
      });
    }
  };

  const onSelectPracticeRemove = async (selectedOption) => {
    console.log(selectedOption, provider);
    console.log(formData.providerId, "id");
    const ids = selectedOption.map((item) => item.value);
    const updatedProviders = provider.filter((prov) =>
      ids.includes(prov.practice_id)
    );

    setProviderData(updatedProviders);
    setFormData({
      ...formData,
      providerId: updatedProviders.map((prov) => prov.id),
    });
    console.log(ids, "ids");
    console.log(updatedProviders);
    if (ids.length > 0 && ids !== null) {
      // fetchProviderData(ids);
    } else {
      setProviderData([]);
    }
  };

  const onSelectPractice = (selectedOption) => {
    console.log(selectedOption, "selected");
    const ids = selectedOption.map((item) => item.value);
    setSelelectIdss(ids);
    fetchProviderData(ids);
  };
  const handleProviders = (selectedOption) => {
    console.log(selectedOption, "selectedOption");
  };
  console.log(providerPayer, "{{{{{{");
  let objCss = {
    chips: {
      // To change css chips(Selected options)
      background: "rgb(14, 116, 144)",
      padding: "4px 24px",
    },
    option: {
      // To change css for dropdown options
      backgroundColor: "rgb(14, 116, 144) !important",
    },

    highlightOption: {
      background: "rgb(14, 116, 144) !important",
      color: "#fff",
    },
    searchWrapper: {
      // To change css for multiselect (Width,height,etc..)
      maxHeight: "100px", // Set a maximum height for the container
      overflowY: "auto", // Add a vertical scrollbar when needed
    },
    //   optionContainer: { // To change css for option container
    //     border: '2px solid',
    //     color: 'rgb(14, 116, 144)'
    //   }
  };
  console.log(formData, "User");
  console.log(providerPayer, "providerPayer");
  console.log(selectIdss, "ffdfddffdf");
  const handleFormSubmit = async () => {
    if (passwordsMatch && formData.email !== "" && formData.name !== "") {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

      if (!emailRegex.test(formData.email)) {
        toast.error("Please enter a valid email address");
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        let obj = {};
        obj.name = formData.name;
        obj.email = formData.email;
        obj.password = formData.pass;
        const responce = await registerUser(obj);
        console.log(responce, "responce");
        if (responce.status == 201) {
          toast.success("User created Successfully ");
        }
        setLoading(false);
      } catch (error) {
        console.log(error, "error");
        if (error.response.status == 400) {
          toast.error("User email already exist");
        }
        setLoading(false);
      }
    } else {
      toast.error("Please Correct Information");
      setLoading(false);
    }
  };

  return (
    <div
      class="row"
      style={{
        backgroundColor: "#fff",
        paddingRight: "10px",
        borderRadius: "30px",
        border: "1px solid #F0F8FF",
        marginLeft: "10px",
      }}
    >
      <div className="col-sm-6 col-lg-12 mt-3">
        <h5>Personal Information:</h5>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group form-inline  mb-2">
          <label
            class="inputLabel pt-2  w-100"
            style={{ color: "black", marginRight: "6px" }}
          >
            Name*
          </label>
          <input
            class="form-control inputField  w-100 w-100"
            type="text"
            name="name"
            value={formData.name}
            onChange={(e) => handleVlaues(e.target.value, "name")}
            placeholder=""
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="form-group form-inline  mb-2">
          <label
            class="inputLabel pt-2  w-100"
            style={{ color: "black", marginRight: "6px" }}
          >
            Email*
          </label>
          <input
            class="form-control inputField  w-100 w-100"
            type="text"
            name="email"
            placeholder=""
            value={formData.email}
            onChange={(e) => handleVlaues(e.target.value, "email")}
          />
        </div>
      </div>{" "}
      <div class="col-sm-6 col-lg-3">
        <div class="form-group form-inline  mb-2">
          <label
            class="inputLabel pt-2  w-100"
            style={{ color: "black", marginRight: "6px" }}
          >
            Password
          </label>
          <input
            class="form-control inputField  w-100 w-100"
            type="password"
            name="pass"
            placeholder=""
            value={formData.pass}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
        </div>
      </div>{" "}
      <div class="col-sm-6 col-lg-3">
        <div class="form-group form-inline  mb-2">
          <label
            class="inputLabel pt-2  w-100"
            style={{ color: "black", marginRight: "6px" }}
          >
            Confirm Password
          </label>
          <input
            class="form-control inputField  w-100 w-100"
            type="password"
            name="conpass"
            placeholder=""
            value={formData.conpass}
            onChange={(e) => handleConfirmPasswordChange(e.target.value)}
          />
        </div>
        {!passwordsMatch && (
          <p className="text-danger">Passwords do not match</p>
        )}
      </div>{" "}
      <div class="col-sm-6 col-lg-6">
        <div class="form-group mb-2 ">
          <label for="staticEmail2 " class="mt-2 ">
            User Role
          </label>
          <Select
            options={UserRoleoptions}
            placeholder="Please Select Roles"
            onChange={(data) => handleData(data, "Roles")}
          />{" "}
        </div>
      </div>
      <div className="col-sm-6 col-lg-12 mt-3">
        <h5>Assign Providers:</h5>
      </div>
      <>
        <div class="col-sm-6 col-lg-6 ">
          <div class="form-group1  mb-2 ">
            <label
              class="inputLabel "
              style={{ color: "black", marginRight: "6px" }}
            >
              <h5> Select Practice</h5>{" "}
            </label>
            <Multiselect
              options={options ? options : []}
              onSelect={onSelectPractice}
              onRemove={onSelectPracticeRemove}
              style={objCss}
              showArrow={true}
              showCheckbox={true}
              placeholder="Please Select Practice"
              displayValue="label" // Property name to display in the dropdown options
            />
            <label
              class="inputLabel"
              style={{ color: "black", fontSize: "13px", color: "red" }}
            ></label>
          </div>
        </div>
      </>{" "}
      <div class="col-sm-6 col-lg-6">
        <div class="form-group1  mb-2">
          <label
            class="inputLabel  "
            style={{ color: "black", marginRight: "6px" }}
          >
            <h5>Select Providers</h5>
          </label>

          <MultiSelect
            infiniteScroll
            showAll
            truncate={23}
            showBubble
            bubbleColor="#C0723C"
            optionSize="13px"
            // showBubble
            width="100%"
            searchIcon={"/assets/icons/Search.png"}
            data={provider?.map((pr) => {
              {
                console.log(pr.id, "newids");
              }
              return {
                display: pr.label,
                value: pr.id,
                id: pr.id,
                ...(formData.providerId.includes(pr.id) && { status: true }),
              };
            })}
            handleData={(data) => {
              console.log(data);
              setFormData({
                ...formData,
                providerId: data.map((d) => d.id),
              });
            }}
            border="1px solid #E5E5E5"
          />
        </div>
      </div>
      <div class="col-sm-6 col-lg-12 ">
        <div class="form-group ">
          <div className="d-flex justify-content-end mt-2">
            <button className="btnClear mr-1 ">Clear</button>
            <button
              type="button"
              className="btnSave"
              onClick={handleFormSubmit}
              disabled={loadingn}
            >
              Save
              {loadingn ? (
                <Loader small={true} large={false} forButton={true} />
              ) : undefined}
            </button>
          </div>
        </div>
      </div>
      {/* <div class="col-sm-6 col-lg-3">
            <div class="form-group form-inline  mb-2">
              <label
                class="inputLabel pt-2  w-100"
                style={{ color: "black", marginRight: "6px" }}
              >
                SSN
              </label>
              <input
                class="form-control inputField  w-100 w-100"
                type="text"
                name="sbf"
                placeholder=""
              />
            </div>
          </div>{" "} */}
    </div>
  );
};

export default AddUsers;
