import React, { useEffect, useState } from "react";

import Select from "react-select";
import ImageUploaderr from "../../../Common/FileUpload";
import { useSelector } from "react-redux";
import {
  gettaxonomyasspecialityAndProvidersAgainstPractices,
  getDocumentTypes,
  postDocumentsData,
  UploadDocumentstoS3,
} from "../../../Utils/ApiManager";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader/Loader";

const AddDocument = (props) => {
  const { state, onLoginCredentialing, Specialities } = useSelector(
    (state) => state
  );
  const [options, setOptionsForPracticeDropdown] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);

  const [documnetsType, setdocumnetsType] = useState([]);
  const [dateValues, setDateValues] = useState({
    issueDate: "",
    expiryDate: "",
  });
  const [file, setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    practticeid: "",
    providerOptionsId: "",
    documenttype: "",
  });
  console.log(options, "options");
  const practiceNames = Specialities?.map((item) => ({
    label: item.practice_name,
    value: item.practice_id,
  }));
  console.log();
  const getProvider = Specialities?.filter((item) => {
    return selectedOption.practticeid.value === item.practice_id;
  }).map((item) => ({
    label: item.name + " " + item.last_name,
    value: item.id,
  }));

  console.log(getProvider, "getProviders");
  const fetchDocumnetTypes = async () => {
    try {
      await getDocumentTypes().then((res) => {
        console.log(res.data.documentTypes, "getDocumnetTypes");
        const Dt = res?.data?.documentTypes.map((item) => ({
          label: item.type,
          value: item.id,
        }));
        setdocumnetsType(Dt);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setDateValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  useEffect(() => {
    setOptionsForPracticeDropdown(practiceNames);
    setProviderOptions(getProvider);
    fetchDocumnetTypes();
  }, [selectedOption?.practticeid]);
  console.log();
  const handleChangeselect = (selectedValue, selectedOption) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      practticeid: selectedOption ? selectedOption : "",
    }));
  };
  const handleChangeProvider = (selectedValue, selectedOption) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      providerOptionsId: selectedOption ? selectedOption : "",
    }));
    // setProviderOptionsId(selectedValue);
  };
  const handleChangeDocumnetTyp = (selectedValue, selectedOption) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      documenttype: selectedOption ? selectedOption : "",
    }));
  };
  const handleInputChange = (event) => {
    setFile(event[0]);
  };

  console.log(file, "image");
  // console.log(selectedOption.practticeid, "practice_id");
  // console.log(selectedOption.providerOptionsId, "providerOptionsId");
  // console.log(documnetsTypeId, "fsdfsdfsdfs");
  const postFormData = async () => {
    if (
      !selectedOption?.providerOptionsId?.value ||
      !selectedOption?.documenttype?.value ||
      !file ||
      !dateValues.issueDate ||
      !dateValues.expiryDate ||
      !onLoginCredentialing?.user?.id
    ) {
      toast.error("Required fields are missing");
      setLoading(false);
      return; // Exit the function if any required field is missing
    }
    try {
      setLoading(true);
      const formData1 = new FormData();
      formData1.append("provider_id", selectedOption?.providerOptionsId?.value);
      formData1.append("document_type_id", selectedOption?.documenttype?.value);
      formData1.append("name", file?.name);
      formData1.append("user_id", onLoginCredentialing?.user?.id);
      formData1.append("issue_date", dateValues.issueDate);
      formData1.append("expiry_date", dateValues?.expiryDate);
      formData1.append("deleted", false);
      formData1.append("file", file);

      console.log(formData1, "hello Setup");
      await UploadDocumentstoS3(formData1, "provider").then((responce) => {
        if (responce.status) {
          toast.success("data uploaded successfully");
        }
        // props.modalCloseCall()
        console.log(responce, "responce");
      });
      onClear();
      props.modelCLoseCall()
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error("Required fields are missing");
      } else {
        toast.error("An error occurred while uploading the data and file");
      }
      setLoading(false);
    }
  };
  const onClear = () => {
    setDateValues({ issueDate: "", expiryDate: "" });
    setSelectedOption((prevState) => ({
      ...prevState,
      practticeid: "",
      providerOptionsId: "",
      documenttype: "",
    }));
    setFile(null);
  };
  return (
    <div class="container ">
      <div
        class="row"
        style={
          {
            // backgroundColor: "#F0F8FF",
            // paddingRight: "10px",
            // borderRadius: "7px",
            // border: "1px solid #F0F8FF",
          }
        }
      >
        <div class="col-sm-6 col-lg-4 mb-3">
          <div class="form-group mb-2 ">
            <label for="staticEmail2 " class=" mt-3">
              <h4>Select Practice </h4>
            </label>
            <Select
              options={options ? options : ""}
              placeholder="Select an option"
              value={selectedOption.practticeid}
              isDisabled={isLoading}
              // Use the original Select component
              onChange={(selectedOption) => {
                handleChangeselect(
                  Number(selectedOption.value),
                  selectedOption
                );
              }}
            />{" "}
          </div>
        </div>

        <div class="col-sm-6 col-lg-4">
          <div class="form-group mb-2 ">
            <label for="staticEmail2 " class=" mt-3">
              <h4>Select Provider </h4>
            </label>
            <Select
              options={providerOptions ? providerOptions : ""}
              placeholder="Please Select Practice"
              value={selectedOption.providerOptionsId}
              isDisabled={isLoading}
              onChange={(selectedOption) =>
                handleChangeProvider(
                  Number(selectedOption.value),
                  selectedOption
                )
              }
            />{" "}
          </div>
        </div>

        <div class="col-sm-6 col-lg-4">
          <div class="form-group mb-2 ">
            <label for="staticEmail2 " class=" mt-3">
              <h4>Document Type </h4>
            </label>
            <Select
              options={documnetsType ? documnetsType : ""}
              placeholder="Please Select Payer Practice"
              value={selectedOption.documenttype}
              isDisabled={isLoading}
              onChange={(selectedOption) =>
                handleChangeDocumnetTyp(
                  Number(selectedOption.value),
                  selectedOption
                )
              }
            />{" "}
          </div>
        </div>
        <div class="col-sm-6 col-lg-6">
          <div class="form-group form-inline  mb-2">
            <label class="inputLabel pt-2  w-100" style={{ color: "black" }}>
              Issue Date
            </label>
            <input
              className="form-control inputField w-100"
              type="date"
              name="issueDate"
              isDisabled={isLoading}
              placeholder=""
              value={dateValues.issueDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
        <div class="col-sm-6 col-lg-6">
          <div class="form-group form-inline  mb-2">
            <label class="inputLabel pt-2  w-100" style={{ color: "black" }}>
              Expiry Date
            </label>
            <input
              className="form-control inputField w-100"
              type="date"
              name="expiryDate"
              isDisabled={isLoading}
              placeholder=""
              value={dateValues.expiryDate}
              onChange={handleDateChange}
            />
          </div>
        </div>

        <div class=" col-lg-12 mt-2">
          <div class="form-group1 ">
            <label class="inputLabel" style={{ color: "black" }}>
              Please Select a File
            </label>
            <ImageUploaderr
              handleInputChange={handleInputChange}
              image={file}
            />
          </div>
        </div>
        <div class="row col-lg-12 d-flex"></div>
        <div class="col-sm-6 col-lg-12 mt-2">
          <div class="form-group ">
            <div className="d-flex justify-content-end mt-4">
              <button onClick={onClear} className="btnClear mr-1 ">
                Clear
              </button>
              <button
                onClick={postFormData}
                disabled={isLoading}
                type="button"
                className="btnSave"
              >
                Save{" "}
                {isLoading ? (
                  <Loader small={true} large={false} forButton={true} />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDocument;
