import React, { useEffect, useState } from "react";
import UserAuditTable from "../../../Common/RsuitDataGrid/userTaskViewTable";
import AddDocument from "../addNewDocument/addDocument";
import Modal from "../../../Common/CommonModal";
import Select from "react-select";
import { getDocuments, viewDocumnets } from "../../../Utils/ApiManager";
import DataTable from "../../../Common/RsuitDataGrid/userTaskViewTable";
import { useSelector } from "react-redux";
import ImageUploaderr from "../../../Common/FileUpload";
import DocViewer from "../../../Common/DocViewer/DocViewer";
import AllDocumentsViewver from "../../../Common/AllDocumentsViewver";

const ViewDocument = (props) => {
  const [documentsData, setDocumentsData] = useState();
  const [loadin, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [docview, setDocView] = useState(false);
  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    limit: 10,
    page: 1,
    count: 0,
  });
  const [file, setFile] = useState();
  const handelViewDocument = (e, rowData) => {
    const pdfUrl = rowData;

    console.log(pdfUrl, "pdfUrl");

    setFile(pdfUrl.fileName);
    // viewDocumnet(pdfUrl.fileName);
    setDocView(true);
    // window.open(pdfUrl, "_blank");
  };

  const handleDelete = (e, rowData) => {
    
  };
  const arr = [
    { colName: "Document Type", flexGrow: 1.2, dataKey: "document_types" },
    { colName: "Provider", flexGrow: 1.2, dataKey: "providerName" },
    {
      colName: "File Name",
      flexGrow: 1.8,
      dataKey: "fileName",
      CustomCell: ({ data }) => {
        return <div> {data.slice(10)} </div>;
      },
    },
    { colName: "Issue Date", flexGrow: 0.8, dataKey: "issueDate" },
    {
      colName: "Expiry Date",
      flexGrow: 0.8,
      dataKey: "expiryDate",
      CustomCell: ({ data }) => {
        return <div style={{ color: "red" }}> {data} </div>;
      },
    },
    {
      colName: "Action",
      flexGrow: 0.5,
      dataKey: "id",
      actions: [
        {
          label: "fa fa-info-circle iconCol01 me-2",
          onClick: (rowData) => {
            handelViewDocument(
              rowData,
              documentsData?.documents.find((row) => row.id == rowData)
            );
          },
        },
        { label: "fas fa-trash iconCol03 ", onClick: handleDelete },
      ],
    },
  ];
  const handleFilter = () => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      fromDate: startDate,
      toDate: endDate,
    };
    let check = payload.fromDate == "" && payload.toDate == "";
    if (check) {
    } else {
      fetchDocuments(payload);
    }
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    const emptyPayload = {
      page: 1,
      limit: Number(paginationData.limit),
      fromDate: "",
      toDate: "",
    };

    let check = startDate == "" && endDate === "";
    if (check) {
      return;
    } else {
      fetchDocuments(emptyPayload);
    }
  };

  const fetchDocuments = async (payload) => {
    setLoading(true);
    try {
      await getDocuments(payload).then((res) => {
        setDocumentsData(res.data);
        setPaginationData((prevPaginationData) => ({
          ...prevPaginationData,
          count: res.data?.totalCount,
        }));
      });

      setLoading(false);
    } catch (error) {
      console.log(error, "douments");
    }
    setLoading(false);
  };

  useEffect(() => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
    };
    fetchDocuments(payload);
  }, [paginationData.page, paginationData.limit]);

  const handleChangePage = (newPage) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      page: newPage,
    }));
  };
  const handleChangeLimit = (newLimit) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      limit: newLimit,
      page: 1,
    }));
  };
  const handleChangeForDate = (event) => {
    const { name, value } = event.target;
    if (name == "start") {
      setStartDate(value);
      console.log(`${name}: ${value}`);
    }
    if (name == "end") {
      setEndDate(value);
      console.log(`${name}: ${value}`);
    }
    // Perform any additional logic with the selected dates
  };

  const openModal = () => {
    props.addNew(props.name);
  };

  const closeModal = () => {
    props.addNew("");
  };
  const closeModal2 = () => {
    fetchDocuments();
  };
  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Start Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="start"
              placeholder=""
              value={startDate}
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">End Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="end"
              placeholder=""
              value={endDate}
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mt-2">
            <div className="d-flex mt-4">
              <button className="btnClear mr-1" onClick={handleFilter}>
                Filter
              </button>
              <button type="button" className="btnSave" onClick={handleClear}>
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <DataTable
        Array={arr}
        dateForgrid={documentsData?.documents}
        loader={loadin}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        totalPages={documentsData?.totalCount}
        // onChangePop={handleActionbtn}
      />{" "}
      <Modal
        isOpen={props.addNewButton === props.name ? true : false}
        closeModal={closeModal}
        title={"Add New Document"}
      >
        <AddDocument modelCLoseCall={closeModal2}/> {/* Render your component inside the modal */}
      </Modal>
      {file ? (
        <Modal
          isOpen={docview}
          closeModal={() => setDocView(false)}
          title={"Viewe"}
        >
          <AllDocumentsViewver folderName={"provider"} fileName={file} />
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
export default ViewDocument;
