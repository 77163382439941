import React, { useEffect, useState } from "react";
import DocViewer from "../../../Common/DocViewer/DocViewer";
import { viewNastDocumnets } from "../../../Utils/ApiManager";
import Modal from "../../../Common/CommonModal";
import AllDocumentsViewver from "../../../Common/AllDocumentsViewver";

const NastInformation = (props) => {
  const { data } = props;
  console.log(data, "ihaveprops");
  const [ediDocandRemarks, setEdiDocandRemarks] = useState({
    docNmae: "",
    remarks: "",
  });
  const [docview, setDocView] = useState();
  const [file, setFile] = useState([]);
  const [isModalOpenRemark, setIsModalOpenRemarks] = useState(false);

  const getData = () => {
    const edidocumentsData = data?.edidocuments.map((document) => ({
      id: document.id,
      nast_id: document.nast_id,
      name: document.name,
    }));
    edidocumentsData.forEach((document) => {
      console.log(document, "name");
      setFile(document?.name);

      setEdiDocandRemarks((prev) => ({
        ...prev,
        docNmae: document.name,
      }));
    });
    const ediRemarks = data?.ediremarks.map((remarks) => ({
      id: remarks.id,
      nast_id: remarks.nast_id,
      remarks: remarks.remarks,
    }));

    ediRemarks.forEach((remarks) => {
      console.log(remarks);
      setEdiDocandRemarks((prev) => ({
        ...prev,
        remarks: remarks.remarks,
      }));
    });
  };
  useEffect(() => {
    getData();
  }, []);
  console.log(ediDocandRemarks.flag, "flag");

  console.log(ediDocandRemarks, "docdata");

  const viewDocumnet = (fileName) => {
    // console.log(fileName,"folee");
    // viewNastDocumnets(fileName)
    //   .then((res) => {
    //     // setBtnLoader(false);
    //     // setLoadingIndexd(null);
    //     console.log(res.data,"responceeee");
    setFile(fileName);
    // setDocView(true)
    setIsModalOpenRemarks(true);

    // .catch((error) => {
    //   // setBtnLoader(false);
    //   // setLoadingIndexd(null);
    //   console.log(error,"error");
    // });
  };

  // setDocView(true);

  console.log(file, "name");
  return (
    <>
      {" "}
      <div class="">
        {/* <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title" id="favoritesModalLabel">
            NAST Information
          </h4>
        </div> */}
        <div class="modal-body">
          <div
            class="section-heading"
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              marginBottom: "15px",
            }}
          >
            Practice
          </div>
          <div className="blueBottomLine"></div>
          <div> {""}</div>
          <div class="provider_formbox">
            {/* <div class="form-group">
							<label class="col-sm-2 control-label">
								<strong>Practice Name:</strong>
							</label>
							<label id="practice_name" class="col-sm-4 control-label">
								{data?.practice?.practice_name}
							</label>
							<label class="col-sm-3 control-label">
								<strong>Group NPI:</strong>
							</label>
							<label id="group_npi" class="col-sm-3 control-label">
								{data?.practice?.group_npi}
							</label>
							<div></div>
							<label class="col-sm-3 control-label">
								<strong>Practice Code:</strong>
							</label>
							<label id="practice_code" class="col-sm-3 control-label">
								{data?.practice?.practice_code}
							</label>
							<label class="col-sm-3 control-label">
								<strong>Tax ID:</strong>
							</label>
							<label id="practice_tax_id" class="col-sm-3 control-label">
								{data?.practice?.practice_tax_id}
							</label>
							<div></div>
						</div> */}
            <div class="form-group">
              <div className="fullSpace">
                <div className="halfSpace">
                  <div className="maxWid88">Practice Name:</div>
                  <label id="practice_name" class="">
                    {data?.practice?.practice_name}
                  </label>
                </div>
                <div className="halfSpace">
                  <div className="maxWid88">Group NPI:</div>
                  <label id="group_npi">{data?.practice?.group_npi}</label>
                </div>
                <div className="halfSpace">
                  <div className="maxWid88">Practice Code:</div>
                  <label id="practice_code">
                    {data?.practice?.practice_code}
                  </label>
                </div>
                <div className="halfSpace">
                  <div className="maxWid88">Tax ID:</div>
                  <label id="practice_tax_id">
                    {data?.practice?.practice_tax_id}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="section-heading"
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              marginBottom: "15px",
            }}
          >
            Documents
          </div>
          <div class="provider_formbox">
            <div class="form-group">
              <div className="tableStyle">
                <table width="100%">
                  <thead>
                    <tr>
                      <th width="25%">Date</th>
                      <th width="25%">User</th>
                      <th width="50%">View</th>
                    </tr>
                  </thead>
                  <tbody id="document_rows">
                    <tr>
                      <td>2023-07-10 14:53:52</td>
                      <td>{data?.User?.name}</td>
                      <td>
                        {ediDocandRemarks?.docNmae ? (
                          <>
                            {" "}
                            {
                            /* {ediDocandRemarks?.docNmae.substring(0, 15) +
                              "..."}{" "} */
                              ediDocandRemarks?.docNmae
                              }
                            <button
                              type="button"
                              className="btnSave "
                              onClick={() =>
                                viewDocumnet(ediDocandRemarks?.docNmae)
                              }
                            >
                              <i
                                class="fa fa-eye  "
                                aria-hidden="true"
                                style={{ color: "white" }}
                              ></i>
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            class="section-heading"
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              marginBottom: "15px",
            }}
          >
            Enrollment &amp; Remarks
          </div>
          <div class="provider_formbox">
            <div class="form-group">
              <div className="tableStyle">
                <table width="100%">
                  <thead>
                    <tr>
                      <th width="20%">Date</th>
                      <th width="20%">User</th>
                      <th width="20%">Enrollment</th>
                      <th width="40%">Remarks</th>
                    </tr>
                  </thead>
                  <tbody id="remarks_rows">
                    <tr>
                      <td>2023-07-10 14:53:52</td>
                      <td>{data?.User?.name}</td>
                      <td>{data?.Payer?.name}</td>
                      <td>{ediDocandRemarks?.remarks}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* {file ? (
              <DocViewer
                openImageViwerModel={() => {
                  setDocView(false);
                }}
                image={file}
                docViewer={docview}
              />
            ) : (
              ""
            )} */}
            {file ? (
              <Modal
                isOpen={isModalOpenRemark}
                closeModal={() => setIsModalOpenRemarks(false)}
                title={"Viewe"}
              >
                <AllDocumentsViewver folderName={"edi"} fileName={file} />
              </Modal>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NastInformation;
