import React, { useEffect, useState } from "react";
import AddPayer from "../addNewPayer/addPayer";
import Modal from "../../../Common/CommonModal";
import DataTable from "../../../Common/RsuitDataGrid/userTaskViewTable";
import { getPayerData } from "../../../Utils/ApiManager";

const ViewPayer = (props) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);
 
  const [providerData, setProvidersData] = useState([]);
  const [datUpdate, setDataUpdate] = useState();
  const [type, setType] = useState(false);

  const handelUpdateInfo = (e, rowData) => {
    const Info = rowData;
    setDataUpdate(rowData);
    setType(true);
    console.log(Info, "Info");
    props.addNew(props.name);
  };
  const arr = [
		{ colName: "Payer Name", flexGrow: 1.2, dataKey: "name" },
		{
			colName: "Created Date",
			flexGrow: 0.7,
			dataKey: "created_at",
			CustomCell: ({ data }) => {
				return <div>{data?.split("T")[0]}</div>;
			},
		},
		{
			colName: "Action",
			flexGrow: 0.4,
			dataKey: "id",
			actions: [
				{
					label: "fas fa-pencil-alt iconCol02 p-0",
					onClick: (rowData) => {
						handelUpdateInfo(
							rowData,
							providerData.data.find((row) => row.id == rowData)
						);
					},
				},
				// Add more action objects as needed
			],
		},
	];

  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    limit: 10,
    page: 1,
    count: 0,
  });

  useEffect(() => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
    };
    fetchData(payload);
  }, [paginationData.page, paginationData.limit]);

  const openModal = () => {
    props.addNew(props.name);
    setDataUpdate(false);
    setType(false)
  };
 
  const closeModal = () => {
    props.addNew('')
    // fetchData()
    setDataUpdate(false);
    setType(false)
  };
const closeAfterApiRes=()=>{
  props.addNew('')
  fetchData()
}
  const handleActionbtn = (row) => {};

  const handleFilter = () => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
    };
    if (payload.fromDate !== "" || payload.toDate !== "") {
      fetchData(payload);
    }
  };
  const handleClear = () => {
    setEndDate("");
    setStartDate("");
    const emptyPayload = {
      page: 1,
      limit: Number(paginationData.limit),
      fromDate: "",
      toDate: "",
    };

    let check = startDate === "" && endDate == "";
    if (check) {
    } else {
      fetchData(emptyPayload);
    }
  };
  const fetchData = async (payload) => {
    setLoading(true);
    try {
      const data = await getPayerData(payload);
      setProvidersData(data.data);
      setPaginationData((prevPaginationData) => ({
        ...prevPaginationData,
        count: data.data.count,
      }));

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleChangeLimit = (newLimit) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      limit: newLimit,
      page: 1,
    }));
  };

  const handleChangePage = (newPage) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      page: newPage,
    }));
  };
  const handleChangeForDate = (event) => {
    const { name, value } = event.target;
    if (name == "start") {
      setStartDate(value);
    }
    if (name == "end") {
      setEndDate(value);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Start Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="start"
              placeholder=""
              value={startDate}
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">End Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="end"
              placeholder=""
              value={endDate}
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mt-2">
            <div className="d-flex mt-4">
              <button className="btnClear mr-1" onClick={handleFilter}>
                Filter
              </button>
              <button onClick={handleClear} type="button" className="btnSave">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <DataTable
        Array={arr}
        dateForgrid={providerData.data}
        loader={loading}
        onChangePop={handleActionbtn}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        totalPages={providerData.count}
        wordWrap={false}
      />
     
      <Modal
        isOpen={props.addNewButton===props.name?true:false}
        closeModal={closeModal}
        title={type? "Update Payer":"Add New Payer"}
      >
        <AddPayer data={type ? {datUpdate,type} : ""} modalCloseCall={closeAfterApiRes}/>{" "}
      </Modal>
    </>
  );
};

export default ViewPayer;
