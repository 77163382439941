import React from "react";

import userAvatar from "../../../Assets/images/userAvatar.svg";

import logo from "../../../Assets/images/bellmedex.png";
import "./toggle.css";
// import Notifcation from "./Notifcation";

export const Header = ({
  toggleSidenavBar,
  toggleDarkMode,
  darkMode,

}) => {
  

  return (
		<>
			<nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
				<div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
					<a className="navbar-brand brand-logo mr-5">
						<img src={logo} className="bellmedexLogo" alt="logo" />
					</a>
				</div>
				<div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
					<button
						className="navbar-toggler navbar-toggler align-self-center"
						type="button"
						data-toggle="minimize"
					>
						<span className="icon-menu" onClick={() => toggleSidenavBar()}>
							<i className="fas fa-bars"></i>
						</span>
					</button>
					<ul className="navbar-nav mr-lg-2">
						<li className="nav-item nav-search d-none d-lg-block"></li>
					</ul>

					<ul className="navbar-nav navbar-nav-right">
						<li
							className="nav-item  mt-2 darkModeToogle"
							data-toggle="tooltip"
							data-placement="top"
							title={darkMode ? "Switch to Day Mode" : "Switch to Dark Mode"}
						>
							{/* <a className="nav-link">
              <div class="toggle__fill"></div>

                <i
                  style={{ marginBottom: "12px", marginRight: "-22px" }}
                  class={
                    darkMode
                      ? "fas fa-sun  text-warning"
                      : " fas  fa-moon text-warning"
                  }
                ></i>
              </a> */}
							{/* <label className=" d-flex togglei mt-0 ml-2 ">
								<input
									className="toggle__inputi  ml-5 "
									name="check"
									type="checkbox"
									// checked={checked}
									onClick={() => toggleDarkMode()}
									id="myggle"
								/>
								<div className="toggle__filli">
									{darkMode ? (
										<>
											<span>🌞</span>
										</>
									) : (
										<>
											<span className="ml-1  pl-4">🌜</span>
										</>
									)}
								</div>
							</label> */}
						</li>
						<li
							className={
								// notification ? "nav-item dropdown show" :
								"nav-item dropdown "
							}
							// onClick={() => setnotifications(!notification)}
						>
							<a
								className="nav-link count-indicator dropdown-toggle"
								id="notificationDropdown"
								data-toggle="dropdown"
							>
								<i className="far fa-bell"></i>
								<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-danger">
									{/* {NotifyForCardExpires.length == 0
                    ? ""
                    : NotifyForCardExpires.length} */}
								</span>
								<span className=""></span>
							</a>

							{/* <Notifcation
                notification={notification}
                NotifyForCardExpires={NotifyForCardExpires}
              /> */}
						</li>
						<li
							// onClick={() => setprofilepic(!profilepic)}
							className={
								true
									? "nav-item nav-profile dropdown"
									: "nav-item nav-profile dropdown show"
							}
						>
							<a
								className="nav-link dropdown-toggle"
								data-toggle="dropdown"
								id="profileDropdown"
							>
								{true ? (
									<>
									
									</>
								) : (
									<>
										<img src={userAvatar} alt="profile" />
									</>
								)}
							
								<b
									style={{
										fontSize: "14px",
										marginLeft: "0.5rem",
										fontWeight: "600",
									}}
								>
									
								</b>
							</a>
							<div
								
								aria-labelledby="profileDropdown"
							>
								<a
									className="dropdown-item"
							
								>
									<i className="fas fa-ellipsis-v text-primary"></i>
									Settings
								</a>
								{/* <a className="dropdown-item" onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt  text-primary"></i>
                  Logout
                </a> */}
							</div>
						</li>
						<li className="nav-item nav-settings d-none d-lg-flex">
 
						</li>
					</ul>
					<button
						className="navbar-toggler navbar-toggler-right rounded d-lg-none align-self-center p-2 text-white"
						type="button"
						data-toggle="offcanvas"
						onClick={() => toggleSidenavBar()}
					>
						<span className="icon-menu fas fa-bars ml-1">
							<i className="fas fa-sliders-v"></i>
						</span>
					</button>
				</div>
			</nav>
		</>
	);
};
