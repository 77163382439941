import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import DataTable from "../../../Common/RsuitDataGrid/userTaskViewTable";
import { getDataForviewDate, getSummaryData } from "../../../Utils/ApiManager";
import Modal from "../../../Common/CommonModal";
import ViewProvideInfo from "./providerInfo";


let statuses = [
  "",
  'New',
  'approved',
  'participating',
  'Non-Participating',
  'in-process',
  'pending-bm',
  'pending-provider',
  'rejected',
  'not-eligible',
  'panel-closed',
]
let optionss = [

  { label: "New", value: "New" },
  { label: "approved", value: "approved" },
  { label: "participating", value: "participating" },
  { label: "Non-Participating", value: "Non-Participating" },
  { label: "in-process", value: "in-process" },
  { label: "pending-bm", value: "pending-bm" },
  { label: "pending-provider", value: "pending-provider" },
  { label: "rejected", value: "rejected" },
  { label: "not-eligible", value: "not-eligible" },
  { label: "panel-closed", value: "panel-closed" },
];


function AppStatusReport() {
  const [providerData, setProvidersData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    limit: 10,
    page: 1,
    count: 0,
  });
  const [ProviderDataforForm, setProviderData] = useState('');
  const [Loadin, setLoadin] = useState(false);

  const [isModalOpenRemark, setIsModalOpenRemarks] = useState(false);

  const [practice_id, setPraacticeId] = useState();
  const [status, setStatus] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();


  const [IdForloader, setIdForloader] = useState({ id: null, name: null })


  const [selectedOption, setSelectedOption] = useState(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",

      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)",
        cursor: "pointer",
        color: "white",
      },
    }),
  };
  useEffect(() => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      date_from: startDate ? startDate : "",
      date_to: endDate ? endDate : "",
      status: selectedOption ? selectedOption.label : "",
    };
    if (payload.date_from !== "" || payload.date_to !== "" || payload.status !== "") {
      fetchData(payload);
    } else {
      const payload = {
        page: Number(paginationData.page),
        limit: Number(paginationData.limit),
      };
      fetchData(payload);
    }
  }, [paginationData.page, paginationData.limit]);




  const handleChangeselect = (selectedValue, selectedOption) => {
    console.log(selectedValue, selectedOption);
    setSelectedOption(selectedOption);
    setPraacticeId(selectedValue);
  };


  const handleFilter = () => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      date_from: startDate ? startDate : "",
      date_to: endDate ? endDate : "",
      status: selectedOption ? selectedOption.label : ''
    };
    if (
      payload.date_from !== "" ||
      payload.date_to !== "" || payload.staus != ''
    ) {
      fetchData(payload);
    }
  };
  const fetchData = async (payload) => {
    setLoadin(true);
    try {
      const res = await getSummaryData(payload);




      let chatRows = [];
      res.data.summaryData.forEach((d, index) => {
        let matchingRow = chatRows.find(row => row.id === d.id);
        if (matchingRow !== undefined) {
          // If a matching row is found, update the corresponding status value with the total value
          matchingRow[d[`payer_providers.status`]] = d[`payer_providers.total`];
        } else {
          // If no matching row is found, create a new row with only necessary properties
          let newRow = {
            id: d.id,
            name: d.name,
            last_name: d.last_name,
            middle_initial: d.middle_initial,
          };

          // Initialize status properties with 0
          statuses.forEach(status => newRow[status] = 0);

          // Update the corresponding status value with the total value
          newRow[d[`payer_providers.status`]] = d[`payer_providers.total`];

          chatRows.push(newRow);
        }
      });

      setProvidersData(chatRows)


      // setProvidersData(data?.data);


      setPaginationData((prevPaginationData) => ({
        ...prevPaginationData,
        totalPages: res.data.totalPayerProviders,
      }));

      setLoadin(false);
    } catch (error) {
      console.error(error);
      setLoadin(false);
    }
  };





  const handleChangeLimit = (newLimit) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      limit: newLimit,
      page: 1,
    }));
  };

  const handleChangePage = (newPage) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      page: newPage,
    }));
  };



  const fetchProviderData =  (id, name) => {
    setIdForloader({ id, name })
    let obj={id, name}
    console.log(obj);

     getDataForviewDate(obj).then((res) => {

      setProviderData(res.data);
      setIdForloader({ id: null, name: null })
      openModalRemarks()
    }).catch((error) => {
      setIdForloader({ id: null, name: null })
      toast("Error While Fetching")
    })
  };

  const openModalRemarks = () => {
    setIsModalOpenRemarks(true);
  };
  const closeModalRemarks = () => {
    setIsModalOpenRemarks(false);
  };


  const arr = [

    {
      colName: "Name",
      flexGrow: 1.6,
      dataKey: "id",

      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id == data);


        const fullName = provider
          ? `${provider.name ?? ''} ${provider.last_name ?? ''} ${provider.middle_initial ?? ''}`
          : 'Not Found';
        return <div> {provider
          ? fullName
          : 'Not Found'}
        </div>;
      }

    },


    {
      colName: "App Total", flexGrow: 0.8, dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item, i) => item?.id === data);

        const total = statuses.reduce((acc, value) => acc + provider[value], 0)

        return (
          <div>
            {total}

          </div>
        );
      },
    },
    {
      colName: "New",
      flexGrow: 0.6,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item, i) => item?.id === data);

        const id = provider?.id ? provider?.id : '';
        return (
          <div>
            {provider?.['New'] || 0}
            {provider?.['New'] ? (IdForloader.id === id && IdForloader.name == 'New' ? <Loader size="xs" /> :
              <i className="fa fa-info-circle iconCol01 pl-2" onClick={() => { fetchProviderData(id, 'New') }} />) : ''}
          </div>
        );
      },
    },
    {
      colName: "Approved",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : '';
        return (
          <div>
            {provider?.['approved'] || 0}
            {provider?.['approved'] ? (IdForloader.id === id && IdForloader.name == 'approved' ? <Loader size="xs" /> :
              <i className="fa fa-info-circle iconCol01 pl-2" onClick={() => { fetchProviderData(id, 'approved') }} />) : ''}
          </div>
        );
      },
    },
    {
      colName: "Participating",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : '';
        return (
          <div>
            {provider?.['participating'] || 0}
            {provider?.['participating'] ? (IdForloader.id === id && IdForloader.name == 'participating' ?
              <Loader size="xs" /> : <i className="fa fa-info-circle iconCol01 pl-2" onClick={() => { fetchProviderData(id, 'participating') }} />) : ''}
          </div>
        );
      },
    },
    {
      colName: "Non-Participating",
      flexGrow: 0.9,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : '';
        return (
          <div>
            {provider?.['Non-Participating'] || 0}
            {provider?.['Non-Participating'] ? (IdForloader.id === id && IdForloader.name == 'Non-Participating' ?
              <Loader size="xs" /> : <i className="fa fa-info-circle iconCol01 pl-2" onClick={() => { fetchProviderData(id, 'Non-Participating') }} />) : ''}
          </div>
        );
      },
    },
    {
      colName: "In Process",
      flexGrow: 0.7,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : '';
        return (
          <div>
            {provider?.['in-process'] || 0}
            {provider?.['in-process'] ? (IdForloader.id === id && IdForloader.name == 'in-process' ?
              <Loader size="xs" /> : <i className="fa fa-info-circle iconCol01 pl-2" onClick={() => { fetchProviderData(id, 'in-process') }} />) : ''}
          </div>
        );
      },
    },
    {
      colName: "Pending-BM",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : '';
        return (
          <div>
            {provider?.['pending-bm'] || 0}
            {provider?.['pending-bm'] ? (IdForloader.id === id && IdForloader.name == 'pending-bm' ?
              <Loader size="xs" /> : <i className="fa fa-info-circle iconCol01 pl-2" onClick={() => { fetchProviderData(id, 'pending-bm') }} />) : ''}
          </div>
        );
      },
    },
    {
      colName: "Pending-Provider",
      flexGrow: 0.9,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : '';
        return (
          <div>
            {provider?.['pending-provider'] || 0}
            {provider?.['pending-provider'] ? (IdForloader.id === id && IdForloader.name == 'pending-provider' ?
              <Loader size="xs" /> : <i className="fa fa-info-circle iconCol01 pl-2" onClick={() => { fetchProviderData(id, 'pending-provider') }} />) : ''}
          </div>
        );
      },
    },
    {
      colName: "Rejected",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : '';
        return (
          <div>
            {provider?.['rejected'] || 0}
            {provider?.['rejected'] ? (IdForloader.id === id && IdForloader.name == 'rejected' ?
              <Loader size="xs" /> : <i className="fa fa-info-circle iconCol01 pl-2" onClick={() => { fetchProviderData(id, 'approved') }} />) : ''}
          </div>
        );
      },
    },
    {
      colName: "Not Eligible",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : '';
        return (
          <div>
            {provider?.['not-eligible'] ? provider?.['not-eligible'] : 0}
            {provider?.['not-eligible'] ? (IdForloader.id === id && IdForloader.name == 'not-eligible' ?
              <Loader size="xs" /> : <i className="fa fa-info-circle iconCol01 pl-2" onClick={() => { fetchProviderData(id, 'not-eligible') }} />) : ''}
          </div>
        );
      },
    },
    {
      colName: "Panel Closed",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : '';
        return (
          <div>
            {provider?.['panel-closed'] || 0}
            {provider?.['panel-closed'] ? (IdForloader.id === id && IdForloader.name == 'panel-closed' ?
              <Loader size="xs" /> : <i className="fa fa-info-circle iconCol01 pl-2" onClick={() => { fetchProviderData(id, 'panel-closed') }} />) : ''}
          </div>
        );
      },
    },



  ];

  const handleActionbtn = (row) => {
    debugger;
  };
  const handleChangeForDate = (event) => {
    const { name, value } = event.target;
    if (name == "start") {
      setStartDate(value);
    }
    if (name == "end") {
      setEndDate(value);
    }
  };
  const handleClear = () => {
    setStartDate("");
    setEndDate("");

    setSelectedOption(null);

    const emptyPayload = {
      page: 1,
      limit: Number(paginationData.limit),
      practice_id: "",
      speciality: "",
      status: "",
      home_state: "",
      date_from: "",
      date_to: "",
    };

    let check =
      practice_id == "" && status == "" && startDate == "" && endDate == "";
    if (check) {
    } else {
      fetchData(emptyPayload);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Status</label>

            <Select
              styles={customStyles}
              options={optionss}
              value={selectedOption ? selectedOption : ""}
              placeholder="Select an option"
              // Use the original Select component
              onChange={(selectedOption) =>
                handleChangeselect(Number(selectedOption.value), selectedOption)
              }
            />
          </div>
        </div>

        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Start Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="start"
              placeholder=""
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">End Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="end"
              placeholder=""
              onChange={handleChangeForDate}
            />
          </div>
        </div>

        <div className="col-sm-12 col-md-3">
          <div className="form-group mt-2">
            <div className="d-flex align-items-center mt-4">
              <button className="btnClear mr-1" onClick={handleFilter}>
                Filter
              </button>
              <button type="button" onClick={handleClear} className="btnSave">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        Array={arr}
        dateForgrid={providerData}
        loader={Loadin}
        onChangePop={handleActionbtn}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        totalPages={paginationData.totalPages}
      // footer={"<MyFooter />"} 

      />




      <Modal isOpen={isModalOpenRemark} closeModal={closeModalRemarks} title={"Provider Information"}>
        <ViewProvideInfo data={ProviderDataforForm || ""} />{" "}
      </Modal>
    </>
  );
}



export default AppStatusReport
