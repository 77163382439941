import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "./Header/Header";
import { Sidenav } from "./Sidenav/Sidenav";
import { useSelector } from "react-redux";


function Layout() {
  const { isProviderCameFromEhrProv } = useSelector((state) => state);
  const [toogleSideNav, settoogleSideNav] = useState(
    isProviderCameFromEhrProv ? false : true
  );

  const [darkMode, setdarkMode] = useState(false);
  useEffect(() => {
    if (isProviderCameFromEhrProv) {
      settoogleSideNav(false);
    }
  }, [isProviderCameFromEhrProv]);

  const toggleDarkMode = () => {
    setdarkMode(!darkMode);
  };
  const ProviderInCall = () => {
   
  };

  const toggleSidenavBar = () => {
    settoogleSideNav(!toogleSideNav);
  };
  // cont classNameCond;
  // if (toogleSideNav && !darkMode) {
  //  classNameCond = "main";
  // } else if (!toogleSideNav && !darkMode);
  // {
  //    classNameCond = "main sidebar-icon-only";
  // } else if (toogleSideNav && darkMode);
  // {
  //   return classNameCond = "main dark";
  // }
  // else if(!toogleSideNav && darkMode);
  // {
  //   classNameCond = "sidebar-icon-only dark";
  // }
  return (
    <>
      <div
        className={
          toogleSideNav
            ? !darkMode
              ? "main"
              : "main dark"
            : darkMode
            ? "sidebar-icon-only dark"
            : "sidebar-icon-only  "
        }
      >
        <Header
          ProviderInCall={ProviderInCall}
          toogleSideNav={toogleSideNav}
          toggleSidenavBar={toggleSidenavBar}
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
        />
        <div className="container-fluid page-body-wrapper">
          <Sidenav    
         
            toogleSideNav={toogleSideNav}
            toggleSidenavBar={toggleSidenavBar}
          />
          <div className="main-panel">
            {/* <AlertBar text="Card Expire"/> */}
            <div className="content-wrapper">
              <Outlet />
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
