import { Schema } from 'rsuite';
const { StringType, NumberType, DateType } = Schema.Types
export const validationFields = [
    {
      fieldName: 'individual_npi',
      rules: { requiredMessage: 'Received amount is required.' },
      type: { typeError: StringType() }

    }
    ,{
        fieldName: 'individual_npi',
        rules: { requiredMessage: 'Received amount is required.' },
        type: { typeError: NumberType()}
  
      },
      {
        fieldName: 'last_name',
        rules: { requiredMessage: 'Received amount is required.' },
        type: { typeError: StringType() }
  
      },
      {
        fieldName: 'name',
        rules: { requiredMessage: 'Received amount is required.' },
        type: { typeError: StringType() }
  
      },
      {
        fieldName: 'password',
        rules: { requiredMessage: '' },
        type: { typeError: StringType() }
  
      },
      {
        fieldName: 'email',
        rules: { requiredMessage: 'Received amount is required.' },
        type: { typeError:  StringType()
          .isEmail('Please enter a valid email address.')
          .isRequired('This field is required.') }
  
      },
      {
        fieldName: 'cell',
        rules: { requiredMessage: 'Received amount is required.' },
        type: { typeError: StringType() }
  
      },
      {
        fieldName: 'practiceid',
        rules: { requiredMessage: 'required.' },
        type: { typeError: NumberType() }
  
      }
     

]
export const vlidation = async(obj)=>{  

     
    const validationSchema = await createValidationSchema(validationFields);
     let errors=await validationSchema.check(obj)  
    return errors
  }
  export const createValidationSchema = (fields) => {
    const schema = Schema.Model(
      fields.reduce((result, { fieldName, rules,type }) => {
       
        return {
          ...result,
          [fieldName]: type?.typeError?.isRequired((value, data) => {
            if (!value) {
              return rules?.requiredMessage;
            }
            return true;
          }),
        };
      }, {})
    );
  
    return schema;
  }
  
  

  export function formatTimeTo12HourClock(timeString) {
    const [hours, minutes, seconds] = timeString?.split(':');
    const time = new Date(0, 0, 0, hours, minutes, seconds);  
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
    return new Intl.DateTimeFormat('en-US', options).format(time);
  }
  
