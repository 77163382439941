import React, { useEffect, useState } from "react";
import AddNewEDI from "../AddNewEdiRecord/AddNewEdiRecord";
import Modal from "../../../Common/CommonModal";
import Select from "react-select";
import DataTable from "../../../Common/RsuitDataGrid/userTaskViewTable";
import { deleteNastsdata, getNasts } from "../../../Utils/ApiManager";
import { useSelector } from "react-redux";
import NastInformation from "./NastInformation";
import UpdateEdiRecord from "../AddNewEdiRecord/UpdateEdiRecord";
import { toast } from "react-toastify";
import swal from "sweetalert";

function ViewEdiRecordMainForm(props) {
  const state = useSelector((state) => state);
  const [NastData, setNastData] = useState([]);
  const [practice_id, setPraacticeId] = useState("");
  const [startDate, setStartDate] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [endDate, setEndDate] = useState();
  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    limit: 10,
    page: 1,
    count: 0,
  });
  const [entity, setEntity] = useState();
  const [loading, setLoading] = useState(false);

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [dataForupdate, setDataForUpdate] = useState("");
  const [options, setOptions] = useState([
    {
      value: "",
      label: "please Select",
    },
  ]);
  const [btnloader, setBtnLoader] = useState(false);
  const [btnId, setBtnId] = useState("");

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)",
        cursor: "pointer",
        color: "white",
      },
    }),
  };

  // useEffect(() => {
  //   setOptions(state?.practiceName);
  // }, []);

  useEffect(() => {
    setOptions(state?.practiceName);

    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      practice_id: practice_id,
      date_from: startDate,
      date_to: endDate,
    };
    fetchData(payload);
  }, [paginationData.page, paginationData.limit, state?.practiceName]);

  const fetchData = async (payload) => {
    setLoading(true);
    try {
      const data = await getNasts(payload);
      setNastData(data.data);
      setPaginationData((prevPaginationData) => ({
        ...prevPaginationData,
        count: data.data.count,
      }));
      console.log(data.data, "datata");
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 8 && selectedOption !== null) {
      setSelectedOption(null);
    }
  };
  const handleFilter = () => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      practice_id: practice_id,
      date_from: startDate,
      date_to: endDate,
    };
    console.log(payload);
    if (
      payload.practice_id !== "" ||
      payload.date_from !== "" ||
      payload.date_to !== ""
    ) {
      fetchData(payload);
    }
  };
  const handleChangeLimit = (newLimit) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      limit: newLimit,
      page: 1,
    }));
  };
  const handleChangeForDate = (event) => {
    const { name, value } = event.target;
    if (name == "start") {
      setStartDate(value);
    }
    if (name == "end") {
      setEndDate(value);
    }
  };
  const handleChangePage = (newPage) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      page: newPage,
    }));
  };

  const handleChangeselect = (selectedValue, selectedOption) => {
    // Handle the selected value as a number
    setSelectedOption(selectedOption);
    setPraacticeId(selectedValue);
  };

  const openModal1 = () => {
    setIsModalOpen1(true);
  };
  const openModal2 = () => {
    setIsModalOpen2(true);
  };
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  const closeModal2 = () => {
    setIsModalOpen2(false);
    // fetchData();
  };
  const closeModal = () => {
    props.addNew("");
  };

const callApionDataUpdated=()=>{
  setIsModalOpen2(false);
  props.addNew("");

  fetchData();
}
  const onClear = () => {
    setSelectedOption(null);
    setPraacticeId("");
    setStartDate("");
    setEndDate("");
    const emptyPayload = {
      page: 1,
      limit: Number(paginationData.limit),
      practice_id: "",
      date_from: "",
      date_to: "",
    };

    let check = practice_id == "" && startDate == "" && endDate == "";
    if (check) {
    } else {
      fetchData(emptyPayload);
    }
  };

  const handelViewDocument = (e, rowData) => {
    const data = rowData;
    openModal1();
    setEntity(data);
  };
  const handleUpdate = (e, rowData) => {
    const data = rowData;
    setDataForUpdate(rowData);
    openModal2();
  };
  const handleDalete = async (e, rowData) => {
    setBtnLoader(true);
    setBtnId(rowData?.id);
  
    try {
      const willDelete = await swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this record?",
        icon: "error",
        dangerMode: true,
        buttons: ["Cancel", "Confirm"],
      });
  
      if (willDelete) {
        const response = await deleteNastsdata(rowData?.id);
        if (response.status === 200) {
          toast.success("Record deleted");
          fetchData();
        } else {
          toast.error("Failed to delete the record");
        }
      } else {
        // User cancelled the delete, handle accordingly
      }
    } catch (error) {
      console.error("Error occurred while handling delete confirmation:", error);
      toast.error("An error occurred while deleting the record");
    } finally {
      setBtnLoader(false);
    }
  };
  
  const arr = [
    {
      colName: "Practice Name",
      flexGrow: 1.6,
      dataKey: "practice.practice_name",
    },
    { colName: "Payer", flexGrow: 1.2, dataKey: "Payer.name" },
    { colName: "Action Type", flexGrow: 0.9, dataKey: "action" },
    { colName: "Status", flexGrow: 1.1, dataKey: "status" },
    {
      colName: "Created Date",
      flexGrow: 0.7,
      dataKey: "created_at",
      CustomCell: ({ data }) => {
        return <div>{data?.split("T")[0]}</div>;
      },
    },
    {
      colName: "Action",
      flexGrow: 0.6,
      dataKey: "id",
      actions: [
        {
          label: "fa fa-info-circle iconCol01",
          onClick: (rowData) => {
            handelViewDocument(
              rowData,
              NastData?.data.find((row) => row.id == rowData)
            );
          },
        },
        {
          label: "fas fa-pencil-alt iconCol02",
          onClick: (rowData) => {
            handleUpdate(
              rowData,
              NastData?.data.find((row) => row.id == rowData)
            );
          },
        },
        {
          label: "fa fa-trash iconCol03",
          loader: btnloader,
          id: btnId,

          onClick: (rowData) => {
            handleDalete(
              rowData,
              NastData?.data.find((row) => row.id == rowData)
            );
          },
        },

        // Add more action objects as needed
      ],
    },
  ];
  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <label className="inputLabel">Start Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="start"
              value={startDate}
              placeholder=""
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <label className="inputLabel">End Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="end"
              value={endDate}
              placeholder=""
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <label className="inputLabel">Practices</label>
            <Select
              styles={customStyles}
              options={options}
              onKeyDown={handleKeyDown}
              value={selectedOption}
              placeholder="Select an option"
              onChange={(selectedOption) =>
                handleChangeselect(Number(selectedOption.value), selectedOption)
              }
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <div className="d-flex mt-4">
              <button className="btnClear mr-1" onClick={handleFilter}>
                Filter
              </button>
              <button type="button" className="btnSave" onClick={onClear}>
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        Array={arr}
        dateForgrid={NastData.data}
        loader={loading}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        totalPages={NastData.count}
      />

      <Modal
        isOpen={props.addNewButton === props.name ? true : false}
        closeModal={closeModal}
        title={"Add Edi Record"}
      >
        <AddNewEDI modalCloseCall={callApionDataUpdated} />{" "}
        {/* Render your component inside the modal */}
      </Modal>

      <Modal
        isOpen={isModalOpen1}
        closeModal={closeModal1}
        title={"Nast Information"}
      >
        <NastInformation data={entity ? entity : ""} />{" "}
        {/* Render your component inside the modal */}
      </Modal>

      <Modal
        isOpen={isModalOpen2}
        closeModal={closeModal2}
        title={"Update Edi Record"}
      >
        <UpdateEdiRecord
          data={dataForupdate ? dataForupdate : ""}
          modalCloseCall={callApionDataUpdated}
        />{" "}
        {/* Render your component inside the modal */}
      </Modal>
    </>
  );
}
export default ViewEdiRecordMainForm;
