import React, { useState } from "react";
import Select from "react-select";
import Custominput from "./Custominput";
import "./Quick.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  UpdateQuickFormUpdate,
  createNewPractices,
  createProvider,
  getPracticeDataForUpdate,
  getQuickprovData,
} from "../../Utils/ApiManager";
import { toast } from "react-toastify";
import { Form } from "react-router-dom";
import Loader from "../../Common/Loader/Loader";
import QuickUpdatesProviders from "./QuickUpdatesProviders";
import swal from "sweetalert";

const QuickUpdateForm1 = ({ handleEditClick, PractData, ProviData }) => {
  console.log(PractData, "pppppppp");

  const { state, Specialities, practiceName, onLoginCredentialing } =
    useSelector((state) => state);
  const { payerName } = useSelector((state) => state);

  let practicesDataobj = {
    clia: "",
    clia_expiry: "",
    client_name: "",
    contact1_email: "",
    contact1_mobile: "",
    contact1_name: "",
    contact1_phone: "",
    contact2_email: "",
    contact2_mobile: "",
    contact2_name: "",
    contact2_phone: "",
    created_at: "",
    deleted: null,
    group_npi: "",
    id: "",
    onwer_email: "",
    owner_name: "",
    pay_address: "",
    pay_city: "",
    pay_country: "",
    pay_state: "",
    pay_zip: "",
    practice_code: "",
    practice_name: "",
    practice_tax_id: "",
    service_address: "",
    service_city: "",
    service_country: "",
    service_fax: "",
    service_phone: "",
    service_state: "",
    service_zip: "",
    speciality: "",
    taxnomy: "",
  };
  const dataOfaprovider = {
    id: "",
    practice_id: "",
    practice_name: "",
    group_npi: "",
    individual_npi: "",
    last_name: "",
    middle_initial: "",
    name: "",
    tax_id: "",
    ssn: "",
    speciality: "",
    provider_signup_date: "",
    taxonomy: "",
    taxonomy2: "",
    home_street: "",
    home_suite: "",
    home_city: "",
    home_state: "",
    home_county: "",
    home_zip: "",
    mailing_street: "",
    mailing_suite: "",
    mailing_city: "",
    mailing_state: "",
    mailing_county: "",
    mailing_zip: "",
    cell: "",
    gender: "",
    birth_county: "",
    birth_state: "",
    email: "",
    dob: "",
    password: "",
    software_name: "",
    owner_last_name: "",
    owner_middle_initial: "",
    owner_first_name: "",
    owner_email: "",
    owner_cell_number: "",
    owner_ssn: "",
    owner_driving_license: "",
    owner_driving_license_date: "",
    owner_driving_license_expiry: "",
    owner_dob: "",
    owner_home_address: "",
    caqh_user: "",
    caqh_pass: "",
    caqh_id: "",
    pecos_user: "",
    pecos_pass: "",
    manager_name: "",
    manager_email: "",
    medicaid_id: "",
    grp_madicaid_id: "",
    grp_mrc_id: "",
    medicaid_user: "",
    medicare_id: "",
    website: "",
    dea: "",
    dea_certification_number: "",
    dea_effective_date: "",
    dea_expiry_date: "",
    state_license: "",
    board_certification: "",
    board_certification_number: "",
    board_effective_date: "",
    board_expiry_date: "",
    hospital_affiliation: "",
    hospital_name: "",
    hospital_effective_date: "",
    hospital_expiry_date: "",
    state_license_option: "",
    state_license_number: "",
    state_license_date: "",
    state_license_expiry: "",
    mon_hours: "",
    tue_hours: "",
    wed_hours: "",
    thu_hours: "",
    fri_hours: "",
    sat_hours: "",
    sun_hours: "",
    deleted: "",
    user_id: "",
    status: "",

    payer_providers: [
      {
        id: "",
        payer_id: "",
        provider_id: "",
        provider_identifier: "",
        status: "",
        effective_date: "",
        business_line: "",
        business_effective_date: "",
        Payer: {
          id: "",
          name: "",
          deleted: 0,
        },
      },
      // ... other payer_providers
    ],
    appstatuschanges: [
      {
        id: "",
        payer_id: "",
        provider_id: "",
        before_status: "",
        after_status: "",
        status_change_date: "",
      },
      // ... other appstatuschanges
    ],
    remarks: [
      {
        id: "",
        payer_id: "",
        provider_id: "",
        user_id: "",
        remarks: "",
        deleted: false,
      },
      // ... other remarks
    ],
    locations: [
      {
        fax: "",
        id: "",
        phone: "",
        provider_id: "",
        service_location: "",
      },
    ],
  };
  let applicationStatus = [
    { label: "new" },
    { label: "participating" },
    { label: "non-participating" },
    { label: "in-process" },
    { label: "pending-bm" },
    { label: "pending-provider" },
    { label: "rejected" },
    { label: "not-eligible" },
    { label: "panel-closed" },
    { label: "approved" },
  ];
  const [loadingn, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [practice_id, setPraacticeId] = useState();
  const [providerOptions, setProviderOptions] = useState([]);
  const [providerOptionsId, setProviderOptionsId] = useState();
  const [proviValue, setProviderValue] = useState("");
  const [ProviderDataforForm, setProviderData] = useState(ProviData || "");
  const [PracticesDataforForm, setPracticesData] = useState(PractData || "");
  const [practicesValues, setPracticesValues] = useState(practicesDataobj);
  const [serviceFields, setServiceFields] = useState(
    ProviderDataforForm?.locations
  );
  const [deleteServiceFields, setDeleteServiceFields] = useState([]);
  const [remarksArray, setRemarksArray] = useState(
    ProviderDataforForm?.remarks
  );

  const [payerDelete, setForPayerDelete] = useState("");

  const [payerFields, setPayerFields] = useState(
    ProviderDataforForm?.payer_providers
  );

  const [statusChnageDate, setStatusChnageDate] = useState([
    {
      before_status: "",
      payer_id: "",
      provider_id: "",
      after_status: "",
      id: "",
    },
  ]);
  const payerProviderStatuses = () => {
    if (ProviderDataforForm?.payer_providers) {
      return ProviderDataforForm?.payer_providers.map((provider, index) => ({
        payer_id: provider.payer_id,
        provider_id: provider.provider_id,
        before_status: provider.status,
        after_status: provider.status,
        id: ProviderDataforForm?.appstatuschanges[index]?.id,
      }));
    }

    return [];
  };

  useEffect(() => {
    // Update the statusChnageDate state with the before_status array
    setStatusChnageDate(payerProviderStatuses);
  }, [ProviderDataforForm?.payer_providers]);
  console.log(statusChnageDate, "status_before");

  const [deleteRemarksFields, setDeleteRemarksFields] = useState([]);
  const removeServiceField = (index) => {
    const updatedFields = [...serviceFields];
    if (updatedFields[index].id !== 0) {
      let object = updatedFields[index];
      // Add the object to deleteServiceFields
      setDeleteServiceFields((prev) => [...prev, object]);
    }
    updatedFields.splice(index, 1);
    setServiceFields(updatedFields);
  };
  const addServiceField = () => {
    setServiceFields([
      ...serviceFields,
      { service_location: "", phone: "", fax: "", id: 0 },
    ]);
  };
  const handleChange = (event, index, field) => {
    const updatedFields = [...serviceFields];
    updatedFields[index][field] = event.target.value;

    setServiceFields(updatedFields);
  };
  console.log(serviceFields, "serviceFields");

  const addPayerField = () => {
    setPayerFields([
      ...payerFields,
      {
        payer_id: "",
        effective_date: "",
        status: "",
        provider_identifier: "",
        remarks: "",
        remarksid: 0,
        user_id: onLoginCredentialing?.user.id,
        provider_id: ProviderDataforForm?.id,
      },
    ]);
  };

  const removePayerField = (index) => {
    const updatedFields = [...payerFields];
    if (updatedFields[index].remarksid != 0) {
      let object = updatedFields[index];
      setForPayerDelete((prev) => [...prev, object]);
    }
    updatedFields.splice(index, 1);
    setPayerFields(updatedFields);
  };
  const handlePayerFieldChange = (index, field, value, payerFieldss) => {
    console.log(value, "fieldsvalue");

    const updatedFields = [...payerFields];
    updatedFields[index][field] = value;
    setPayerFields(updatedFields);
    if (field == "effective_date") {
      const dateObject = new Date(value);
      dateObject.setDate(dateObject.getDate() + 13);
      const formattedDate = dateObject.toISOString()?.split("T")[0];
      console.log(formattedDate, "effective_date");
    }
    if (field == "payer_id") {
      const updatedFields = [...payerFields];
      updatedFields[index][field] = value.value;
      setPayerFields(updatedFields);
    }
    if (field == "status") {
      if (payerFieldss?.payer_id && payerFieldss.provider_id) {
        const updatedStatusChnageDate = statusChnageDate.map((el) => {
          if (
            el.provider_id === payerFieldss.provider_id &&
            el.payer_id === payerFieldss.payer_id
          ) {
            return {
              ...el,
              after_status: payerFieldss.status, // Replace with the new status value you want to set
            };
          }
          return el;
        });

        setStatusChnageDate(updatedStatusChnageDate);
      }
    }
  };
  console.log(payerFields, "payerFieldsddddd");
  const handlechnagetogetRemarks = (event, index, field) => {
    const updatedRemarksArray = [...remarksArray];
    updatedRemarksArray[index][field] = event.target.value;
    setRemarksArray(updatedRemarksArray);
  };

  const getProvider = Specialities?.filter((item) => {
    return practice_id === item.practice_id;
  }).map((item) => ({
    label: item.name + " " + item.last_name,
    value: item.id,
  }));
  console.log(PracticesDataforForm, "PracticesDataforForm");
  const generateResponse = (data, type) => {
    let fArray = [[], []];
    return data[type].map((item) => {
      fArray[type == "remarks" ? 0 : 1].push(item.id);
      return {
        ...item,
        remarks:
          type === "remarks"
            ? item.remarks
            : data.remarks.find(
                (remark) =>
                  remark.payer_id === item.payer_id &&
                  !fArray[0].includes(remark.id)
              )?.remarks || "",
        remarksid:
          type === "remarks"
            ? item.id
            : data.remarks.find(
                (remark) =>
                  remark.payer_id === item.payer_id &&
                  !fArray[0].includes(remark.id)
              )?.id || "",

        ...(type === "remarks"
          ? ProviderDataforForm?.payer_providers.find(
              (prov) =>
                prov.payer_id === item.payer_id && !fArray[1].includes(prov.id)
            )
          : {}),
      };
    });
  };
  useEffect(() => {
    let finalResponse =
      ProviderDataforForm?.payer_providers.length >
      ProviderDataforForm?.remarks.length
        ? generateResponse(ProviderDataforForm, "payer_providers")
        : generateResponse(ProviderDataforForm, "remarks");
    setPayerFields(finalResponse);
  }, []);
  const handleChnageForPractices = (event) => {
    const { name, value } = event.target;
    // Update the state with the new value
    setPracticesData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChangeForproviders = (event) => {
    const { name, value } = event.target;
    // Update the state with the new value
    setProviderData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(name, "name");
    if (name == "status") {
      setStatusChnageDate((prev) => ({
        ...prev,
        after_status: value,
      }));
    }
  };
  const handleChangeProvider = (selectedValue, selectedOption) => {
    setProviderOptionsId(selectedValue);
    setProviderValue(selectedOption);
    fetchProviderData(selectedValue ? selectedValue : "");
  };
  // console.log(getProvider, "getProviders");
  useEffect(() => {
    setOptions(practiceName);
    setProviderValue("");
    setProviderOptions(getProvider);
  }, [practice_id]);
  // console.log(options, "options");
 

  function removeArraysFromProviderData(obj) {
    if (obj.formVariablesCopy) {
      delete obj.formVariablesCopy.appstatuschanges;
      delete obj.formVariablesCopy.remarks;
      delete obj.formVariablesCopy.locations;
      delete obj.formVariablesCopy.payer_providers;
    }

    return obj;
  }
  const payerResult = payerFields?.map(({ Payer, ...rest }) => rest);
  const remarksData = payerFields?.map((item) => ({
    id: item.remarksid,
    remarks: item.remarks,
    user_id: item.user_id,
    payer_id: item?.payer_id,
    provider_id: item.provider_id,
  }));
  // Usage example:
  const onUpdateVerify = async () => {
    try {
      const willNotVerified = await swal({
        title: "Are you sure?",
        text: "Are you sure that you want to update the record?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Cancel", "Confirm"],
      });

      if (willNotVerified) {
        const formVariablesCopy = { ...ProviderDataforForm };
        const payerFieldss = [...payerResult];
        const location = [...serviceFields];
        const remarks = [...remarksData];
        const appStatusChnage = [...statusChnageDate];
        let obj = {
          formVariablesCopy,
          payerFieldss,
          location,
          remarks,
          appStatusChnage,
        };
        obj = removeArraysFromProviderData(obj);
        console.log(payerFieldss, "objobj");
        let responce;
        let res;
        if (true) {
          try {
            setLoading(true);
            if (
              obj.formVariablesCopy.id !== "" &&
              obj.formVariablesCopy.id !== null
            ) {
              res = await UpdateQuickFormUpdate(obj);
            }

            if (
              PracticesDataforForm.id !== "" ||
              PracticesDataforForm.id !== null
            ) {
              responce = await createNewPractices(PracticesDataforForm);
            }
            setLoading(false);

            if (responce.status === 200) {
              swal(
                "Verified!",
                "Practice  has been updated successfully",
                "success"
              );
            }
            if (res.status === 200 && responce.status === 200) {
              swal(
                "Verified!",
                "Information has been updated successfully",
                "success"
              );
            }

            // console.log(res, "bigbigbig");
          } catch (error) {
            console.log(error, "error");
            if (error.status === 400) {
              toast.error("Please seelct Provider");
            }
            setLoading(false);
            toast.error("Error while Updating Record")

          }
        } else {
          // User cancelled the update, handle accordingly
        }
      }
    } catch (error) {
      // Handle any error that occurs during the swal interaction
      toast.error("Error while Updating Record")

      console.error(
        "Error occurred while handling update confirmation:",
        error
      );
    }
  };

  const fetchProviderData = async (id) => {
    setLoading(true);
    try {
      const responce = await getQuickprovData({ id });
      if (responce.status === 200) {
        toast.success("success");
      }
      console.log(responce, "responce");

      setProviderData(responce?.data[0]);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };
  console.log(ProviderDataforForm, "ProviderDataforForm");

  console.log(PracticesDataforForm, "practicesValues");
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)",
        cursor: "pointer",
        color: "white",
      },
    }),
  };
  
  console.log(PracticesDataforForm, "PracticesDataforForm");
  return (
    <>
      {loadingn ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ) : (
        ""
      )}

      <>
        <div className="col-sm-12 mb-2">
          {/* Patient summary start           */}
          <div className="patientBg">
            <div className="row">
              <div className="col-sm-12">
                <div class="smHd">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>Practice Info</div>
                    {/* <div className=" iconBlue">
                      <button onClick={handleEditClick}>Close Edit</button>
                      <i class="fas fa-user-edit"></i>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="patientBgSummary">
                  <div className="SummaryBlock">
                    <div className="label">Client FName</div>
                    <div className="entity">
                      <input
                        type="text"
                        name="client_name"
                        className="inputField"
                        defaultValue={PracticesDataforForm.client_name}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Legal Business Name</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        name="practice_name"
                        defaultValue={PracticesDataforForm?.practice_name}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Group NPI</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="group_npi"
                        defaultValue={PracticesDataforForm?.group_npi}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Tax ID</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        name="practice_tax_id"
                        defaultValue={PracticesDataforForm?.practice_tax_id}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Speciality</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="speciality"
                        defaultValue={PracticesDataforForm?.speciality}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Taxonomy</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="taxnomy"
                        defaultValue={PracticesDataforForm?.taxnomy}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">CLIA</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="clia"
                        type="text"
                        defaultValue={PracticesDataforForm?.clia}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock ">
                    <div className="label">CLIA Expiry</div>
                    <div className="entity w-100">
                      <input
                        className="inputField "
                        name="clia_expiry"
                        type="date"
                        defaultValue={PracticesDataforForm?.clia_expiry}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div class="smHd mt-2">Services </div>
              </div>
              <div className="col-sm-12">
                <div className="patientBgSummary">
                  <div className="SummaryBlock">
                    <div className="label">Service Address</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="service_address"
                        defaultValue={PracticesDataforForm?.service_address}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">City</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="service_city"
                        defaultValue={PracticesDataforForm?.service_city}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">State</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="service_state"
                        defaultValue={PracticesDataforForm?.service_state}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Zip</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="service_zip"
                        defaultValue={PracticesDataforForm?.service_zip}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Country</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        defaultValue={PracticesDataforForm?.service_country}
                        name="service_country"
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Fax</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="service_fax"
                        defaultValue={PracticesDataforForm?.service_fax}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Office Phone</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="service_phone"
                        defaultValue={PracticesDataforForm?.service_phone}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div class="smHd mt-2">Pay to Address</div>
              </div>
              <div className="col-sm-12">
                <div className="patientBgSummary">
                  <div className="SummaryBlock">
                    <div className="label">Service Address</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="pay_address"
                        defaultValue={PracticesDataforForm?.pay_address}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">City</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="pay_city"
                        defaultValue={PracticesDataforForm?.pay_city}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">State</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="pay_state"
                        defaultValue={PracticesDataforForm?.pay_state}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Zip</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="pay_zip"
                        defaultValue={PracticesDataforForm?.pay_zip}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Country</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="pay_country"
                        defaultValue={PracticesDataforForm?.pay_country}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div class="smHd mt-2">List of Contacts</div>
              </div>
              <div className="col-sm-12">
                <div className="patientBgSummary">
                  <div className="SummaryBlock">
                    <div className="label">Person/Name </div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="contact1_name"
                        defaultValue={PracticesDataforForm?.contact1_name}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Contact1 Email</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="contact1_email"
                        defaultValue={PracticesDataforForm?.contact1_email}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Contact1 Phone</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        name="contact1_phone"
                        defaultValue={PracticesDataforForm?.contact1_phone}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Contact1 Mobile</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        name="contact1_mobile"
                        defaultValue={PracticesDataforForm?.contact1_mobile}
                        onChange={handleChnageForPractices}
                      />
                    </div>{" "}
                  </div>

                  <div className="SummaryBlock">
                    <div className="label">Contact2 Email</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        name="contact2_email"
                        defaultValue={PracticesDataforForm?.contact2_email}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Contact2 Phone</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="contact2_phone"
                        defaultValue={PracticesDataforForm?.contact2_phone}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Contact2 Mobile</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        name="contact2_mobile"
                        defaultValue={PracticesDataforForm?.contact2_mobile}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Owner</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        defaultValue={PracticesDataforForm?.onwer_name}
                        name="onwer_name"
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Email</div>
                    <div className="">
                      {" "}
                      <input
                        className="inputField"
                        name="onwer_email"
                        defaultValue={PracticesDataforForm?.onwer_email}
                        onChange={handleChnageForPractices}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Patient summary end           */}
        </div>

        <div className="col-sm-12 mb-2">
          <div className="providerBg">
            <div className="row">
              <div className="col-sm-12">
                <div class="smHd02">
                  <div>Provider Info</div>
                  <div className="iconBlue"></div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="patientBgSummary">
                  <div className="SummaryBlock">
                    <div className="label">Provider Fname</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="name"
                        defaultValue={ProviderDataforForm?.name}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Provider Lname</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="last_name"
                        defaultValue={ProviderDataforForm?.last_name}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">SSN</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="ssn"
                        defaultValue={ProviderDataforForm?.ssn}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Individual NPI</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        name="individual_npi"
                        defaultValue={ProviderDataforForm?.individual_npi}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">DOB</div>
                    <div className="entity">
                      <input
                      type="date"
                        className="inputField"
                        name="dob"
                        defaultValue={ProviderDataforForm?.dob?.split("T")[0]}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Tax ID</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="tax_id"
                        defaultValue={ProviderDataforForm?.tax_id}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Phone/Cell#</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="cell"
                        defaultValue={ProviderDataforForm?.cell}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    {/* <div className="label">This filed in pending</div>
                  <div className="entity">No</div> */}
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Provider Email</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="email"
                        defaultValue={ProviderDataforForm?.email}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Ind Medicare ID</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="medicare_id"
                        defaultValue={ProviderDataforForm?.medicare_id}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Ind Medicad ID</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="medicaid_id"
                        defaultValue={ProviderDataforForm?.medicaid_id}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Grp Medicaid ID</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="grp_madicaid_id"
                        defaultValue={ProviderDataforForm?.grp_madicaid_id}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Speciality</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="speciality"
                        defaultValue={ProviderDataforForm?.speciality}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Group MRC ID</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="grp_mrc_id"
                        defaultValue={ProviderDataforForm?.grp_mrc_id}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Medicaid User</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="medicaid_user"
                        defaultValue={ProviderDataforForm?.medicaid_user}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Gender</div>

                    <div className="entity">
                      <input
                        className="inputField"
                        defaultValue={ProviderDataforForm?.gender}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Office Mgr Name</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="manager_name"
                        defaultValue={ProviderDataforForm?.manager_name}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Office Mgr Email</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="manager_email"
                        defaultValue={ProviderDataforForm?.manager_email}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Website</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="website"
                        defaultValue={ProviderDataforForm?.website}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 mt-2">
                <div class="smHd02">
                  <div>Services</div>
                  <button></button>
                </div>

                {/* {ProviderDataforForm.locations.map((field) => {
                    return (
                      <>
                        <div class="moreServices">
                          <div class="row">
                            <div class="col-sm-6 col-lg-3">
                              <div class="form-group form-inline mb-2">
                                <label class="inputLabel">Service Loc</label>
                                <input
                                  className="inputField"
                                  defaultValue={field?.service_location}
                                />
                              </div>
                            </div>
                            <div class="col-sm-6 col-lg-3">
                              <div class="form-group form-inline mb-2">
                                <label class="inputLabel">Service phone</label>
                                <input
                                  className="inputField"
                                  defaultValue={field?.phone}
                                />
                              </div>
                            </div>
                            <div class="col-sm-6 col-lg-3">
                              <div class="form-group form-inline mb-2">
                                <label class="inputLabel">Service Fax</label>
                                <input
                                  className="inputField"
                                  defaultValue={field?.fax}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })} */}
              </div>
              {serviceFields.map((field, index) => {
                return (
                  <>
                    <div className="row accordionModalForm" key={index}>
                      <div className="col-sm-6 col-lg-3">
                        <div className="form-group form-inline mb-2">
                          <label
                            className="inputLabel pt-2 w-100"
                            style={{ color: "black", marginRight: "6px" }}
                          >
                            Service location
                          </label>
                          <input
                            className="form-control inputField w-100"
                            type="text"
                            name="service_location"
                            placeholder=""
                            value={serviceFields[index].service_location}
                            onChange={(event) =>
                              handleChange(event, index, "service_location")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <div className="form-group form-inline mb-2">
                          <label
                            className="inputLabel pt-2 w-100"
                            style={{ color: "black", marginRight: "6px" }}
                          >
                            Phone
                          </label>
                          <input
                            className="form-control inputField w-100"
                            type="text"
                            name="phone"
                            placeholder=""
                            value={serviceFields[index].phone}
                            onChange={(event) =>
                              handleChange(event, index, "phone")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        <div className="form-group form-inline mb-2">
                          <label
                            className="inputLabel pt-2 w-100"
                            style={{ color: "black", marginRight: "6px" }}
                          >
                            Fax
                          </label>
                          <input
                            className="form-control inputField w-100"
                            type="text"
                            name="fax"
                            placeholder=""
                            value={serviceFields[index].fax}
                            onChange={(event) =>
                              handleChange(event, index, "fax")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 justify-content-center">
                        <div className="d-flex justify-content-center align-items-center mt-5">
                          {serviceFields.length > 1 ? (
                            <i
                              className="fas fa-times"
                              style={{ color: "#ec2b09" }}
                              onClick={() => removeServiceField(index)}
                            ></i>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              <div className="d-flex justify-content-end">
                <button className="btnSave mb-2" onClick={addServiceField}>Add more locations</button>
              </div>
              <div className="col-sm-12">
                <div class="smHd02">DEA</div>
              </div>
              <div className="col-sm-12">
                <div className="patientBgSummary">
                  <div className="SummaryBlock">
                    <div className="label">Certification #</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="dea_certification_number"
                        defaultValue={
                          ProviderDataforForm?.dea_certification_number
                        }
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  {console.log(ProviderDataforForm?.dea_effective_date,"ddddd") }
                  <div className="SummaryBlock">
                    <div className="label">Effective</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        type="date"
                        defaultValue={ProviderDataforForm?.dea_effective_date?.split("T")[0]}
                        name="dea_effective_date"
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Expiry</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        type="date"
                        name="dea_expiry_date"
                        defaultValue={ProviderDataforForm?.dea_expiry_date?.split("T")[0]}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div class="smHd02">Board Certification</div>
              </div>
              <div className="col-sm-12">
                <div className="patientBgSummary">
                  <div className="SummaryBlock">
                    <div className="label">Certification #</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="board_certification"
                        defaultValue={ProviderDataforForm?.board_certification}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Effective</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        type="date"
                        name="board_effective_date"
                        defaultValue={ProviderDataforForm?.board_effective_date?.split("T")[0]}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Expiry</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        type="date"
                        name="dea_expiry_date"
                        defaultValue={ProviderDataforForm?.dea_expiry_date?.split("T")[0]}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div class="smHd02">State License</div>
              </div>
              <div className="col-sm-12">
                <div className="patientBgSummary">
                  <div className="SummaryBlock">
                    <div className="label">License#</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="state_license"
                        defaultValue={ProviderDataforForm?.state_license}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Effective</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        type="date"
                        name="state_license_date"
                        defaultValue={ProviderDataforForm?.state_license_date?.split("T")[0]}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Expiry</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        type="date"
                        name="state_license_expiry"
                        defaultValue={ProviderDataforForm?.state_license_expiry?.split("T")[0]}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div class="smHd02">Home Address</div>
              </div>
              <div className="col-sm-12">
                <div className="patientBgSummary">
                  <div className="SummaryBlock">
                    <div className="label">Street</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        type="text"
                        name="home_street"
                        defaultValue={ProviderDataforForm?.home_street}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Suite/Other</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        type="text"
                        name="home_suite"
                        defaultValue={ProviderDataforForm?.home_suite}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">City</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        type="text"
                        name="home_city"
                        defaultValue={ProviderDataforForm?.home_city}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">State</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        type="text"
                        name="home_state"
                        defaultValue={ProviderDataforForm?.home_state}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">County</div>
                    <div className="entity">
                      {" "}
                      <input
                        className="inputField"
                        name="home_county"
                        type="text"
                        defaultValue={ProviderDataforForm?.home_county}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Zip</div>
                    <div className="entity">
                      {" "}
                      <input
                        type="number"
                        className="inputField"
                        name="home_zip"
                        defaultValue={ProviderDataforForm?.home_zip}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div class="smHd02">Billing/Mailing Address</div>
              </div>
              <div className="col-sm-12">
                <div className="patientBgSummary">
                  <div className="SummaryBlock">
                    <div className="label">Street</div>
                    <div className="entity">
                      {" "}
                      <input
                        type="text"
                        className="inputField"
                        name="mailing_street"
                        defaultValue={ProviderDataforForm?.mailing_street}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Suite/Other</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="mailing_suite"
                        defaultValue={ProviderDataforForm?.mailing_suite}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">City</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        type="text"
                        name="mailing_city"
                        defaultValue={ProviderDataforForm?.mailing_city}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">State</div>
                    <div className="entity">
                      <input
                        name="mailing_state"
                        className="inputField"
                        onChange={handleChangeForproviders}
                        type="text"
                        defaultValue={ProviderDataforForm?.mailing_state}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">County</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="mailing_county"
                        type="text"
                        defaultValue={ProviderDataforForm?.mailing_county}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">Zip</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        type="number"
                        onChange={handleChangeForproviders}
                        name="mailing_zip"
                        defaultValue={ProviderDataforForm?.mailing_zip}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div class="smHd02">Web Portal Access:</div>
              </div>
              <div className="col-sm-12">
                <div className="patientBgSummary">
                  <div className="SummaryBlock">
                    <div className="label">CAQH User</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        name="caqh_user"
                        type="text"
                        onChange={handleChangeForproviders}
                        defaultValue={ProviderDataforForm?.caqh_user}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">CAQH Pass</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        type="text"
                        name="caqh_pass"
                        defaultValue={ProviderDataforForm?.caqh_pass}
                        onChange={handleChangeForproviders}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">CAQH ID</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        type="number"
                        onChange={handleChangeForproviders}
                        name="caqh_id"
                        defaultValue={ProviderDataforForm?.caqh_id}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">PECOS User</div>
                    <div className="entity">
                      <input
                        className="inputField"
                        type="text"
                        name="pecos_user"
                        onChange={handleChangeForproviders}
                        defaultValue={ProviderDataforForm?.pecos_user}
                      />
                    </div>
                  </div>
                  <div className="SummaryBlock">
                    <div className="label">PECOS Pass</div>
                    <div className="entity">
                      <input
                        type="text"
                        name="pecos_pass"
                        onChange={handleChangeForproviders}
                        className="inputField"
                        defaultValue={ProviderDataforForm?.pecos_pass}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          <div className="d-flex flex-column" style={{}}>
            <div>
              {payerFields?.map((_, index) => (
                
                <div className="addmoreBg" key={index}>
                  	<div className="smHd02 p-2">
								<div>Payer</div>
							</div>
                 
                  <div className="d-flex">
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group mb-2">
                        <label htmlFor="staticEmail2" className="mt-2">
                          Select Payer
                        </label>
                        <Select
                          options={payerName ? payerName : []}
                          placeholder="Please Select Payer"
                          value={payerName?.find(
                            (id) => id.value == payerFields[index]?.payer_id
                          )}
                          onChange={(value) =>
                            handlePayerFieldChange(index, "payer_id", value)
                          }
                          style={customStyles}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          Effective Date
                        </label>
                        <input
                          className="form-control inputField w-100 w-100"
                          type="date"
                          name="sbf"
                          placeholder=""
                          value={payerFields[index].effective_date}
                          onChange={(e) =>
                            handlePayerFieldChange(
                              index,
                              "effective_date",
                              e.target.value,
                              e
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group mb-2">
                        <label htmlFor="staticEmail2" className="mt-2">
                          Status
                        </label>

                        <select
                          className=" inputField2"
                          name="status"
                          placeholder=""
                          value={payerFields[index].status}
                          onChange={(e) =>
                            handlePayerFieldChange(
                              index,
                              "status",
                              e.target.value,
                              payerFields[index]
                            )
                          }
                        >
                          {applicationStatus.map((option) => {
                            return (
                              <option value={option.label}>
                                {option.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          Provider Id
                        </label>
                        <input
                          className="form-control inputField w-100 w-100"
                          type="type"
                          name="sbf"
                          placeholder=""
                          value={payerFields[index].provider_identifier}
                          onChange={(e) =>
                            handlePayerFieldChange(
                              index,
                              "provider_identifier",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-sm-6 col-lg-9">
                      <div className="form-group form-inline mb-2">
                        <label
                          className="inputLabel pt-2 w-100"
                          style={{ color: "black", marginRight: "6px" }}
                        >
                          Remarks
                        </label>
                        <input
                          className="form-control inputField w-100 w-100"
                          type="type"
                          name="sbf"
                          placeholder=""
                          value={payerFields[index].remarks}
                          onChange={(e) =>
                            handlePayerFieldChange(
                              index,
                              "remarks",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 justify-content-start">
                      <div className="d-flex justify-content-center align-items-center mt-5">
                        {payerFields.length > 1 ? (
                          <i
                            className="fas fa-times"
                            style={{ color: "#ec2b09" }}
                            onClick={() => removePayerField(index)}
                          ></i>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div class="col-sm-6 col-lg-12 mt-2">
            <div class="form-group ">
              <div className="d-flex justify-content-end">
                <button className="btnSave " onClick={addPayerField}>
                  Add More Payer
                </button>
              </div>
            </div>
          </div>
        </>
      </>

      <div class="row col-lg-12 d-flex"></div>
      <div class="col-sm-6 col-lg-12 ">
        <div class="form-group ">
          <div className="d-flex justify-content-end">
            <button className="btnClear mr-1  mb-3">Clear</button>
            {
              <button
                type="button"
                className="btnSave mb-3"
                onClick={onUpdateVerify}
              >
                Save
              </button>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickUpdateForm1;
