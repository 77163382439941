import React from "react";


function ViewAllRecordMain(props) {



	return (
		<>
			<h4 className="panel-title">
				<div className="d-flex justify-content-between align-items-center w100bdr">
					<div>

						<div className="titleStyle"> {props?.name}</div>
					</div>
					<div className="d-flex">
						{props.title !== "" ? <button className="btnSave" onClick={() => props.addNew(props.name)}>
							{props.title}
						</button> : ''}

					</div>
				</div>
			</h4>


			<div id="collapseOne" className={"panel-collapse collapse show"}>
				{props.component}
			</div>

		</>
	);
}
export default ViewAllRecordMain;
