




import React, { useEffect, useState } from "react";

import Select from "react-select";
import { allUsers } from "../../../Utils/ApiManager";
import DataTable from "../../../Common/RsuitDataGrid/userTaskViewTable";
import { useSelector } from "react-redux";
import Modal from "../../../Common/CommonModal";
import AddUsers from "../addNewUser/AddUsers";


function ViewUsers(props) {
  const { statesCodes } = useSelector((state) => state);
  const [search, setSearch] = useState('')
  const [dateForgrid, setDataForGrid] = useState([]);

  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    limit: 10,
    page: 1,
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dataUpdate, setDataUpdate] = useState({ type: '', id: '' });
  useEffect(() => {
    if (dataUpdate?.id) {
      openModal();
    }
  }, [dataUpdate])


  const change = async (e) => {
    let value = e.target.value
    setSearch(value)
  }

  const arr = [
    { colName: "User Name", flexGrow: 1.6, dataKey: 'user_name', },
    { colName: "Email", flexGrow: 1.2, dataKey: 'email', },
    { colName: "User Role", flexGrow: 0.9, dataKey: 'role_name', },
    {
      colName: "Joining Date",
      flexGrow: 0.5,
      dataKey: "created_at",
      CustomCell: ({ data }) => {
        return <div>{data?.split("T")[0]}</div>;
      },
    },

    { colName: "Action", flexGrow: 0.8, dataKey: 'action', }

  ];



  const openModal = () => {
    props?.addNew(props?.name);
  };

  const closeModal = () => {
    setDataUpdate({ type: '', id: '' });
    props?.addNew("")
  };

  useEffect(() => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      search: search,
      dateFrom: startDate ? startDate : "",
      dateTo: endDate ? endDate : "",
    };
    fetchUserData(payload);
  }, [paginationData.page, paginationData.limit, search]);

  const fetchUserData = async (payload) => {

    setLoading(true);


    allUsers(payload).then((res) => {
      setDataForGrid(res.data.data)
      setPaginationData((prevPaginationData) => ({
        ...prevPaginationData,
        count: res?.data.recordsTotal
        ,
      }));
      setDataUpdate({ type: '', id: '' });
      setLoading(false);
    }).catch((res) => {
      setDataUpdate({ type: '', id: '' });
      setLoading(false);
    })

  };

  const handleChangeLimit = (newLimit) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      limit: newLimit,
      page: 1,
    }));
  };

  const handleFilter = () => {

    const payload = {
      page: Number(0),
      limit: Number(0),

      dateFrom: startDate ? startDate : "",
      dateTo: endDate ? endDate : "",
      search: search

    };
    if (payload.practice_id || payload.speciality || payload.status || payload.service_state || payload.dateFrom || payload.dateTo) {
      fetchUserData(payload);
    }
  };

  const handleChangePage = (newPage) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      page: newPage,
    }));
  };

  const handleChangeForDate = (event) => {
    const { name, value } = event.target;
    if (name === "start") {
      setStartDate(value);
    }
    if (name === "end") {
      setEndDate(value);
    }
  };

  const handleClear = () => {
    setSearch('')
    setStartDate("");
    setEndDate("");

    const emptyPayload = {
      page: 1,
      limit: Number(paginationData.limit),
      service_state: "",
      dateFrom: "",
      dateTo: "",
      search: ''
    };

    let check = startDate === "" && endDate === ""
    if (check) {
      return;
    } else {
      fetchUserData(emptyPayload);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <label className="inputLabel">Start Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="start"
              placeholder=""
              value={startDate}
              onChange={handleChangeForDate}
            />
          </div>
        </div>

        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <label className="inputLabel">End Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="end"
              placeholder=""
              value={endDate}
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        
        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <div className="d-flex mt-4">
              <button onClick={handleFilter} className="btnClear mr-1">
                Filter
              </button>
              <button onClick={handleClear} type="button" className="btnSave">
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <label className="inputLabel">search</label>
            <input
              className="form-control inputField"
              type="text"
              name="search"
              placeholder=""
              value={search}
              onChange={change}
            />
          </div>
        </div>
      </div>

      <DataTable
        dateForgrid={dateForgrid ?? []}
        Array={arr}
        loader={loading}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        totalPages={paginationData.count}
      />

       <Modal
        isOpen={props?.addNewButton === props.name ? true : false}
        closeModal={closeModal}
        title={"Add New User"}
      >
        <AddUsers data={dataUpdate} closeModal={closeModal} />
      </Modal>
    </>
  );
}
export default ViewUsers
