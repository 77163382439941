function LoginAction(payload) {
  return {
    type: "Login",
    payload: payload,
  };
}

/// Sign off redux action
function onLoginCredentialing(payload) {
  return {
    type: "Credentialing",
    payload: payload,
  };
}

function userLists(payload) {
  return {
    type: "userlist",
    payload: payload,
  };
}

function userNames(payload) {
  return {
    type: "userNames",
    payload: payload,
  };
}
function practiceName(payload) {
  return {
    type: "practiceName",
    payload: payload,
  };
}
function practIdies(payload) {
  return {
    type: "practiceIdies",
    payload: payload,
  };
}
function Specialities(payload) {
  return {
    type: "Specialities",
    payload: payload,
  };
}
function statesCodes(payload) {
  return {
    type: "statesCodes",
    payload: payload,
  };
}
function payerName(payload) {
  return {
    type: "payerName",
    payload: payload,
  };
}

export {
  LoginAction,
  onLoginCredentialing,
  userNames,
  userLists,
  practIdies,
  practiceName,
  Specialities,
  statesCodes,
  payerName
};
