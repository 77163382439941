// phoneUtils.js
export const formatPhoneNumber = (inputValue) => {
    let cleaned = inputValue.replace(/\D/g, ''); 
    let match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  
    if (match) {
      let formattedNumber = match[1] ? `(${match[1]}` : '';
      formattedNumber += match[2] ? `) ${match[2]}` : '';
      formattedNumber += match[3] ? `-${match[3]}` : '';
      return formattedNumber;
    }
  
    return cleaned;
  };
  