import React from "react";
import DoccViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Button } from "rsuite";
import { Modal, ButtonToolbar, Loader, Placeholder } from "rsuite";
function DocViewer({ openImageViwerModel, image, docViewer }) {
  console.log(image, "image");
  const [rows, setRows] = React.useState(0);
  const docs = [
    {
      uri:
        process.env.REACT_APP_LOCAL_SERVER_URL +
        'getDocuments/' +image
    },
  ];
  console.log(docs, "imageOnline");
  return (
    <>
      <Modal
        open={docViewer}
        onClose={() => openImageViwerModel(false)}
        //onEntered={handleEntered}
        onExited={() => {
          setRows(0);
        }}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title>Doc Viewer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DoccViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            theme={{
              primary: "#5296d8",
              secondary: "#ffffff",
              tertiary: "#5296d899",
              textPrimary: "#ffffff",
              textSecondary: "#5296d8",
              textTertiary: "#00000099",
              disableThemeScrollbar: false,
            }}
            style={{ height: "410px", width: "900px" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#0e7490",
              color: "white",
              fontSize: "0.805rem",
            }}
            onClick={() => openImageViwerModel(false)}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default DocViewer;
