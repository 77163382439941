import React from "react";
import { formatTimeTo12HourClock } from "../Providers/validation";

function ViewRemarks(props) {
	console.log(props.data, "ffffffff");
	return (
		<div>
			<div class="modal-body ">
				<div class="provider_formbox">
					<div class="form-group">
						<div class="tableStyle">
							<table width="100%">
								<thead>
									<tr>
										<th width="20%" >Date</th>
										<th width="20%">Remarks By</th>
										<th width="80%">Remarks</th>
									</tr>
								</thead>
								<tbody id="remark_rows">
									{props?.data.length > 0 ? (
										props?.data.map((re, index) => (
											<tr key={index}>
												<td>{re?.updated_at?.split("T")[0] } {formatTimeTo12HourClock(re?.updated_at?.split("T")[1].split(".")[0]) }</td>
												<td>{re?.User?.name??"N/A"}</td>
												<td>
													<p>
														{re?.remarks}
														<br />
														{""}
													</p>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan="3" style={{ textAlign: "center", height: "75px", width: "289px", paddingLeft: "31px" }}>
												<p style={{  paddingLeft: "42%" }}>No Remarks Found</p>
											</td>
										</tr>

									)}
								</tbody>
							</table>

						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ViewRemarks;
