import React, { Component } from "react";

export default class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.observer = React.createRef();
    this.triggerRef = React.createRef();
    this.myRef = React.createRef();
    this.state = {
      data: this.props.data,
      show: false,
      currentText: "",
      cleared: false,
      all: false,
      showInput: false,
      ...(this.props.infiniteScroll && {
        showData: this.props.defaultLoad
          ? this.props.defaultLoad
          : this.props.data.length < 20
          ? this.props.data.length
          : 20,
      }),
      isIntersecting: false,
      filteredData: [],
      allData: this.props.data.map((item) => {
        // // console.log(item)
        if (item.status == true) {
          return {
            id: item.id,
            value: item.value,
            display: item.display,
            checked: true,
          };
        } else {
          return { ...item, checked: false };
        }
      }),
    };
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    if (this.triggerRef.current) {
      this.observer.current.observe(this.triggerRef.current);
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    if (this.triggerRef.current) {
      this.observer.current.unobserve(this.triggerRef.current);
    }
  }
  handleClickOutside = (e) => {
    if (e.target.dataset.name == "select") {
      return;
    } else if (e.target.dataset.name == "img") {
      return;
    }
    if (!this.myRef.current.contains(e.target)) {
      this.setState({ show: false, showData: this.props.defaultLoad || 20 });
    }
  };
  handleSearch = (e) => {
    this.setState({
      filteredData: [],
      showInput: !this.state.showInput,
      show: false,
    });
  };

  handleSelect = (e) => {
    if (e.target.dataset.name == "img") {
      return;
    } else {
      this.setState({
        show: !this.state.show,
        showData: this.props.defaultLoad || 20,
      });
    }
  };
  allData = () => {
    console.log(this.state.allData);
    return this.state.allData
      .filter((item) => item.checked)
      .map((value) => {
        return {
          id: value.id,
          display: value.display,
          value: value.value,
        };
      });
  };
  handleClickDiv = async (e) => {
    if (e.target.dataset.name) {
      // handle click on all
      if (e.target.dataset.name == "all") {
        // handle click all on filtered data
        if (this.state.showInput && this.state.filteredData.length > 0) {
          await this.setState({
            all: !this.state.all,
            allData: this.state.allData.map((item) => {
              let foundItem = this.state.filteredData.find(
                (fitem) => item.id === fitem.id
              );
              if (foundItem) return { ...item, checked: !this.state.all };
              return item;
            }),
            filteredData: this.state.filteredData.map((item) => {
              return { ...item, checked: !this.state.all };
            }),
          });
          this.props.handleData(this.allData());
        } else {
          // when its normal all not filtered data
          await this.setState(
            {
              all: !this.state.all,
              allData: this.state.allData.map((item) => {
                return { ...item, checked: !this.state.all };
              }),
              filteredData: this.state.filteredData.map((item) => {
                return { ...item, checked: !this.state.all };
              }),
            },
            () => {
              console.log(this.state.allData);
              this.props.handleData(this.allData());
            }
          );
        }
      } else {
        // handle click on single element
        await this.setState(
          {
            all: !this.state.all,
            allData: this.state.allData.map((item) => {
              if (item.id == e.target.dataset.name) {
                return { ...item, checked: !item.checked };
              }
              return item;
            }),
            filteredData: this.state.filteredData.map((item) => {
              if (item.id == e.target.dataset.name) {
                return { ...item, checked: !item.checked };
              }
              return item;
            }),
          },
          () => {
            console.log(this.state.allData);
            this.props.handleData(this.allData());
          }
        );
      }
    }
  };

  handleCheckbox = async (e) => {
    if (e.target.id == "all") {
      // handle click on all
      // handle click all on filtered data

      if (this.state.showInput && this.state.filteredData.length > 0) {
        await this.setState({
          all: !this.state.all,
          allData: this.state.allData.map((item) => {
            let foundItem = this.state.filteredData.find(
              (fitem) => item.id === fitem.id
            );
            if (foundItem) return { ...item, checked: !this.state.all };
            return item;
          }),
          filteredData: this.state.filteredData.map((item) => {
            return { ...item, checked: !this.state.all };
          }),
        });
        this.props.handleData(this.allData());
      } else {
        // when its normal all not filtered data
        await this.setState(
          {
            all: !this.state.all,
            allData: this.state.allData.map((item) => {
              return { ...item, checked: !this.state.all };
            }),
            filteredData: this.state.filteredData.map((item) => {
              return { ...item, checked: !this.state.all };
            }),
          },
          () => {
            console.log(this.state.allData);
            this.props.handleData(this.allData());
          }
        );
      }
    } else {
      // handle click on single element

      await this.setState(
        {
          all: !this.state.all,
          allData: this.state.allData.map((item) => {
            if (item.id == e.target.id) {
              return { ...item, checked: !item.checked };
            }
            return item;
          }),
          filteredData: this.state.filteredData.map((item) => {
            if (item.id == e.target.id) {
              return { ...item, checked: !item.checked };
            }
            return item;
          }),
        },
        () => {
          console.log(this.state.allData);
          this.props.handleData(this.allData());
        }
      );
    }
  };

  componentDidUpdate(d, s) {
    const rerenderData = () => {
      this.setState({
        data: this.props.data,
        allData: this.props.data.map((item) => {
          if (item.status == true) {
            return {
              id: item.id,
              value: item.value,
              display: item.display,
              checked: true,
            };
          } else {
            return { ...item, checked: false };
          }
        }),
      });
    };

    if (
      this.props.data.length !== this.state.data.length
      // (this.props.data.length > 0 &&
      //   !this.props.data.every(
      //     (d) =>
      //       this.state.allData.find((v) => v.id === d.id).checked == d.status
      //   ))
    ) {
      rerenderData();
    } else if (
      !this.props.data.some((v) =>
        this.state.data.map((d) => d.id).includes(v.value)
      ) &&
      this.state.data.length > 0
    ) {
      rerenderData();
    } else if (
      this.props.data.length > 0 &&
      !this.props.data.some(
        (d) => this.state.allData.find((v) => v.id === d.id).checked == d.status
      ) &&
      this.props.data.some((d) => d.hasOwnProperty("status")) &&
      !this.state.initialRender
    ) {
      this.setState({ initialRender: true }, () => {
        rerenderData();
      });
    }
  }
  shouldComponentUpdate = (d, p, s) => {
    let rerender = false;
    if (
      d.data.length === this.state.data.length ||
      !d.data.every(
        (v) => this.props.data.find((s) => s.id === v.id)?.status === v.status
      )
    ) {
      rerender = !d.data.some((v) =>
        this.state.data.map((d) => d.id).includes(v.value)
      );
      if (!rerender) {
        if (this.props.data.some((r) => r.hasOwnProperty("status"))) {
          rerender = !this.state.allData?.every(
            (d) =>
              this.props.data.find((v) => v.id == d.id).status === d.checked
          );
        }
      }
    }

    if (
      d.defaultValue !== p.defaultValue ||
      this.state.show !== p.show ||
      this.state.showInput !== p.showInput ||
      this.state.all !== p.all ||
      this.state.filteredData.length !== p.filteredData.length ||
      p.isIntersecting ||
      this.state.data.length !== p.data.length ||
      d.data.length !== p.data.length ||
      d.clear === true ||
      p.cleared === true ||
      this.state.allData.some(
        (r) => p.allData.find((pd) => pd.id === r.id).checked !== r.checked
      ) ||
      p.allData.every(
        (r) =>
          this.state.allData.find((d) => d.id == r.id).checked === r.checked
      ) ||
      rerender
    ) {
      // // console.log(this.state,"re rendering")
      return true;
    }
    // // console.log(this.state,p,d,"not re rendering")
    return false;
  };
  render() {
    // console.log("re rendering MultiSelect");
    // // console.log(this.props.data)
    if (this.state.cleared === true) {
      this.setState({ cleared: !this.state.cleared });
    }

    if (this.props.clear === true) {
      this.setState(
        {
          data: this.props.data,
          show: false,
          currentText: "",
          cleared: true,
          all: false,
          showInput: false,
          ...(this.props.infiniteScroll && {
            showData: this.props.defaultLoad
              ? this.props.defaultLoad
              : this.props.data.length < 20
              ? this.props.data.length
              : 20,
          }),
          isIntersecting: false,
          filteredData: [],
          allData: this.props.data.map((item) => {
            if (item.status == true) {
              return {
                id: item.id,
                value: item.value,
                display: item.display,
                checked: true,
              };
            } else {
              return { ...item, checked: false };
            }
          }),
        },
        () => {
          this.props.handleData(this.allData());
          this.props.handleClear(false);
        }
      );
    }

    if (this.state.data.length < this.props.data.length) {
      this.setState({ data: this.props.data });
    }

    this.observer.current = new IntersectionObserver(
      (Entries) => {
        if (this.props.infiniteScroll) {
          let first = Entries[0];

          if (
            first.isIntersecting &&
            this.state.showData < this.props.data.length
          ) {
            this.setState({
              isIntersecting: Entries[0].isIntersecting,
              showData: this.state.showData + (this.props.perScroll || 20),
            });
          } else {
            this.setState({ isIntersecting: false });
          }
        }
      },
      {
        threshold: 0.8,
      }
    );
    let bubbleStyle = {
      // maxHeight: "108px",
      overflowY: "auto",
      display: "flex",
      flexWrap: "wrap",
    };
    let bubbleStyleHorizontal = {
      // maxHeight: "108px",
      overflowY: "hidden",
      overflowX: "scroll",
      display: "flex",
      // flexWrap: "wrap",
    };

    return (
      <>
        {/* min-width: max-content
      flex not wrap 
      overflow y disabled
      maxHeight to the option height
      no maxheight
      scroll horizontal */}
        {this.props.showBubble && !this.props.bubbleEnd && (
          <div
            //     display: flex;
            //     padding: 5
            // px
            //  10
            // px
            // ;
            //     color: white;
            //     min-width: MAX-CONTENT;
            //     max-height: 30
            // px
            // ;
            className="bubbles"
            style={
              this.props.scrollDirection == "vertical"
                ? bubbleStyle
                : this.props.scrollDirection == "horizontal"
                ? bubbleStyleHorizontal
                : bubbleStyle
            }
          >
            {this.state.allData.map((data) => {
              return (
                data.checked && (
                  <span
                    style={{
                      border: "1px solid white",
                      background:
                        (this.props.bubbleColor
                          ? this.props.bubbleColor.length > 0
                            ? this.props.bubbleColor
                            : null
                          : null) || "#5bbac0",
                      display: "flex",
                      padding: "5px 10px",
                      minWidth: "max-content",
                      color: "white",
                      maxHeight: "30px",
                      maxWidth: "max-content",
                      margin: "2px 0",
                      borderRadius: "20px",
                    }}
                  >
                    {data.display + " "}
                    <span
                      name={data.id}
                      id={data.id}
                      onClick={this.handleCheckbox}
                      style={{
                        cursor: "pointer",
                        margin: "0 0 0 5px",
                        background: this.props.bubbleCrossBgColor
                          ? this.props.bubbleCrossBgColor.length > 0
                            ? this.props.bubbleCrossBgColor
                            : "white"
                          : "white",
                        borderRadius: "12px",
                        padding: "0 6px",
                        color: this.props.bubbleCrossColor
                          ? this.props.bubbleCrossColor.length > 0
                            ? this.props.bubbleCrossColor
                            : "black"
                          : "black",
                      }}
                    >
                      X
                    </span>
                  </span>
                )
              );
            })}
          </div>
        )}
        <div
          style={{
            width: this.props.width ? this.props.width : "250px",
            display: "flex",
            flexDirection: "column",
            // position: "",
          }}
        >
          <div
            className="flex w-full items-center  rounded-sm bg-white"
            data-name="select"
            style={
              {
                // height: this.props.height ? this.props.height : "31px",
                // border: this.props.border ? this.props.border : "1px solid grey",
                // boxSizing: "border-box",
                // borderRadius: "6px",
                // display: "flex",
                // justifyContent: "space-between",
                // alignItems: "center",
                // width: "100%",
                // cursor: "pointer",
              }
            }
            onClick={!this.props.readonly ? this.handleSelect : () => {}}
          >
            {!this.state.showInput ? (
              <select
                onClick={
                  !this.props.readonly
                    ? () =>
                        this.setState({
                          show: !this.state.show,
                          showData: this.props.defaultLoad,
                        })
                    : () => {}
                }
                name=""
                id=""
                style={{
                  border: "none",
                  color: "#495057",
                  overflow: "hidden",
                  background: this.props.readonly && "#e9ecef",
                  width: "100%",
                  height: "100%",
                  outline: "none",
                  // borderRadius: "6px",
                  cursor: "pointer",
                  padding: "4px 6px",
                  pointerEvents: "none",
                }}
              >
                <option value="" style={{ overflow: "hidden" }}>
                  {this.state.allData.every((item) => !item.checked)
                    ? (this.props.defaultValue?.length > 0
                        ? ""
                        : null) || "Please Select"
                    : (this.state.allData.every((item) => item.checked) &&
                        this.props.showAll) ||
                      (this.state.filteredData.every((item) => item.checked) &&
                        this.state.filteredData.length > 0 &&
                        this.props.showAll)
                    ? "ALL"
                    : this.state.allData.map((item) =>
                        item.checked ? ""  : ""
                      )}
                </option>
              </select>
            ) : (
              <input
                onChange={async (e) => {
                  if (e.target.value === "") {
                    this.setState({ filteredData: [] });
                  }
                  if (e.target.value.length > 1) {
                    let filteredData = await this.state.allData.filter(
                      (item) => {
                        if (
                          item.display
                            .toLowerCase()
                            .startsWith(e.target.value.toLowerCase())
                        ) {
                          return true;
                        } else if (
                          item.display
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                        ) {
                          return true;
                        }
                      }
                    );

                    this.setState({
                      all: filteredData.every((nitem) => nitem.checked == true),
                      filteredData: filteredData.map((item) => {
                        return { ...item };
                      }),
                    });
                  }
                }}
                onFocus={() => {
                  this.setState({ show: true });
                }}
                type="text"
                placeholder="Enter Here..."
                data-name="img"
                className=" text-base"
                style={{
                  border: "none",
                  // fontSize: this.props.searchFontSize
                  //   ? this.props.searchFontSize
                  //   : "14px",
                  padding: "5px 9px",
                  outline: "none",

                  width: "100%",
                  height: "100%",
                }}
              />
            )}
            {this.props.searchIcon ? (
              <img
                data-name="img"
                onClick={!this.props.readonly ? this.handleSearch : () => {}}
                src={this.props.searchIcon}
                alt=""
                // className="max-w-[5px]"
                style={{
                  margin: "0 .6rem",
                  cursor: "pointer",
                  pointerEvents: "all",
                }}
                width={this.props.iconSize ? this.props.iconSize : "20px"}
              />
            ) : (
              <h6
                data-name="img"
                onClick={this.handleSearch}
                className="my-auto"
                style={{
                  padding: ".4rem",
                  fontSize: this.props.searchSize
                    ? this.props.searchSize
                    : "13px",
                }}
              >
                Search
              </h6>
            )}
          </div>

          <div
            className="selectbox"
            style={{
              top: "-5px",
              // boxSizing: "border-box",
              width: "100%",

              // justifyContent: "center",
              flexDirection: "column",

              zIndex: "9999",
              display: this.state.show ? "flex" : "none",
            }}
          >
            <div
              ref={this.myRef}
              className="mainbox"
              style={{
                position: "fixed",
                border: "1px solid #c5c5c5",
                padding: "5px 0px",
                // top: this.props.height ? this.props.height : "31px",
                background: this.props.background
                  ? this.props.background
                  : "white",
                width: "265px",
                maxHeight: `${
                  this.props.maxHeight ? this.props.maxHeight : "280px"
                }`,
                overflowY: "scroll",
              }}
            >
              {this.state.allData.length > 1 && this.props.showAll && (
                <div
                  onClick={this.handleClickDiv}
                  className="option"
                  data-name="all"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: this.props.optionHeight
                      ? this.props.optionHeight
                      : "15px",
                    // paddingTop: "10px",
                    paddingLeft: "10px",
                    // alignItems: "center",
                    // justifyContent: "space-around",
                    cursor: "pointer",
                  }}
                >
                  <input
                    onChange={this.handleCheckbox}
                    type="checkbox"
                    id="all"
                    checked={
                      this.state.allData.every((item) => item.checked) ||
                      (this.state.filteredData.every((item) => item.checked) &&
                        this.state.filteredData.length > 0)
                    }
                    // style={{ height: "15px", width: "15px" }}
                  />{" "}
                  <h4
                    style={{
                      pointerEvents: "none",
                      width: "90%",
                      paddingLeft: "10px",
                      fontSize: this.props.optionSize
                        ? this.props.optionSize
                        : "12px",
                    }}
                  >
                    All
                  </h4>
                </div>
              )}
              {this.state.filteredData.length > 0 && this.state.showInput
                ? this.state.filteredData
                    .slice(
                      0,
                      this.props.infiniteScroll
                        ? this.state.showData
                        : this.props.data.length
                    )
                    .map((item, index) => {
                      return (
                        <div
                          data-name={item.id}
                          title={item.display}
                          onClick={this.handleClickDiv}
                          className="option"
                          key={item.id}
                          style={{
                            display: "flex",
                            height: `${
                              this.props.optionHeight
                                ? this.props.optionHeight
                                : "20px"
                            }`,
                            // paddingTop: "10px",
                            paddingLeft: "10px",
                            // alignItems: "center",
                            // justifyContent: "space-around",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            onChange={this.handleCheckbox}
                            type="checkbox"
                            // style={{ height: "15px", width: "15px" }}
                            name={item.id}
                            id={item.id}
                            checked={this.state.filteredData[index].checked}
                          />{" "}
                          <h4
                            style={{
                              pointerEvents: "none",
                              width: "90%",
                              paddingLeft: "10px",
                              fontSize: this.props.optionSize
                                ? this.props.optionSize
                                : "12px",
                            }}
                          >
                            {item.display.length > 16
                              ? `${item.display.slice(
                                  0,
                                  this.props.truncate || 25
                                )}...`
                              : item.display}
                          </h4>
                        </div>
                      );
                    })
                : this.state.allData
                    .slice(
                      0,
                      this.props.infiniteScroll
                        ? this.state.showData
                        : this.props.data.length
                    )
                    .map((item, index) => {
                      return (
                        <div
                          data-name={item.id}
                          title={item.display}
                          onClick={this.handleClickDiv}
                          className="option"
                          key={item.id}
                          style={{
                            display: "flex",
                            height: `${
                              this.props.optionHeight
                                ? this.props.optionHeight
                                : "20px"
                            }`,
                            // paddingTop: "10px",
                            paddingLeft: "10px",
                            // alignItems: "center",
                            // justifyContent: "space-around",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            onChange={this.handleCheckbox}
                            type="checkbox"
                            // style={{ height: "15px", width: "15px" }}
                            name={item.id}
                            id={item.id}
                            checked={this.state.allData[index].checked}
                          />{" "}
                          <h4
                            style={{
                              pointerEvents: "none",
                              width: "90%",
                              paddingLeft: "10px",
                              fontSize: this.props.optionSize
                                ? this.props.optionSize
                                : "16px",
                            }}
                          >
                            {item.display.length > 16
                              ? `${item.display.slice(
                                  0,
                                  this.props.truncate || 28
                                )}...`
                              : item.display}
                          </h4>
                        </div>
                      );
                    })}
              <div
                ref={this.triggerRef}
                style={{
                  height: "5px",
                  transform: "translateY(-29px)",
                  pointerEvents: "none",
                }}
              ></div>
            </div>
          </div>
        </div>
        {this.props.showBubble && this.props.bubbleEnd && (
          <div
            //     display: flex;
            //     padding: 5
            // px
            //  10
            // px
            // ;
            //     color: white;
            //     min-width: MAX-CONTENT;
            //     max-height: 30
            // px
            // ;
            className=" min-h-[150px] max-h-[150px] border flex content-start text-sm p-1"
            style={
              this.props.scrollDirection == "vertical"
                ? bubbleStyle
                : this.props.scrollDirection == "horizontal"
                ? bubbleStyleHorizontal
                : bubbleStyle
            }
          >
            {this.state.allData.map((data) => {
              return (
                data.checked && (
                  <span
                    className="bg-primaryColor flex items-center h-fit border"
                    style={{
                      border: "1px solid white",
                      // background:
                      //   (this.props.bubbleColor
                      //     ? this.props.bubbleColor.length > 0
                      //       ? this.props.bubbleColor
                      //       : null
                      //     : null) || "#5bbac0",
                      display: "flex",
                      // padding: "5px 10px",

                      minWidth: "max-content",
                      color: "white",
                      // maxHeight: "30px",
                      maxWidth: "max-content",
                      margin: "2px 0",
                      // borderRadius: "20px",
                    }}
                  >
                    <span className="p-1">{data.display + " "}</span>
                    <span
                      className="ml-1 text-sm  text-white px-2"
                      name={data.id}
                      id={data.id}
                      onClick={
                        !this.props.readonly ? this.handleCheckbox : () => {}
                      }
                      style={{
                        cursor: "pointer",
                        // margin: "0 0 0 5px",
                        // background: this.props.bubbleCrossBgColor
                        //   ? this.props.bubbleCrossBgColor.length > 0
                        //     ? this.props.bubbleCrossBgColor
                        //     : "white"
                        //   : "white",
                        // borderRadius: "12px",
                        // padding: "0 6px",
                        // color: this.props.bubbleCrossColor
                        //   ? this.props.bubbleCrossColor.length > 0
                        //     ? this.props.bubbleCrossColor
                        //     : "black"
                        //   : "black",
                      }}
                    >
                      X
                    </span>
                  </span>
                )
              );
            })}
          </div>
        )}
      </>
    );
  }
}