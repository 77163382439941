import React, { useEffect, useState } from "react";

import Select from "react-select";
import ImageUploaderr from "../../../Common/FileUpload";
import { useSelector } from "react-redux";
import {
  gettaxonomyasspecialityAndProvidersAgainstPractices,
  getDocumentTypes,
  postDocumentsData,
  getPayerData,
  payersForDropDown,
  addNastsRecords,
  postEdiRemarks,
  UploadDocumentstoS3,
} from "../../../Utils/ApiManager";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader/Loader";
import axios from "axios";

const AddNewEdi = (props) => {
  const ActionsOptions = [
    { value: "", label: "Please Select Action" },
    { value: "EDI", label: "EDI" },
    { value: "ERA", label: "ERA" },
    { value: "ETF", label: "ETF" },
    { value: "Web", label: "Web" },
    { value: "DEMO", label: "DEMO" },
    { value: "Other", label: "Other" },
  ];
  const statusOptions = [
    { value: "", label: "Please Select Action" },
    { value: "NEW", label: "NEW" },
    { value: "Pending", label: "Pending" },
    { value: "In-process", label: "In-process" },
    { value: "DEclined", label: "DEclined" },
    { value: "Completed", label: "Completed" },
    { value: "Canceled", label: "Canceled" },
  ];

  const { state, onLoginCredentialing, Specialities } = useSelector(
    (state) => state
  );
  // const [practice_id, setPraacticeId] = useState("");
  const [options, setOptionsForPracticeDropdown] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  // const [payerId, setProviderOptionsId] = useState([]);

  // const [documnetsType, setdocumnetsType] = useState([]);
  // const [documnetsTypeId, setdocumnetsTypeId] = useState([]);
  const [idcheck, setIdcheck] = useState({
    id: "",
    flag: false,
  });
  const [data, setData] = useState({
    textAreaValue: "",
    action: "",
    status: "",
  });
  const [file, setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    practticeid: "",
    payername: "",
    documenttype: "",
  });

  console.log(options, "options");
  const practiceNames = Specialities?.map((item) => ({
    label: item.practice_name,
    value: item.practice_id,
  }));

  const getPayerName = async () => {
    try {
      const responce = await payersForDropDown();
      console.log(responce.data.data, "responce");
      const Dt = responce?.data?.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setProviderOptions(Dt);
    } catch (error) {}
  };

  // console.log(getProvider, "getProviders");
  // const fetchDocumnetTypes = async () => {
  //   try {
  //     await getDocumentTypes().then((res) => {
  //       console.log(res.data.documentTypes, "getDocumnetTypes");
  //       const Dt = res?.data?.documentTypes.map((item) => ({
  //         label: item.type,
  //         value: item.id,
  //       }));
  //       setdocumnetsType(Dt);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    setOptionsForPracticeDropdown(practiceNames);
    // fetchDocumnetTypes();
    getPayerName();
  }, []);
  console.log();
  const handleChangeselect = (selectedValue, selectedOption) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      practticeid: selectedOption ? selectedOption : "",
    }));
    // setPraacticeId(selectedValue);
  };
  const handleChangeProvider = (selectedValue, selectedOption) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      payername: selectedOption ? selectedOption : "",
    }));
    // setProviderOptionsId(selectedValue);
  };
  const handleInputChange = (event) => {
    setFile(event[0]);
  };

  console.log(file, "image");
  console.log(selectedOption.practticeid.value, "practice_id");
  console.log(selectedOption.payername.value, "payerid");
  console.log(selectedOption.documenttype.value, "documentstype id ");

  const handleSave = async (id) => {
    if (
      !file ||
      !onLoginCredentialing?.user?.id ||
      !selectedOption.practticeid.value ||
      !selectedOption.payername.value ||
      !data.action ||
      !data.status ||
      !data.textAreaValue
    ) {
      toast.error("Required fields are missing");
      setLoading(false);
      return; 
    }
    try {
      setLoading(true);
      const formData1 = new FormData();
      formData1.append("name", file?.name);
      formData1.append("user_id", onLoginCredentialing?.user?.id);
      formData1.append("deleted", false);
      formData1.append("file", file);
      formData1.append(
        "practice_id",
        selectedOption.practticeid.value ? selectedOption.practticeid.value : ""
      );
      formData1.append(
        "payer_id",
        selectedOption.payername.value ? selectedOption.payername.value : ""
      );
      formData1.append("action", data.action ? data.action : "");
      formData1.append("status", data.status ? data.status : "");
      formData1.append("remarks", data.textAreaValue ? data.textAreaValue : "");
      console.log(formData1, "hello Setup");
      const url = process.env.REACT_APP_LOCAL_SERVER_URL + "/saveDocuments/edi";
      console.log(url, "url");
    await  UploadDocumentstoS3(formData1, "edi").then((responce) => {
        if (responce.status) {
          toast.success("data uploaded successfully");
        }
        props.modalCloseCall();
        console.log(responce, "responce");
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error?.response?.status === 400) {
        toast.error("Required fields are missing");
      } else {
        toast.error("An error occurred while uploading the data and file");
      }
      setLoading(false);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const onClear = () => {
    setData({ status: "", action: "", textAreaValue: "" });
    setSelectedOption((prevState) => ({
      ...prevState,
      practticeid: "",
      payername: "",
    }));
    // setPraacticeId("");
    console.log("fsdfsdf");
  };
  console.log(data, "data");
  return (
    <div class="container ">
      <div
        class="row"
      >
        <div class="col-sm-6 col-lg-4 mb-3">
          <div class="form-group mb-2 ">
            <label for="staticEmail2 " class=" mt-3">
              <h4>Select Practice </h4>
            </label>
            <Select
              options={options ? options : ""}
              placeholder="Select an option"
              value={selectedOption.practticeid}
              // Use the original Select component
              onChange={(selectedOption) => {
                handleChangeselect(
                  Number(selectedOption.value),
                  selectedOption
                );
              }}
            />{" "}
          </div>
        </div>

        <div class="col-sm-6 col-lg-4">
          <div class="form-group mb-2 ">
            <label for="staticEmail2 " class=" mt-3">
              <h4>payer Name </h4>
            </label>
            <Select
              options={providerOptions ? providerOptions : ""}
              placeholder="Please Select Practice"
              value={selectedOption.payername}
              onChange={(selectedOption) =>
                handleChangeProvider(
                  Number(selectedOption.value),
                  selectedOption
                )
              }
            />{" "}
          </div>
        </div>
        <div class="col-sm-6 col-lg-6">
          <div class="form-group form-inline  mb-2">
            <label class="inputLabel pt-2  w-100" style={{ color: "black" }}>
              Action
            </label>
            <select
              className=" inputField2"
              name="action"
              placeholder="Please select state"
              value={data.action}
              onChange={handleChange}
            >
              {ActionsOptions.map((option) => {
                return <option value={option.value}>{option.label}</option>;
              })}
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-lg-6">
          <div class="form-group form-inline  mb-2">
            <label class="inputLabel pt-2  w-100" style={{ color: "black" }}>
              Status
            </label>
            <select
              className=" inputField2"
              name="status"
              placeholder=""
              value={data.status}
              onChange={handleChange}
            >
              {statusOptions.map((option) => {
                return <option value={option.value}>{option.label}</option>;
              })}
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-lg-12">
          <div class="form-group1 form-inline  mb-2">
            <label
              class="inputLabel pt-2    "
              style={{ color: "black", marginRight: "6px" }}
            >
              Remarks
            </label>

            <textarea
              class=" inputField1  "
              type="text"
              rows="5"
              cols="70"
              name="textAreaValue"
              placeholder=""
              value={data.textAreaValue}
              onChange={handleChange}
            />
          </div>
        </div>
        <div class=" col-lg-12 mt-2">
          <div class="form-group1 ">
            <label class="inputLabel" style={{ color: "black" }}>
              Please Select a File
            </label>
            <ImageUploaderr
              handleInputChange={handleInputChange}
              image={file}
            />
          </div>
        </div>
        <div class="row col-lg-12 d-flex"></div>
        <div class="col-sm-6 col-lg-12 mt-2">
          <div class="form-group ">
            <div className="d-flex justify-content-end mt-4">
              <button onClick={onClear} className="btnClear mr-1 ">
                Clear
              </button>
              <button
                onClick={handleSave}
                disabled={isLoading}
                type="button"
                className="btnSave"
              >
                Save{" "}
                {isLoading ? (
                  <Loader small={true} large={false} forButton={true} />
                ) : undefined}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewEdi;
