import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getReemarks, viewDocumnets } from "../../Utils/ApiManager";

import ViewRemarks from "../QuickUpdates/ViewRemarks";
import Loader from "../../Common/Loader/Loader";
import Modal from "../../Common/CommonModal";
import DocViewer from "../../Common/DocViewer/DocViewer";
import AllDocumentsViewver from "../../Common/AllDocumentsViewver";

function ViewPayerRemarks({ data }) {
  const [ProviderDataforForm, setProviderData] = useState("");
  const [isModalOpenRemark, setIsModalOpenRemarks] = useState(false);
  const { payerName } = useSelector((state) => state);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [loadingIndexd, setLoadingIndexd] = useState(null);
  const [docview, setDocView] = useState(false);

  const [file, setFile] = useState();
  const handelViewDocument = (rowData) => {
    console.log(rowData,"filenameee");
    setFile(rowData);
    setDocView(true);
    setBtnLoader(false);
    setLoadingIndexd(null);
    // window.open(pdfUrl, "_blank");
  };
  const viewDocumnet = (fileName) => {
    viewDocumnets(fileName)
      .then((res) => {
        setBtnLoader(false);
        setLoadingIndexd(null);
      })
      .catch(() => {
        setBtnLoader(false);
        setLoadingIndexd(null);
        console.log("error");
      });
  };
  const [btnloader, setBtnLoader] = useState(false);
  const getRemarks = async (a) => {
    console.log(a);
    setBtnLoader(true);
    try {
      const responce = await getReemarks(a);
      console.log(responce);
      setProviderData(responce?.data);

      setBtnLoader(false);
      setLoadingIndex(null);
      if (responce.status == 200) {
        setIsModalOpenRemarks(true);
      }
    } catch (error) {
      console.log(error, "errorR");
      setBtnLoader(false);
      setLoadingIndex(null);
    }
  };

  console.log(data);

  return (
    <div className="modal-body w-100">
      <div className="section-heading  font-weight-bold">Personal</div>
      <div className="provider_formbox">
        <div className="form-group">
          <div className="tableStyle">
            <table width="100%">
              <thead>
                <tr>
                  <th>Provider Name</th>
                  <th>Individual NPI</th>
                  <th>Group NPI</th>
                  <th>Tax ID</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <label
                      id="provider_name"
                      className="col-sm-3 control-label text-right"
                    >
                      {data?.provider?.name}
                    </label>
                  </td>
                  <td>
                    <label
                      id="individual_npi"
                      className="col-sm-3 control-label text-right"
                    >
                      {data?.provider?.individual_npi}
                    </label>
                  </td>
                  <td>
                    <label
                      id="group_npi"
                      className="col-sm-3 control-label text-right"
                    >
                      {data?.provider?.group_npi}
                    </label>
                  </td>
                  <td>
                    <label
                      id="tax_id"
                      className="col-sm-3 control-label text-right"
                    >
                      {data?.provider?.tax_id}
                    </label>
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="section-heading  font-weight-bold">Enrollment</div>
      <div className="provider_formbox">
        <div className="form-group">
          <div className="tableStyle">
            <table width="100%">
              <thead>
                <tr>
                  <th>Payer Name</th>
                  <th>Status</th>
                  <th>Provider Id</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody id="enrollment_rows">
                {data?.payerProviders.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td>
                          {item?.payer_id
                            ? payerName.find((i) => i.value === item?.payer_id)
                                ?.label
                            : "N/A"}{" "}
                        </td>
                        <td>{item?.status ? item?.status : "N/A"}</td>
                        <td>
                          {item?.provider_identifier
                            ? item?.provider_identifier
                            : "N/A"}
                        </td>
                        <td>
                          {" "}
                          {btnloader && loadingIndex === index ? (
                            <Loader small={true} large={false} />
                          ) : (
                            <i
                              className="fa fa-eye "
                              onClick={() => {
                                let a = {
                                  provider_id: item?.provider_id,
                                  payer_id: item?.payer_id,
                                };
                                setLoadingIndex(index);

                                getRemarks(a);
                              }}
                            ></i>
                          )}
                        </td>{" "}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="section-heading font-weight-bold">Documents</div>
      <div className="provider_formbox">
        <div className="form-group">
          <div className="tableStyle">
            <table width="100%" className="tabel">
              <thead>
                <tr>
                  <th>Document Name</th>
                  <th>File</th>
                  <th>Issue Date</th>
                  <th>Expiry Date</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody id="document_rows">
                {data?.Document?.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td>
                          {" "}
                          {item?.payer_id
                            ? data?.docType?.find(
                                (i) => i.id === item?.document_type_id
                              )
                            : "N/A"}{" "}
                        </td>
                        <td>{item?.name ? item?.name : "N/A"}</td>
                        <td>{item?.issue_date ? item?.issue_date : "N/A"}</td>
                        <td>{item?.expiry_date ? item?.expiry_date : "N/A"}</td>
                        <td>
                          {" "}
                          {btnloader && loadingIndexd === index ? (
                            <Loader small={true} large={false} />
                          ) : (
                            <i
                              className="fa fa-eye "
                              onClick={() => {
                                setLoadingIndexd(index);
                                setBtnLoader(true);
                                handelViewDocument(
                                  item?.name ? item?.name : ""
                                );
                              }}
                            ></i>
                          )}
                        </td>{" "}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="section-heading">Assigned Users</div>
      <div className="provider_formbox">
        <div className="form-group">
          <table width="100%">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody id="assigned_agent_rows"></tbody>
          </table>
        </div>
      </div>
      <div className="section-heading">Payments</div>
      <div className="provider_formbox">
        <div className="form-group">
          <table width="100%">
            <thead>
              <tr>
                <th>Service</th>
                <th>Unit Price</th>
                <th>Quantity</th>
                <th>Payment Date</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody id="payment_rows"></tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={isModalOpenRemark}
        closeModal={() => setIsModalOpenRemarks(false)}
        title={"Provider Information"}
      >
        <ViewRemarks data={ProviderDataforForm || ""} />{" "}
      </Modal>
      {file?
              <Modal
              
              isOpen={docview}
              closeModal={() => setDocView(false)}
              title={"Viewe"}
              >
            <AllDocumentsViewver folderName={"provider"} fileName={file}/>
              </Modal>:""
            }
    </div>
  );
}

export default ViewPayerRemarks;
