import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  CardSubtitle,
  ListGroupItem,
  Button,
} from "reactstrap";

const FeedData = [
  {
    title: "Cras justo odio",
    icon: "-bell",
    color: "primary",
    date: "6 minute ago",
  },
  {
    title: "New user registered.",
    icon: "bi bi-person",
    color: "info",
    date: "6 minute ago",
  },
  {
    title: "Server #1 overloaded.",
    icon: "bi bi-hdd",
    color: "danger",
    date: "6 minute ago",
  },
  {
    title: "New order received.",
    icon: "bi bi-bag-check",
    color: "success",
    date: "6 minute ago",
  },
  {
    title: "Cras justo odio",
    icon: "bi bi-bell",
    color: "dark",
    date: "6 minute ago",
  },
  {
    title: "Server #1 overloaded.",
    icon: "bi bi-hdd",
    color: "warning",
    date: "6 minute ago",
  },
];

const Feeds = () => {
  return (
		<Card className="mar-md-3">
			<CardBody>
				<CardTitle tag="h5">Feeds</CardTitle>
				<CardSubtitle className="mb-2 text-muted" tag="h6">
					Widget you can use
				</CardSubtitle>
				<ListGroup flush className="mt-4">
					{FeedData.map((feed, index) => (
						<ListGroupItem
							key={index}
							action
							href="/"
							tag="a"
							className="d-flex align-items-center p-3 border-0"
						>
							<Button
								className="rounded-circle me-3 minCircleHt"
								size="sm"
								color={feed.color}
							>
								<i className={feed.icon}></i>
							</Button>
							{feed.title}
							<small className="ms-auto text-muted text-small">
								{feed.date}
							</small>
						</ListGroupItem>
					))}
				</ListGroup>
			</CardBody>
		</Card>
	);
};

export default Feeds;
