import React, { useEffect, useState } from "react";
import DataTable from "../../../Common/RsuitDataGrid/userTaskViewTable";
import { UserAudit } from "../../../Utils/ApiManager";
import { formatTimeTo12HourClock } from "../../Providers/validation";

const UsersAudits = () => {
  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    limit: 10,
    page: 1,
    count: 0,
  });
  const [userdata, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const arr = [
    {
      colName: "Action",
      flexGrow: 0.6,
      dataKey: "event",
    },
    { colName: "User", flexGrow: 0.8, dataKey: "name" },
    {
      colName: "Login Time",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        let dsta = userdata?.records.find((item) => item?.id == data);
        console.log(dsta);
        let time = dsta?.new_values?.split('"')[3];
        return (
          <div>
            <b>Date:</b>  {time?.split(" ")[0]}
            <br />
          <b>Time:</b>  {time && time?.split(" ")[1]}
          </div>
        );
      },
    },
    { colName: "Ip Address", flexGrow: 0.7, dataKey: "ip_address" },
    {
      colName: "Old Login",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        let dsta = userdata?.records?.find((item) => item?.id == data);
        console.log(dsta);
        let time = dsta?.old_values?.split('"')[3];
        return (
          <div>
            <b>Date:</b>{time?.split(" ")[0]}
            <br />
            <b>Time:</b>{time && time?.split(" ")[1]}
          </div>
        );
      },
    },

    {
      colName: "New Login",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        let dsta = userdata?.records?.find((item) => item?.id == data);
        console.log(dsta);
        let time = dsta?.new_values?.split('"')[3];
        return (
          <div>
            <b>Date:</b>{time?.split(" ")[0]}
            <br />
            <b>Time:</b>{time && time?.split(" ")[1]}
          </div>
        );
      },
    },

    {
      colName: "Resource",
      flexGrow: 1.3,
      dataKey: "id",
      CustomCell: ({ data }) => {
        console.log();
        let dsta = userdata?.records?.find((item) => item?.id == data);
        return (
          <div>
          <b>Name:</b> {dsta?.name}
            <br />
           <b>Email:</b> {dsta?.email}
          </div>
        );
      },
    },
    // { colName: "Remarks", flexGrow: 1.1, dataKey: "status" },
  ];


  const fetchData = async (payload) => {
    setLoading(true);
    try {
      const data = await UserAudit(payload);
      // console.log(data.records, "userdAT");
      setUserData(data?.data);
      setPaginationData((prevPaginationData) => ({
        ...prevPaginationData,
        count: data?.data?.totalCount,
      }));
      console.log(data.data.records, "datata");
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
    }
    fetchData(payload);
  }, [paginationData.page, paginationData.limit,]);
  const handleChangePage = (newPage) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      page: newPage,
    }));
  };
  const handleChangeLimit = (newLimit) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      limit: newLimit,
      page: 1,
    }));
  };
  return (
    <div>
      {" "}
      <DataTable
        Array={arr}
        dateForgrid={userdata?.records}
        loader={loading}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        totalPages={userdata?.totalCount}
        wordWrap={true}

      />
    </div>
  );
};

export default UsersAudits;
