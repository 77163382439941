import { useState, CSSProperties } from "react";


function Loader({ small, large, forButton, }) {
  let color = forButton ? "light" : "secondary";
  let [loading, setLoading] = useState(true);
  let [colors, setColor] = useState("#ffffff");
  return (
    <div
      className="text-center"
      style={{
        display: forButton ? "contents" : "auto",
      }}
    >
      {small && (
        <div
          style={{
            width: large ? "60px" : "0.8rem",
            height: large ? "60px" : "0.8rem",
            marginTop: large ? "60px" : null,
          }}
          className={
            small
              ? `ml-1 spinner-border text-${color} spinner-border-sm`
              : `spinner-border text-${color}`
          }
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      )}
      {!small && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15vh",
          }}
        >
          <div
            className="spinner-border  text-primary"
            role="status"
          ></div>
        </div>
      )}
    </div>
  );
}

export default Loader;
