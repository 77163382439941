import React, { useState } from 'react';
import { createNewPractices, getPracticeDataForUpdate } from '../../../Utils/ApiManager';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { formatPhoneNumber } from '../../Providers/phoneformate';
import Select from "react-select";

import { useSelector } from 'react-redux';

const AddNew = (props) => {
const {statesCodes}= useSelector((state) => state)

const formattedOptions = statesCodes.StatesCodes.map((item) => ({
    value: item.code,
    label: item.state,
  }));
    const [loading, setLoading] = useState(false);
    
    let inputData = {
        id: '0',
        practice_name: '',
        practice_code: '',
        group_npi: '',
        client_name: '',
        practice_tax_id: '',
        speciality: '',
        taxnomy: '',
        clia: '',
        clia_expiry: '',
        service_address: '',
        service_city: '',
        service_state: '',
        service_zip: '',
        service_country: '',
        service_fax: '',
        service_phone: '',
        pay_address: '',
        pay_city: '',
        pay_state: '',
        pay_zip: '',
        pay_country: '',
        contact1_name: '',
        contact1_email: '',
        contact1_phone: '',
        contact2_name: '',
        contact2_email: '',
        contact2_phone: '',
        owner_name: '',
        owner_email: '',
    }
    const [data, setData] = useState(inputData);
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: "14px",
          color: state.isSelected ? "white" : "black",
          backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
          marginTop: "2px",
          ":hover": {
            backgroundColor: "rgb(14, 116, 144)",
            cursor: "pointer",
            color: "white",
          },
        }),
      };
    useEffect(() => {
        if (props?.data?.type == 'edit' && props?.data?.id != null) {
            fetchPracticeData(props?.data?.id)
        }
    }, [props?.data?.id])


    const savePracticeData = () => {
        setLoading(true);
       createNewPractices(data).then((res) => {
       
                toast(res?.data)
                setLoading(false)
                props.closeModal()
            }).catch((err) => {
                setLoading(false)
                toast(err?.message); // Assuming you have a toast function to display error messages.
              });

      
    };
    const fetchPracticeData = (payload) => {       
            setLoading(true);
             getPracticeDataForUpdate(payload).then((res) => {
                setData(res.data);
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
                toast(err?.message); // Assuming you have a toast function to display error messages.
              });
           
        
    };


    const onClear = () => {
        setData(inputData)
    }
    const handleChange = (e) => {
        let { value, name } = e.target
        if (name == "service_phone" || name == "contact1_phone" || name == "contact2_phone"||name=="service_fax") {
            let valu = formatPhoneNumber(value)
            setData({ ...data, [name]: valu });
        }
        else {
            setData({ ...data, [name]: value });
        }

    };

    return (
        <div>
            {loading ? <div className="loaderbgpopup">
        <div className="loaderdivpopup"></div>
      </div> : ''}
            <div
                className="row"
                style={{
                    // backgroundColor: "#F0F8FF",
                    // paddingRight: "10px",
                    // borderRadius: "7px",
                    // border: "1px solid #F0F8FF",
                    // marginLeft: "10px",
                }}
            >
                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label
                            className="inputLabel"
                            style={{ color: "black", marginRight: "6px" }}
                        >
                            Practice Code
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="practice_code"
                            placeholder=""
                            value={data?.practice_code}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Group NPI
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="group_npi"
                            placeholder=""
                            value={data?.group_npi}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Client Name
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="client_name"
                            placeholder=""
                            value={data?.client_name}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Practice Name
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="practice_name"
                            placeholder=""
                            value={data?.practice_name}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Tax ID
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="practice_tax_id"
                            placeholder=""
                            value={data?.practice_tax_id}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Speciality
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="speciality"
                            placeholder=""
                            value={data?.speciality}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Taxonomy
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="taxnomy"
                            placeholder=""
                            value={data?.taxnomy}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            CLIA
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="clia"
                            placeholder=""
                            value={data?.clia}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black" }}>
                            CLIA Expiry
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="date"
                            name="clia_expiry"
                            placeholder=""
                            value={data?.clia_expiry}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Service Address
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="service_address"
                            placeholder=""
                            value={data?.service_address}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            City
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="service_city"
                            placeholder=""
                            value={data?.service_city}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3" style={{ display: "flex", flexDirection: "column" }}>
                    
                    <div className="form-group form-inline mb-0">
                            <label
                              className="inputLabel pt-2 w-100"
                              style={{ color: "black",  marginRight: "6px"}}
                            >
                              State
                            </label>
                          </div>
                        <Select
                  options={formattedOptions??[]}
                  placeholder="Select an option"
                  styles={customStyles}
                  value={data?.service_state&& formattedOptions?.find((item)=>data?.service_state==item.value)}
                  onChange={(value) =>
                    handleChange({
                      target: { value: value.label, name: "service_state" },
                    })
                  }
                />
                  
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Zip
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="service_zip"
                            placeholder=""
                            value={data?.service_zip}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Country
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="service_country"
                            placeholder=""
                            value={data?.service_country}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Fax
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="service_fax"
                            placeholder=""
                            maxLength={14}
                            value={data?.service_fax}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Office Phone
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="service_phone"
                            maxLength={14}
                            placeholder=""
                            value={data?.service_phone}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Pay Address
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="pay_address"
                            placeholder=""
                            value={data?.pay_address}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            City
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="pay_city"
                            placeholder=""
                            value={data?.pay_city}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3" style={{ display: "flex", flexDirection: "column" }}>
                    
                    <div className="form-group form-inline mb-0">
                            <label
                              className="inputLabel pt-2 w-100"
                              style={{ color: "black",  marginRight: "6px"}}
                            >
                              State
                            </label>
                          </div>
                        <Select
                  options={formattedOptions??[]}
                  placeholder="Select an option"
                  styles={customStyles}
                  value={data?.pay_state&& formattedOptions?.find((item)=>data?.pay_state==item.value)}
                  onChange={(value) =>
                    handleChange({
                      target: { value: value.label, name: "pay_state" },
                    })
                  }
                />
                  
                </div>
               

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Zip
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="pay_zip"
                            placeholder=""
                            value={data?.pay_zip}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Country
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="pay_country"
                            placeholder=""
                            value={data?.pay_country}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Contact1
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="contact1_name"
                            placeholder=""
                            value={data?.contact1_name}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Contact1 Email
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="contact1_email"
                            placeholder=""
                            value={data?.contact1_email}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Contact1 Phone
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            maxLength={14}
                            name="contact1_phone"
                            placeholder=""
                            value={data?.contact1_phone}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Contact2
                        </label>
                        <input
                            className="inputField1 w-100"
                            type=""
                            name="contact2_name"
                            placeholder=""
                            value={data?.contact2_name}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Contact2 Email
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="contact2_email"
                            placeholder=""
                            value={data?.contact2_email}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Contact2 Phone
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="contact2_phone"
                            maxLength={14}
                            placeholder=""
                            value={data?.contact2_phone}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Owner
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="owner_name"
                            placeholder=""
                            value={data?.owner_name}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                    <div className="form-group1 form-inline mb-2">
                        <label className="inputLabel" style={{ color: "black", marginRight: "6px" }}>
                            Owner Email
                        </label>
                        <input
                            className="inputField1 w-100"
                            type="text"
                            name="owner_email"
                            placeholder=""
                            value={data?.owner_email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row col-lg-12 d-flex"></div>
            <div className="col-sm-6 col-lg-12 mb-3">
                <div className="form-group1">
                    <div className="d-flex justify-content-end mt-2">
                        <button className="btnClear mr-1" onClick={onClear}>Clear</button>
                        <button type="button" className="btnSave" onClick={savePracticeData}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddNew;
