import React, { useState, useEffect } from "react";
import Select from "react-select";
import DataTable from "../../../Common/RsuitDataGrid/userTaskViewTable";
import { getPaginatedData, getUserNames } from "../../../Utils/ApiManager";

function UserProductivity() {
  const [providerData, setProvidersData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    limit: 10,
    page: 1,
    count: 0,
  });
  const [Loadin, setLoadin] = useState(false);

  const [options, setOptions] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [selectedOption, setSelectedOption] = useState(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)",
        cursor: "pointer",
        color: "white",
      },
    }),
  };

  useEffect(() => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      date_from: startDate ? startDate : "",
      date_to: endDate ? endDate : "",
    };
    if (selectedOption !=null) {
      payload.user_id = selectedOption.value;
    }
    fetchData(payload);
    fetchUseNmaes();
  }, [paginationData.page, paginationData.limit]);
  const handleFilter = () => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      date_from: startDate ? startDate : "",
      date_to: endDate ? endDate : "",
      user_id: selectedOption.value ? selectedOption.value : ""
      
    };
    if (payload.date_from !== "" || payload.date_to !== "" || payload.user_id !== "") {
      fetchData(payload);
    }
  };
  const fetchData = async (payload) => {
    setLoadin(true);
    try {
      const data = await getPaginatedData(payload);
      console.log(data);
      setProvidersData(data.data);

      setPaginationData((prevPaginationData) => ({
        ...prevPaginationData,
        count: data.totalPages,
      }));

      setLoadin(false);
    } catch (error) {
      console.error(error);
      setLoadin(false);
    }
  };
  const fetchUseNmaes = async () => {
    try {
      const responce = await getUserNames();
      console.log(responce.data, "responce");
      const transformedData = responce?.data.map((item) => ({
        label: item.user,
        value: item.user_id,
      }));
      setOptions(transformedData);
    } catch (error) { }
  };

  const handleChangeProvider = (selectedValue, selectedOption) => {
    setSelectedOption(selectedOption)
  }



  const handleChangeLimit = (newLimit) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      limit: newLimit,
      page: 1,
    }));
  };

  const handleChangePage = (newPage) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      page: newPage,
    }));
  };

  const arr = [
    { colName: "Name", flexGrow: 0.7, dataKey: "user" },

    {
      colName: "Email",
      flexGrow: 0.9,
      dataKey: "email",
    },
    {
      colName: "Action",
      flexGrow: 0.5,
      dataKey: "action",
    },
    {
      colName: "Provider",
      flexGrow: 0.7,
      dataKey: "provider_name",
    },
    {
      colName: "Application",
      flexGrow: 0.7,
      dataKey: "payer",
    },
    {
      colName: "Date",
      flexGrow: 0.4,
      dataKey: "created_at",
      CustomCell: ({ data }) => {
        return <div>{data?.split("T")[0]}</div>;
      },
    },
    {
      colName: "Remarks/Description",
      flexGrow: 2,
      dataKey: "description",
    },
  ];

  const handleActionbtn = (row) => {
    debugger;
  };
  const handleChangeForDate = (event) => {
    const { name, value } = event.target;
    if (name == "start") {
      setStartDate(value);
    }
    if (name == "end") {
      setEndDate(value);
    }
  };
  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setOptions("");
    setSelectedOption("")
    const emptyPayload = {
      page: 1,
      limit: Number(paginationData.limit),
      date_from: "",
      date_to: "",
    };

    let check = startDate == "" && endDate == "";
    if (check) {
    } else {
      fetchData(emptyPayload);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Users</label>

            <Select
              styles={customStyles}
              options={options || []}
              value={selectedOption ? selectedOption : ""}
              placeholder="Select an option"
              // Use the original Select component
              onChange={(selectedOption) =>
                handleChangeProvider(
                  Number(selectedOption.value),
                  selectedOption
                )
              }
            />
          </div>
        </div>

        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Start Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="start"
              placeholder=""
              value={startDate}
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">End Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="end"
              placeholder=""
              value={endDate}
              onChange={handleChangeForDate}
            />
          </div>
        </div>

        <div className="col-sm-12 col-md-3">
          <div className="form-group mt-2">
            <div className="d-flex align-items-center mt-4">
              <button className="btnClear mr-1" onClick={handleFilter}>
                Filter
              </button>
              <button type="button" onClick={handleClear} className="btnSave">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        Array={arr}
        dateForgrid={providerData.data}
        loader={Loadin}
        onChangePop={handleActionbtn}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        totalPages={providerData.totalPages}
        wordWrap={true}
      />
    </>
  );
}

export default UserProductivity;
