
import { Card, CardBody } from "reactstrap";

const TopCards = (props) => {
  return (
		<Card>
			{/* <CardBody >
        <div className="d-flex justify-content-between">
        <div className="dashIcon">
            <div className={`circle-box lg-box d-inline-block ${props.bg} icon`}>
              <i className={props.icon}></i>            
            </div>
            <h5 >{props.title}</h5>
        </div>
          <div><small className="dashStats">{props.subtitle}</small></div>
        </div>
      </CardBody> */}
			<CardBody>
				<div className="dashIcon">
					<div className="d-flex align-items-center justify-content-between">
						<div
							className={`circle-box lg-box d-inline-block ${props.bg} icon`}
						>
							<i className={props.icon}></i>
						</div>
						<small className="dashStats">{props.subtitle}</small>
					</div>
					<h5>{props.title}</h5>
				</div>
			</CardBody>
			{/* <CardBody >
        <div >
          <div className={`circle-box lg-box d-inline-block ${props.bg} icon`}>
            <i className={props.icon}></i>
          </div>
          <div className="icon ">
            <h5 className="mb-0  font-weight-bold">{props.title}</h5>
            <small className="text-muted  ">{props.subtitle}</small>
          </div>
        </div>
      </CardBody>   */}
		</Card>
	);
};

export default TopCards;
