
export const formVariable={
    id: 0,
    practice_id: '',
    practice_name: '',
    group_npi: '',
    individual_npi: '',
    last_name: '',
    middle_initial: '',
    name: '',
    tax_id: '',
    ssn: '',
    speciality: '',
   
    provider_identifier:'',
    taxonomy: '',
    taxonomy2: '',
    home_street: '',
    home_suite: '',
    home_city: '',
    home_state: '',
    home_county: '',
    home_zip: '',
    mailing_street: '',
    mailing_suite: '',
    mailing_city: '',
    mailing_state: '',
    mailing_county: '',
    mailing_zip: '',
    cell: '',
    gender: '',
    birth_county: '',
    birth_state: '',
    email: '',
    dob: '',
    password: '',
    software_name: '',
    owner_last_name: '',
    owner_middle_initial: '',
    owner_first_name: '',
    owner_email: '',
    owner_cell_number: '',
    owner_ssn: '',
    owner_driving_license: '',     
    owner_home_address: '',
    caqh_user: '',
    caqh_pass: '',
    caqh_id: '',
    pecos_user: '',
    pecos_pass: '',
    manager_name: '',
    manager_email: '',
    medicaid_id: '',
    grp_madicaid_id: '',
    grp_mrc_id: '',
    medicaid_user: '',
    medicare_id: '',
    website: '',
    dea: "",
    board_certification: "",
    dea_certification_number:'',
    hospital_affiliation: "",
    hospital_name:'',
    state_license_option: "",   
    provider_signup_date: "",
    dea_effective_date: "",
    dea_expiry_date: "",
    board_effective_date: "",
    board_certification_number:'',
    board_expiry_date: "",
    hospital_effective_date: "",
    hospital_expiry_date: "",
    state_license_date: "",
    state_license_number:'',
    state_license_expiry: "",
    owner_driving_license_date: "",
    owner_driving_license_expiry: "",
    state_license:"",
    owner_dob: "",
    status:'active',
    user_id:''
  }
  export const timeVariable = {    
    mon_open: "",
    mon_close: "",
    tue_open: "",
    tue_close: "",
    wed_open:  "",
    wed_close:  "",
    thu_open:  "",
    thu_close:  "",
    fri_open:  "",
    fri_close:  "",
    sat_open:  "",
    sat_close:  "",
    sun_open:  "",
    sun_close:  "",
   
  };

  export const applicationStatus = [
    { label: "New", value: "New" },
  { label: "approved", value: "approved" },
  { label: "participating", value: "participating" },
  { label: "Non-Participating", value: "Non-Participating" },
  { label: "in-process", value: "in-process" },
  { label: "pending-bm", value: "pending-bm" },
  { label: "pending-provider", value: "pending-provider" },
  { label: "rejected", value: "rejected" },
  { label: "not-eligible", value: "not-eligible" },
  { label: "panel-closed", value: "panel-closed" },
  ];

 


//Required Validation Model
