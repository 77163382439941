import React, { useEffect, useState } from "react";
import DataTable from "../../../Common/RsuitDataGrid/userTaskViewTable";
import { ProviderAudit } from "../../../Utils/ApiManager";
const ProvidersAudits = () => {
  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    limit: 10,
    page: 1,
    count: 0,
  });
  const [userdata, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValues] = useState();
  const viewDeatials=(row)=>{


  }
  const arr = [
    {
      colName: "Action",
      flexGrow: 0.6,
      dataKey: "event",
    },
    { colName: "User", flexGrow: 0.8, dataKey: "user_name" },
    {
      colName: "Time",
      flexGrow: 0.8,
      dataKey: "created_at",
      // CustomCell: ({ data }) => {
      //   let dsta = userdata?.records.find((item) => item?.id == data);
      //   console.log(dsta);
      //   let time = dsta?.new_values?.split('"')[3];
      //   return (
      //     <div>
      //       Date: {time?.split(" ")[0]}
      //       <br />
      //       Time:{time && time?.split(" ")[1]}
      //     </div>
      //   );
      // },
    },
    { colName: "Ip Address", flexGrow: 0.7, dataKey: "ip_address" },
    {
      colName: "Old Values",
      flexGrow: 0.8,
      dataKey: "id",
      // CustomCell: ({ data }) => {
      //   let dsta = userdata?.records?.find((item) => item?.id == data);
      //   // console.log(dsta);
      //   // let time = dsta?.old_values?.split('"')[3];
      //   return (
      //     <div>
      //       {dsta?.old_values}
      //       {/* <br />
      //       Time:{time && time?.split(" ")[1]} */}
      //     </div>
      //   );
      // },
    },

    {
      colName: "New Values",
      flexGrow: 2.8,
      dataKey: "id",
      // CustomCell: ({ data }) => {
       
      //   let dsta = userdata?.records?.find((item) => item?.id == data);
      //   let time = dsta?.new_values;
      // //  const objevcy= formatObjectToString(time)
      //   return (
      //     <div>
      //       {time}
      //       {/* {formatObjectToString(time)} */}
      //       {/* <span className="fas fa-eye pl-2" onClick={()=>viewDeatials(time)}></span> */}
           
      //     </div>
      //   );
      // },
    },

    {
      colName: "Resource",
      flexGrow: 1.3,
      dataKey: "id",
      CustomCell: ({ data }) => {
        console.log();
        let dsta = userdata?.records?.find((item) => item?.id == data);
        return (
          <div>
            name:{dsta?.provider_name}
            <br />
            email:{dsta?.provider_email}
          </div>
        );
      },
    },
  ];

  const fetchData = async (payload) => {
    setLoading(true);
    try {
      const data = await ProviderAudit(payload);
 setUserData( data?.data);
      setPaginationData((prevPaginationData) => ({
        ...prevPaginationData,
        count: data?.data?.totalCount,
      }));

      // console.log(data.data.records, "datata");
      const extractedValues = data?.data?.records.map(
        (record) => record.new_values
      );
      setValues(extractedValues);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
    };
    fetchData(payload);
  }, [paginationData.page, paginationData.limit]);
  const handleChangePage = (newPage) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      page: newPage,
    }));
  };
  const handleChangeLimit = (newLimit) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      limit: newLimit,
      page: 1,
    }));
  };
  const stringValues = [];

    // const b = value?.map((item) => item?.practice_id);
  //   console.log(value, "[[[[[");
  //   value?.forEach((object, index) => {
  //     stringValues[index] = "";
  //     console.log(object);
  //     const keys = Object.keys(JSON.parse(object)).map((key) => {
  //       // console.log(key)
  //       stringValues[index] += `${key} : ${JSON.parse(object)[key]} \n`;
  //     });
  //     stringValues[index] += "\n";
  //   });
  // console.log(stringValues);
  function formatObjectToString(object) {
    const stringValues = [];
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        stringValues.push(`${key} : ${object[key]}`);
      }
    }
    return stringValues.join('\n') + '\n';
  }
  return (
    <div>
      {" "}
      <DataTable
        Array={arr}
        dateForgrid={userdata?.records}
        loader={loading}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        totalPages={userdata?.totalCount}
        wordWrap={true}
      />
      {stringValues?.map((v) => (
        <pre>{v}</pre>
      ))}
    </div>
  );
};

export default ProvidersAudits;
