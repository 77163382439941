import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import DataTable from "../../../Common/RsuitDataGrid/userTaskViewTable";
import {
  getDataForviewDate,
  getSummaryData,
  getTeamWiseData,
} from "../../../Utils/ApiManager";
import Modal from "../../../Common/CommonModal";
import ViewProvideInfo from "../AppStatusReport/providerInfo";

let statuses = [
  "",
  "New",
  "approved",
  "participating",
  "Non-Participating",
  "in-process",
  "pending-bm",
  "pending-provider",
  "rejected",
  "not-eligible",
  "panel-closed",
];
let optionss = [
  { label: "All Teams", value: "" },
  { label: "Team A", value: 1 },
  { label: "Team B", value: 2 },
  { label: "Team C", value: 3 },
  { label: "Team D", value: 4 },
 
];

function TeamWiseReports() {
  const [providerData, setProvidersData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    limit: 10,
    page: 1,
    count: 0,
  });
  const [ProviderDataforForm, setProviderData] = useState("");
  const [Loadin, setLoadin] = useState(false);

  const [isModalOpenRemark, setIsModalOpenRemarks] = useState(false);

  const [practice_id, setPraacticeId] = useState();
  const [status, setStatus] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [IdForloader, setIdForloader] = useState({ id: null, name: null });

  const [selectedOption, setSelectedOption] = useState(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",

      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      marginTop: "2px",
      ":hover": {
        backgroundColor: "rgb(14, 116, 144)",
        cursor: "pointer",
        color: "white",
      },
    }),
  };
  useEffect(() => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      date_from: startDate ? startDate : "",
      date_to: endDate ? endDate : "",
      teamId: selectedOption ? selectedOption.value : "",
    };
    if (
      payload.date_from !== "" ||
      payload.date_to !== "" ||
      payload.teamId !== ""
    ) {
      fetchData(payload);
    } else {
      const payload = {
        page: Number(paginationData.page),
        limit: Number(paginationData.limit),
      };
      fetchData(payload);
    }
  }, [paginationData.page, paginationData.limit]);

  const handleChangeselect = (selectedValue, selectedOption) => {
    console.log(selectedValue, selectedOption,"[[[[[[[[[[");
    setSelectedOption(selectedOption);
    setPraacticeId(selectedValue);

    // fetchData({teamId:selectedValue})
  };

  const handleFilter = () => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      date_from: startDate ? startDate : "",
      date_to: endDate ? endDate : "",
      teamId: selectedOption.value ? selectedOption.value : "",
    };
    if (
      payload.date_from !== "" ||
      payload.date_to !== "" ||
      payload.teamId != ""
    ) {
      fetchData(payload);
    }
  };
  const fetchData = async (payload) => {
    setLoadin(true);
    try {
      const res = await getTeamWiseData(payload);
      let chatRows = [];

      res?.data?.data.forEach((d, index) => {
        let matchingRow = chatRows.find((row) => row.id === d.id);
        if (matchingRow !== undefined) {
          // If a matching row is found, update the corresponding status value with the total value
          matchingRow[d[`status`]] = d[`totalCount`];
        } else {
          // If no matching row is found, create a new row with only necessary properties
          let newRow = {
            id: d.id,
            name: d.name,
            teamId: d.teamId,
            status: d.status,
            totalCount: d.totalCount,
          };

          // Initialize status properties with 0
          statuses.forEach((status) => (newRow[status] = 0));

          // Update the corresponding status value with the total value
          newRow[d[`status`]] = d[`totalCount`];

          chatRows.push(newRow);
        }
      });

      setProvidersData(chatRows);

      // setProvidersData(data?.data);
     console.log(chatRows,"chatRows");
      setPaginationData((prevPaginationData) => ({
        ...prevPaginationData,
        totalPages: res.data.totalPayerProviders,
      }));
      console.log(chatRows, "chatRows");
      setLoadin(false);
    } catch (error) {
      console.error(error);
      setLoadin(false);
    }
  };

  const handleChangeLimit = (newLimit) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      limit: newLimit,
      page: 1,
    }));
  };

  const handleChangePage = (newPage) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      page: newPage,
    }));
  };

  const fetchProviderData = async (id, name) => {
    console.log(id, name);
    setIdForloader({ id, name });

    await getDataForviewDate({id:id})
      .then((res) => {
        console.log(res.data,"res.data");

        setProviderData(res.data);
        setIdForloader({ id: null, name: null });
        openModalRemarks();
      })
      .catch((error) => {
        setIdForloader({ id: null, name: null });
        toast("Error While Fetching");
      });
  };

  const openModalRemarks = () => {
    setIsModalOpenRemarks(true);
  };
  const closeModalRemarks = () => {
    setIsModalOpenRemarks(false);
  };

  const arr = [
    {
      colName: "Name",
      flexGrow: 1.6,
      dataKey: "id",

      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id == data);

        const fullName = provider
          ? `${provider.name ?? ""} ${provider.last_name ?? ""} ${
              provider.middle_initial ?? ""
            }`
          : "Not Found";
        return <div> {provider ? fullName : "Not Found"}</div>;
      },
    },

    {
      colName: "App Total",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item, i) => item?.id === data);

        const total = statuses.reduce((acc, value) => acc + provider[value], 0);

        return <div>{total}</div>;
      },
    },
    {
      colName: "New",
      flexGrow: 0.6,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item, i) => item?.id === data);

        const id = provider?.id ? provider?.id : "";
        return (
          <div>
            {provider?.["New"] || 0}
            {provider?.["New"] ? (
              IdForloader.id === id && IdForloader.name == "New" ? (

<></>                ) : (
               <></>
              )
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      colName: "Approved",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);
        const id = provider?.id ? provider?.id : "";
        return (
          <div>
            {provider?.["approved"] || 0}
            {provider?.["approved"] ? (
              IdForloader.id === id && IdForloader.name == "approved" ? (
                <></>
              ) : (
               <></>
              )
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      colName: "Participating",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : "";
        return (
          <div>
            {provider?.["participating"] || 0}
            {provider?.["participating"] ? (
              IdForloader.id === id && IdForloader.name == "participating" ? (
              <></>
              ) : (
              <></>
              )
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      colName: "Non-Participating",
      flexGrow: 1,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : "";
        return (
          <div>
            {provider?.["Non-Participating"] || 0}
            {provider?.["Non-Participating"] ? (
              IdForloader.id === id &&
              IdForloader.name == "Non-Participating" ? (
               <></>
              ) : (
               <></>
              )
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      colName: "In-Process",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : "";
        return (
          <div>
            {provider?.["in-process"] || 0}
            {provider?.["in-process"] ? (
              IdForloader.id === id && IdForloader.name == "in-process" ? (

<></>                ) : (
               <></>
              )
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      colName: "Pending-BM",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : "";
        return (
          <div>
            {provider?.["pending-bm"] || 0}
            {provider?.["pending-bm"] ? (
              IdForloader.id === id && IdForloader.name == "pending-bm" ? (
              <></>
              ) : (
              <></>
              )
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      colName: "Pending-Provider",
      flexGrow: 1,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : "";
        return (
          <div>
            {provider?.["pending-provider"] || 0}
            {provider?.["pending-provider"] ? (
              IdForloader.id === id &&
              IdForloader.name == "pending-provider" ? (
               
                <></>
              ) : (
               <></>
              )
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      colName: "Rejected",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : "";
        return (
          <div>
            {provider?.["rejected"] || 0}
            {provider?.["rejected"] ? (
              IdForloader.id === id && IdForloader.name == "rejected" ? (
               <></>
              ) : (
               <></>
              )
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      colName: "Not Eligible",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : "";
        return (
          <div>
            {provider?.["not-eligible"] ? provider?.["not-eligible"] : 0}
            {provider?.["not-eligible"] ? (
              IdForloader.id === id && IdForloader.name == "not-eligible" ? (
              <></>
              ) : (
               <></>
              )
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      colName: "Panel Closed",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);

        const id = provider?.id ? provider?.id : "";
        return (
          <div>
            {provider?.["panel-closed"] || 0}
            {provider?.["panel-closed"] ? (
              IdForloader.id === id && IdForloader.name == "panel-closed" ? (
               <></>
              ) : (
                <></>
              )
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      colName: "View",
      flexGrow: 0.8,
      dataKey: "id",
      CustomCell: ({ data }) => {
        const provider = providerData?.find((item) => item?.id === data);
  
        const id = provider?.id ? provider?.id : "";
        const fullName = provider
        ? `${provider.name ?? ""} ${provider.last_name ?? ""} ${
            provider.middle_initial ?? ""
          }`
        : "Not Found";
        return (
          <div>
          {provider ? (
            IdForloader.id === id? (
              <Loader size="xs" />
            ) : (
              <i
                className="fa fa-info-circle iconCol01 pl-2"
                onClick={() => {
                  fetchProviderData(id);
                }}
              />
            )
          ) : (
            ""
          )}
        </div>
        );
      },
    },
  ];

  const handleActionbtn = (row) => {
    debugger;
  };
  const handleChangeForDate = (event) => {
    const { name, value } = event.target;
    if (name == "start") {
      setStartDate(value);
    }
    if (name == "end") {
      setEndDate(value);
    }
  };
  const handleClear = () => {
    setStartDate("");
    setEndDate("");

    setSelectedOption(null);

    const emptyPayload = {
      page: 1,
      limit: Number(paginationData.limit),
      practice_id: "",
      speciality: "",
      status: "",
      home_state: "",
      date_from: "",
      date_to: "",
    };

    let check =
      practice_id == "" && status == "" && startDate == "" && endDate == "";
    if (check) {
    } else {
      fetchData(emptyPayload);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Teams</label>

            <Select
              styles={customStyles}
              options={optionss}
              value={selectedOption ? selectedOption : ""}
              placeholder="All Teams"
              // Use the original Select component
              onChange={(selectedOption) =>
                handleChangeselect(Number(selectedOption.value), selectedOption)
              }
            />
          </div>
        </div>

        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">Start Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="start"
              placeholder=""
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group mb-2 mr-2">
            <label className="inputLabel">End Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="end"
              placeholder=""
              onChange={handleChangeForDate}
            />
          </div>
        </div>

        <div className="col-sm-12 col-md-3">
          <div className="form-group mt-2">
            <div className="d-flex align-items-center mt-4">
              <button className="btnClear mr-1" onClick={handleFilter}>
                Filter
              </button>
              <button type="button" onClick={handleClear} className="btnSave">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        Array={arr}
        dateForgrid={providerData}
        loader={Loadin}
        onChangePop={handleActionbtn}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        totalPages={paginationData.totalPages}
        // footer={"<MyFooter />"}
      />

      <Modal
        isOpen={isModalOpenRemark}
        closeModal={closeModalRemarks}
        title={"Provider Information"}
      >
        <ViewProvideInfo data={ProviderDataforForm || ""} />{" "}
      </Modal>
    </>
  );
}

export default TeamWiseReports;