import React, { useEffect, useState } from "react";

import Select from "react-select";
import ImageUploaderr from "../../../Common/FileUpload";
import { useSelector } from "react-redux";
import {
  payersForDropDown,
  addNastsRecords,
  postEdiRemarks,
  updateNastsdata,
  ediremarksupdatede,
  UploadDocumentstoS3,
} from "../../../Utils/ApiManager";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader/Loader";
import axios from "axios";
import DocViewer from "../../../Common/DocViewer/DocViewer";
import AllDocumentsViewver from "../../../Common/AllDocumentsViewver";
import Modal from "../../../Common/CommonModal";

const UpdateEdiRecord = (props) => {
  const propsData = props.data;
  console.log(propsData, "props");
  let idofnasts = propsData?.id;
  console.log(idofnasts, "props");

  const ActionsOptions = [
    { value: propsData?.action, label: propsData?.action },
    { value: "EDI", label: "EDI" },
    { value: "ERA", label: "ERA" },
    { value: "ETF", label: "ETF" },
    { value: "Web", label: "Web" },
    { value: "DEMO", label: "DEMO" },
    { value: "Other", label: "Other" },
  ];
  const statusOptions = [
    { value: propsData?.status, label: propsData?.status },
    { value: "NEW", label: "NEW" },
    { value: "Pending", label: "Pending" },
    { value: "In-process", label: "In-process" },
    { value: "DEclined", label: "DEclined" },
    { value: "Completed", label: "Completed" },
    { value: "Canceled", label: "Canceled" },
  ];

  const { state, onLoginCredentialing, Specialities } = useSelector(
    (state) => state
  );
  // const [practice_id, setPraacticeId] = useState("");
  const [options, setOptionsForPracticeDropdown] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [ediDocandRemarks, setEdiDocandRemarks] = useState({
    docNmae: "",
    remarks: "",
  });
  const [file, setFile] = useState([]);

  const [idcheck, setIdcheck] = useState({
    id: "",
    flag: false,
  });
  const [data, setData] = useState({
    textAreaValue: ediDocandRemarks?.remarks,
    remarksId: "",
    actionValue: propsData?.action,
    actionlabel: propsData?.action,
    statusValue: propsData?.status,
    statusLabel: propsData?.status,
    ediDocumentid: "",
  });
  const [docview, setDocView] = useState(false);

  const getData = () => {
    const edidocumentsData = propsData?.edidocuments.map((document) => ({
      id: document.id,
      nast_id: document.nast_id,
      name: document.name,
    }));
    edidocumentsData.forEach((document) => {
      console.log(document.name, "dociii");
      setFile(document.name);
      setData((prev) => ({
        ...prev,
        ediDocumentid: document.id,
      }));
    });

    const ediRemarks = propsData?.ediremarks.map((remarks) => ({
      id: remarks.id,
      nast_id: remarks.nast_id,
      remarks: remarks.remarks,
    }));

    ediRemarks.forEach((remarks) => {
      console.log(remarks);
      setData((prev) => ({
        ...prev,
        textAreaValue: remarks.remarks,
        remarksId: remarks.id,
      }));
    });
  };

  const [isLoading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    practticeid: propsData?.practice.id,
    practice_label: propsData?.practice?.practice_name,
    payername: propsData?.Payer?.name,
    payerId: propsData?.Payer?.id,
    documenttype: "",
  });

  const practiceNames = Specialities?.map((item) => ({
    label: item.practice_name,
    value: item.practice_id,
  }));

  const getPayerName = async () => {
    try {
      const responce = await payersForDropDown();
      console.log(responce.data.data, "responce");
      const Dt = responce?.data?.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setProviderOptions(Dt);
    } catch (error) {}
  };

  useEffect(() => {
    setOptionsForPracticeDropdown(practiceNames);
    getData();
    const url = `${process.env.REACT_APP_LOCAL_SERVER_URL}nasts/updateNastsRecord/${idofnasts}`;
    console.log(url, "url");
    // fetchDocumnetTypes();
    getPayerName();
  }, []);
  console.log();
  const handleChangeselect = (selectedValue, selectedOption) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      practticeid: selectedOption?.value,
      practice_label: selectedOption?.label,
    }));
    // setPraacticeId(selectedValue);
  };
  const handleChangeProvider = (selectedValue, selectedOption) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      payername: selectedOption?.label,
      payerId: selectedOption?.value,
    }));
  };

  const handleInputChange = (event) => {
    console.log(event, "event");
    setFile(event[0]);
  };

  console.log(file, "image");
  console.log(selectedOption.practticeid, "practice_id");
  console.log(selectedOption.payerId, "payerid");
  console.log(selectedOption.documenttype.value, "documentstype id ");

  const PostEdidoc = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", file.name);
      formData.append("file", file);
      formData.append("practice_id", payload.practice_id);
      formData.append("user_id", payload.user_id);
      formData.append("payer_id", payload.payer_id);
      formData.append("action", payload.action);
      formData.append("status", payload.status);
      formData.append("nast_id", payload.nast_id);
      formData.append("remarks", payload.remarks);
      formData.append("remark_id", payload.reamrk_id);
      formData.append("docid", payload.docid);
      console.log(formData, "hello Setup");
      UploadDocumentstoS3(formData, "edi").then((responce) => {
        if (responce.status) {
          toast.success("data uploaded success");
        }
        props.modalCloseCall();
        console.log(responce, "responce1");
      });

      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error("Required fields are missing");
      } else {
        toast.error("An error occurred while uploading the data and file");
      }
      setLoading(false);
    }
  };

  const payload = {
    practice_id: selectedOption?.practticeid,
    user_id: onLoginCredentialing?.user?.id,
    payer_id: selectedOption?.payerId,
    action: data?.actionValue,
    status: data?.statusValue,
    nast_id: idofnasts,
    remarks: data.textAreaValue ? data.textAreaValue : "",
    reamrk_id: data?.remarksId,
    docid: data?.ediDocumentid,
  };

  const updateNastsadatarecord = async () => {
    try {
      const responce = await updateNastsdata(idofnasts, payload);
      console.log(responce, "addresponce");
      if (responce.status == 200) {
        toast.success("Success");
        //     setIdcheck((prevState) => ({
        //       ...prevState,
        //       id: responce?.data?.nastId,
        //     }));
      }
      return responce; // Return the response
    } catch (error) {
      setIdcheck((prevState) => ({
        ...prevState,
        id: "",
      }));
      console.log(error, "addresponce");
      toast.error("Something went wrong");
    }
  };

  console.log(idcheck, "idcheck");
  //   const postedireamkrsupdated = async () => {
  //     const payload = {
  //       nast_id: idofnasts,
  //       user_id: onLoginCredentialing?.user?.id,
  //       remarks: data.textAreaValue ? data.textAreaValue : "",
  //     };
  //     try {
  //       const responce = await ediremarksupdatede(data.remarksId, payload);
  //       console.log(responce, "postRemarks");
  //       return responce;
  //     } catch (error) {}
  //   };

  const handleSave = async () => {
    // setLoading(true);
    // const addResponse = await updateNastsadatarecord();
    // console.log(addResponse, "addResponse");
    // let responcePostremarks;
    // if (addResponse?.status === 200 && addResponse.data?.nastId) {
    //   let id = addResponse.data?.nastId;
    //   responcePostremarks = await postRemarks(id);
    // }
    // console.log(responcePostremarks, "responcePostremarks");
    // if (
    //   addResponse?.status === 200 &&
    //   responcePostremarks.status === 200 &&
    //   addResponse.data?.nastId
    // ) {
    //   let id = addResponse.data?.nastId;
    //   await PostEdidoc(id);
    // }
    setLoading(false);
  };
  const handleChange = (selectedOption) => {
    const { name, value } = selectedOption.target;

    console.log(name, value, "selectedOptionselectedOption");
    if (name == "action") {
      setData((prevFormData) => ({
        ...prevFormData,
        actionlabel: value,
        actionValue: value,
      }));
    }
    if (name == "status") {
      setData((prevFormData) => ({
        ...prevFormData,
        statusLabel: value,
        statusValue: value,
      }));
    }
    if (name == "textAreaValue") {
      setData((prevFormData) => ({
        ...prevFormData,

        textAreaValue: value,
      }));
    }
  };
  const onClear = () => {
    // setData({ status: "", action: "", textAreaValue: "" });
    setData((prev) => ({
      ...prev,
      statusValue: " ",
      actionValue: " ",
      textAreaValue: "",
    }));
    setSelectedOption((prevState) => ({
      ...prevState,
      practticeid: "",
      payername: "",
      payerId: "",
      practice_label: "",
    }));
    // setPraacticeId("");
    console.log("fsdfsdf");
  };
  console.log(data, "data");
  return (
    <div class="container ">
      <div
        class="row"
        style={{
          backgroundColor: "#F0F8FF",
          paddingRight: "10px",
          borderRadius: "7px",
          border: "1px solid #F0F8FF",
        }}
      >
        <div class="col-sm-6 col-lg-4 mb-3">
          <div class="form-group mb-2 ">
            <label for="staticEmail2 " class=" mt-3">
              <h4>Select Practice </h4>
            </label>
            <Select
              options={options ? options : ""}
              placeholder="Select an option"
              value={[
                {
                  label: selectedOption?.practice_label,
                  value: selectedOption?.practticeid,
                },
              ]}
              // Use the original Select component
              onChange={(selectedOption) => {
                handleChangeselect(
                  Number(selectedOption.value),
                  selectedOption
                );
              }}
            />{" "}
          </div>
        </div>

        <div class="col-sm-6 col-lg-4">
          <div class="form-group mb-2 ">
            <label for="staticEmail2 " class=" mt-3">
              <h4>payer Name </h4>
            </label>
            <Select
              options={providerOptions ? providerOptions : ""}
              placeholder="Please Select Practice"
              value={[
                {
                  label: selectedOption?.payername,
                  value: selectedOption?.payerId,
                },
              ]}
              onChange={(selectedOption) => {
                handleChangeProvider(
                  Number(selectedOption.value),
                  selectedOption
                );
              }}
            />{" "}
          </div>
        </div>

        <div class="col-sm-6 col-lg-6">
          <div class="form-group form-inline  mb-2">
            <label class="inputLabel pt-2  w-100" style={{ color: "black" }}>
              Action
            </label>
            <select
              className=" inputField2"
              name="action"
              placeholder="Please select state"
              value={data?.actionValue}
              onChange={handleChange}
            >
              {ActionsOptions.map((option) => {
                return <option value={option.value}>{option.label}</option>;
              })}
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-lg-6">
          <div class="form-group form-inline  mb-2">
            <label class="inputLabel pt-2  w-100" style={{ color: "black" }}>
              Status
            </label>
            <select
              className=" inputField2"
              name="status"
              placeholder=""
              value={data?.statusValue}
              onChange={handleChange}
            >
              {statusOptions.map((option) => {
                return <option value={option.value}>{option.label}</option>;
              })}
            </select>
          </div>
        </div>
        <div class="col-sm-6 col-lg-12">
          <div class="form-group1 form-inline  mb-2">
            <label
              class="inputLabel pt-2    "
              style={{ color: "black", marginRight: "6px" }}
            >
              Remarks
            </label>

            <textarea
              class=" inputField1  "
              type="text"
              rows="5"
              cols="70"
              name="textAreaValue"
              placeholder=""
              value={data.textAreaValue}
              onChange={handleChange}
            />
          </div>
        </div>
        <div class=" col-lg-12 mt-2">
          <div class="form-group1 ">
            <label class="inputLabel" style={{ color: "black" }}>
              Please Select a File
            </label>
            <ImageUploaderr
              handleInputChange={handleInputChange}
              image={file}
              openImageViwerModel={() => {
                setDocView(true);
              }}
            />
          </div>
        </div>

        <div class="row col-lg-12 d-flex">
        {file.length>0 ? (
              <Modal
                isOpen={docview}
                closeModal={() => setDocView(false)}
                title={"Viewe"}
              >
                <AllDocumentsViewver folderName={"edi"} fileName={file} />
              </Modal>
            ) : (
              ""
            )}
        </div>
        <div class="col-sm-6 col-lg-12 mt-2">
          <div class="form-group ">
            <div className="d-flex justify-content-end mt-4">
              <button onClick={onClear} className="btnClear mr-1 ">
                Clear
              </button>
              <button
                onClick={PostEdidoc}
                disabled={isLoading}
                type="button"
                className="btnSave"
              >
                Save{" "}
                {isLoading ? (
                  <Loader small={true} large={false} forButton={true} />
                ) : undefined}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEdiRecord;
