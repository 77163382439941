import React from "react";
import "./addProviderMain.css";
import Select from "react-select";
import { useState } from "react";
import AddProviderForm from "./AddProviderForm";
import { useSelector } from "react-redux";
import { vlidation } from "./validation";

function AddNewProvider({ dataForEdit, closeModal, usaStatesOptions }) {
  const { practiceName } = useSelector((state) => state);
  let objj = {
    practiceid: false,
  };

  const [selectedOptionns, setSelectedOption] = useState([]);
  const [practice_id, setPraacticeId] = useState(null);
  const [error, setError] = useState(objj);
  const practiceValidation = async (name) => {
    if (name) {
      setError({ practiceid: false });
    } else {
      let obj = { practiceid: practice_id };
      let a = await vlidation(obj);
      setError({ practiceid: a.practiceid?.hasError || false });
    }
  };

  const objCss = {
    // Define your custom styles here
    control: (provided) => ({
      ...provided,
      borderRadius: "4px",
      border: "1px solid #ccc",
      boxShadow: "none",
      width: "300px",
    }),
    // Add more styles as needed
  };

  const handleChangeselect = (selectedValue, selectedOption) => {
    setSelectedOption(selectedOption);
    setPraacticeId(selectedValue);
  };
  console.log(practice_id);
  return (
    <>
      <div className=" bg-white ">
        <div className=" row mt-1">
          <div
            className=""
            id="accordion"
            role="tablist"
            aria-multiselectable="true"
          >
            <>
              <div className="panel panel-default">
                <div id="collapseOne">
                  <form className="form-inline col-lg-12">
                    <div className="col-lg-2"></div>
                    <div className="form-group justify-content-end col-lg-10 mb-2">
                      <label for="staticEmail2 " className="mx-4 mt-3">
                        <h5>Select Practice </h5>
                      </label>
                      <div className="d-flex">
                        <Select
                          styles={objCss}
                          options={practiceName ? practiceName : []}
                          value={selectedOptionns ? selectedOptionns : ""}
                          onFocus={() => {
                            practiceValidation("Practice");
                          }}
                          placeholder="Please Select Practice"
                          onChange={(selectedOptionns) =>
                            handleChangeselect(
                              Number(selectedOptionns.value),
                              selectedOptionns
                            )
                          }
                        />
                        <p className="text-danger pl-1">
                          {error.practiceid && "Please select practice"}{" "}
                        </p>
                      </div>
                    </div>
                  </form>
                  <AddProviderForm
                    practiceId={{ ...practice_id, ...selectedOptionns }}
                    practiceValidation={practiceValidation}
                    errors={error.practiceid}
                    dataForEdit={dataForEdit}
                    closeModal={closeModal}
                    handleChangeselect={handleChangeselect}
                    usaStatesOptions={usaStatesOptions}
                  />
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddNewProvider;
