import React, { useEffect, useState } from "react";
import Modal from "../../../Common/CommonModal";
import Select from "react-select";
import { deletePractice, getPracticesRecords } from "../../../Utils/ApiManager";
import DataTable from "../../../Common/RsuitDataGrid/userTaskViewTable";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddNew from "../AddNewpractices/addnew";
import Swal from "sweetalert2";

function PracticeMain(props) {
  const { statesCodes } = useSelector((state) => state);

  const [dateForgrid, setDataForGrid] = useState([]);

  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    limit: 10,
    page: 1,
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [usaStatesOptions, setUsaStesOptions] = useState([]);
  const [UsaState, setUsaState] = useState("");
  const [UsaStateLabel, setUsaStateLabel] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dataUpdate, setDataUpdate] = useState({ type: "", id: "" });

  const handleUpdateInfo = (rowData) => {
    setDataUpdate({ type: "edit", id: rowData });
  };
  useEffect(() => {
    if (dataUpdate?.id) {
      openModal();
    }
  }, [dataUpdate]);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this record?",
      text: "",
      icon: "warning", // 'type' has been removed as it's deprecated; use 'icon' instead.
      showCancelButton: true,
      confirmButtonColor: "#7ac0f5",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true); // Assuming you have a function called setLoading to update loading state.
        deletePractice(id)
          .then((res) => {
            setLoading(false);
            toast("Deleted Successfully");
            fetchPracticeData();
          })
          .catch((err) => {
            setLoading(false);
            toast(err?.message); // Assuming you have a toast function to display error messages.
          });
      }
    });
  };

  const columns = [
    { colName: "Practice Name", flexGrow: 2, dataKey: "practice_name" },
    { colName: "Practice Code", flexGrow: 0.5, dataKey: "practice_code" },
    { colName: "Total Provider", flexGrow: 0.5, dataKey: "provider_count" },
    {
      colName: "Created Date",
      flexGrow: 0.5,
      dataKey: "created_at",
      CustomCell: ({ data }) => {
        return <div>{data?.split("T")[0]}</div>;
      },
    },
    {
      colName: "Action",
      flexGrow: 0.3,
      dataKey: "id",
      CustomCell: ({ data }) => (
        <>
          <i
            className="fas fa-pencil-alt pe-3 iconCol02"
            onClick={() => handleUpdateInfo(data)}
          ></i>
          <i
            className="fas fa-trash iconCol03 pe-2"
            onClick={() => handleDelete(data)}
          ></i>
        </>
      ),
    },
  ];

  const fetchUsaStates = () => {
    const formattedOptions = statesCodes?.StatesCodes?.map((item) => ({
      value: item.code,
      label: item.state,
    }));
    setUsaStesOptions(formattedOptions);
  };

  const openModal = () => {
    props?.addNew(props?.name);
  };

  const closeModal = () => {
    setDataUpdate({ type: "", id: "" });
    props?.addNew("");
  };

  const handleStateChange = (selectedValue) => {
    setUsaStateLabel(selectedValue.label)
    setUsaState(selectedValue.value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "rgb(14, 116, 144)" : "",
      zIndex: 9999999999,
      marginTop: "2px",
      minHeight: "10px",

      ":hover": {
        backgroundColor: "rgb(14, 116, 144)",
        cursor: "pointer",
        color: "white",
      },
    }),
  };

  useEffect(() => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
       service_state: UsaState ? UsaState : "",
      dateFrom: startDate ? startDate : "",
      dateTo: endDate ? endDate : "",

    };
    fetchPracticeData(payload);
    fetchUsaStates();
  }, [paginationData.page, paginationData.limit]);

  const fetchPracticeData = async (payload) => {
    try {
      setLoading(true);
      const res = await getPracticesRecords(payload);

      console.log(res?.data, "hhhhhhhhh");

      setDataForGrid(res?.data.practices.map((p) => ({ ...p, ...p.practice })));
      setPaginationData((prevPaginationData) => ({
        ...prevPaginationData,
        count: res?.data.totalCount,
      }));
      setDataUpdate({ type: "", id: "" });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  console.log(dateForgrid, "dateForgrid");


  
  const handleChangeLimit = (newLimit) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      limit: newLimit,
      page: 1,
    }));
  };

  const handleFilter = () => {
    const payload = {
      page: Number(paginationData.page),
      limit: Number(paginationData.limit),
      service_state: UsaState ? UsaState : "",
      dateFrom: startDate ? startDate : "",
      dateTo: endDate ? endDate : "",
    };
    if (
      payload.practice_id ||
      payload.speciality ||
      payload.status ||
      payload.service_state ||
      payload.dateFrom ||
      payload.dateTo
    ) {
      fetchPracticeData(payload);
    }
  };

  const handleChangePage = (newPage) => {
    setPaginationData((prevPaginationData) => ({
      ...prevPaginationData,
      page: newPage,
    }));
  };

  const handleChangeForDate = (event) => {
    const { name, value } = event.target;
    if (name === "start") {
      setStartDate(value);
    }
    if (name === "end") {
      setEndDate(value);
    }
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setUsaState("");
    const emptyPayload = {
      page: 1,
      limit: Number(paginationData.limit),
      service_state: "",
      dateFrom: "",
      dateTo: "",
    };

    let check = startDate === "" && endDate === "" && UsaState === "";
    if (check) {
      return;
    } else {
      fetchPracticeData(emptyPayload);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <label className="inputLabel">All States</label>
            <Select
              options={usaStatesOptions ? usaStatesOptions : []}
              placeholder="Select an option"
              styles={customStyles}
              value={UsaStateLabel?{ label: UsaStateLabel }:''}
              onChange={(value) => handleStateChange(value)}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <label className="inputLabel">Start Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="start"
              placeholder=""
              value={startDate}
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <label className="inputLabel">End Date</label>
            <input
              className="form-control inputField"
              type="date"
              name="end"
              placeholder=""
              value={endDate}
              onChange={handleChangeForDate}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="form-group">
            <div className="d-flex mt-4">
              <button onClick={handleFilter} className="btnClear mr-1">
                Filter
              </button>
              <button onClick={handleClear} type="button" className="btnSave">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <DataTable
        dateForgrid={dateForgrid ?? []}
        Array={columns}
        loader={loading}
        onChangePage={handleChangePage}
        onChangeLimit={handleChangeLimit}
        totalPages={paginationData.count}
      />

      <Modal
        isOpen={props?.addNewButton === props.name ? true : false}
        closeModal={closeModal}
        title={"Add New Practice"}
      >
        <AddNew data={dataUpdate} closeModal={closeModal} />
      </Modal>
    </>
  );
}
export default PracticeMain;
