import React, { useState } from "react";
import { useSelector } from "react-redux";




import ViewRemarks from "../../QuickUpdates/ViewRemarks";
import Modal from "../../../Common/CommonModal";
import { getReemarks } from "../../../Utils/ApiManager";
import Loader from "../../../Common/Loader/Loader";




function ViewProvideInfo({ data }) {
    const [ProviderDataforForm, setProviderData] = useState('');
    const [isModalOpenRemark, setIsModalOpenRemarks] = useState(false);
    const { payerName } = useSelector((state) => state)
    const [loadingIndex, setLoadingIndex] = useState(null);

 
 
 
    const [btnloader, setBtnLoader] = useState(false);
    const getRemarks = async (a) => {
        console.log(a);
        setBtnLoader(true);
        try {

            const responce = await getReemarks(a);
           
            setProviderData(responce?.data);

            setBtnLoader(false);
            setLoadingIndex(null);
            if (responce.status == 200) {
                setIsModalOpenRemarks(true)
            }
        } catch (error) {
            console.log(error, "errorR");
            setBtnLoader(false);
            setLoadingIndex(null);
        }
    };



    return (
			<div className="modal-body w-100">
				<div className="section-heading">
					<span className="font-weight-bold">Personal</span>
				</div>
				<div className="provider_formbox">
					<div className="form-group">
						<div className="tableStyle">
							<table className="table" width="100%">
								<thead>
									<tr>
										<th>Provider Name</th>
										<th>Individual NPI</th>
										<th>Group NPI</th>
										<th>Tax ID</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<label id="provider_name">{data?.provider?.name}</label>
										</td>
										<td>
											<label id="individual_npi">
												{data?.provider?.individual_npi}
											</label>
										</td>
										<td>
											<label id="group_npi">{data?.provider?.group_npi}</label>
										</td>
										<td>
											<label id="tax_id">{data?.provider?.tax_id}</label>
										</td>
									</tr>
									<tr></tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="section-heading">
					<span class="font-weight-bold">Enrollment</span>
				</div>
				<div className="provider_formbox">
					<div className="form-group">
						<div class="tableStyle">
							<table width="100%">
								<thead>
									<tr>
										<th>Payer Name</th>
										<th>Status</th>
										<th>Provider Id</th>
										<th>Remarks</th>
										
									</tr>
								</thead>
								<tbody id="enrollment_rows">
									{data?.payerProviders.map((item, index) => {
										return (
											<>
												<tr>
													<td>
														{item?.payer_id
															? payerName.find(
																	(i) => i.value === item?.payer_id
															  )?.label
															: "N/A"}{" "}
													</td>
												
													<td>{item?.status ? item?.status : "N/A"}</td>
													<td>
														{item?.provider_identifier ? item?.provider_identifier : "N/A"}
													</td>
													<td>
														{" "}
														{btnloader && loadingIndex === index ? (
															<Loader small={true} large={false} />
														) : (
															<i
																class="fa fa-eye "
																onClick={() => {
																	let a = {
																		provider_id: item?.provider_id,
																		payer_id: item?.payer_id,
																	};
																	setLoadingIndex(index);
																	getRemarks(a);
																}}
															></i>
														)}
													</td>{" "}
												</tr>
											</>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<Modal
					isOpen={isModalOpenRemark}
					closeModal={() => setIsModalOpenRemarks(false)}
					title={"Provider Information"}
				>
					<ViewRemarks data={ProviderDataforForm || ""} />{" "}
				</Modal>
			</div>
		);
}

export default ViewProvideInfo;
