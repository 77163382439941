import React, { useState, useEffect } from "react";
import { Button, Pagination, Table } from "rsuite";
import "rsuite/dist/rsuite.css";
import "rsuite-table/dist/css/rsuite-table.css";
import AdvancedAnalyticsIcon from "@rsuite/icons/AdvancedAnalytics";
import { act } from "@testing-library/react";

const { Column, HeaderCell, Cell } = Table;

const DataTable = ({
	dateForgrid,
	loader,
	Array,
	onChangePop,
	totalPages,
	onChangePage,
	onChangeLimit,
	wordWrap
}) => {
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);

	const PopUpCell = ({ rowData, onChangePop, ...props }) => (
		<>
			{" "}
			<Cell {...props} style={{ padding: 5 }}>
				<AdvancedAnalyticsIcon
					onClick={() => onChangePop(rowData)}
					style={{ color: "#0e7490" }}
				/>
			</Cell>
		</>
	);

	const handlePageChange = (newPage) => {
		onChangePage(newPage);
		setPage(newPage);
	};

	const handleLimitChange = (newLimit) => {
		onChangeLimit(newLimit);
		setLimit(newLimit);
		setPage(1);
	};

	return (
		<div >
			<div className="mb-4">
				<Table
					height={400}
					data={dateForgrid}
					cellBordered
					rowHeight={35}
					loading={loader}
					wordWrap={wordWrap ? "break-word" : ''}
				>
					{Array &&
						Array?.map((Item, index) => (
							<Column key={index} flexGrow={Item.flexGrow} align="left" resizable>
								<HeaderCell
									style={{
										backgroundColor: "#fff",
										color: "#7ac0f5",
										borderBottom: "3px solid",
										borderTop: "2px solid",
										borderColor: "#7ac0f5",
										fontSize: "0.805rem",
										lineHeight: "14px",
										fontWeight: "700",
									}}
								>
									{Item.colName}
								</HeaderCell>
								{Item?.popupCell ? (
									<PopUpCell onChangePop={onChangePop} />
								) : Item.CustomCell ? (
									<Cell
										dataKey={Item.dataKey}
										renderCell={(rowData) => <Item.CustomCell data={rowData} />}
									/>
								) : Item.actions ? (
									<Cell
										dataKey={Item.dataKey}
										renderCell={(rowData) => (
											<div
												style={{
													justifyContent: "row",
													display: "flex",
												}}
											>
												{Item.actions.map((action, index) => (
													<>

														<Button
															loading={
																action.label == "fa fa-times" &&
																	action.id === rowData
																	? loader
																	: false
															}
															key={index}
															onClick={() => action.onClick(rowData)}
														>
															<i className={action.label}></i>
														</Button>
													</>
												))}
											</div>
										)}
									/>
								) : (
									<Cell dataKey={Item.dataKey} />
								)}
							</Column>
						))}
				</Table>
			</div>
			<Pagination
				prev
				next
				first
				last
				ellipsis
				boundaryLinks
				maxButtons={5}
				size="xs"
				layout={["total", "-", "limit", "|", "pager", "skip"]}
				limitOptions={[10, 30, 50]}
				limit={limit}
				total={totalPages}
				activePage={page}
				onChangePage={handlePageChange}
				onChangeLimit={handleLimitChange}
			/>
		</div>
	);
};

export default DataTable;
