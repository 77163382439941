import React, { useState, useEffect, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import "./sidenav.css";

export const Sidenav = ({ toggleSidenavBar, toogleSideNav }) => {
  const { NavigateDasboardsIssue,onLoginCredentialing } = useSelector((state) => state);
  console.log(onLoginCredentialing.user,"state");
  const [Active, setActive] = useState("Dashboard");
  const [ToogleOnMobile, setToogleOnMobile] = useState(false);
  const [dropDownopToogle, setDropdownToogle] = useState("");
   
  useLayoutEffect(() => {
    const width = window.innerWidth;
    if (width < 992) {
      setToogleOnMobile(true);
    }
  }, []);
  useEffect(() => {
    if (NavigateDasboardsIssue) {
      setActive("Dashboard");
    }
  }, [NavigateDasboardsIssue]);

  const handleClick = (value) => {
    if (ToogleOnMobile === true && toogleSideNav == true) {
      toggleSidenavBar();
    }
    setActive(value);
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  };

  // const onMouseEnterFun = (val) => {
  //   setIsHover(val)

  // }
  // const onMouseLeave = () => {
  //   setIsHover(null)

  // }
  return (
    <>
      <nav
        className={
          toogleSideNav
            ? "sidebar sidebar-offcanvas active"
            : "sidebar sidebar-offcanvas"
        }
        id="sidebar"
      >
        <ul className="nav" style={{ position: "fixed" }}>
          {/* <span  ><Dashboard  /></span> */}
          <>
            {" "}
            <li
              className={
                Active === "Dashboard" ? " nav-item  active" : " nav-item  "
              }
            >
              <NavLink
                onClick={() => {
                  setDropdownToogle("");
                  handleClick("Dashboard");
                }}
                className={({ isActive }) =>
                  isActive ? "nav-link nav-item active" : "nav-link nav-item"
                }
                to={"/dashboard"}
              >
                {/* <span  ><DashboardIcon  /></span> */}

                <i
                  className="fas fa-th navIconSz"
                  style={
                    Active === "Dashboard"
                      ? { color: "white" }
                      : { color: "#4D5961" }
                  }
                ></i>

                <span className="menu-title ">Dashboard</span>
              </NavLink>
            </li>
          </>
          {/* ***** End***** */}

          {/* <span  ><QuickUpdates /></span> */}
          <>
            {" "}
            <li
              className={
                Active === "QuickUpdateForm1"
                  ? " nav-item  active"
                  : " nav-item  "
              }
            >
              <NavLink
                onClick={() => {
                  setDropdownToogle("");
                  handleClick("QuickUpdateForm1");
                }}
                className={({ isActive }) =>
                  isActive ? "nav-link nav-item active" : "nav-link nav-item"
                }
                to={"/QuickUpdateForm1"}
              >
                <i
                  className="fas fa-rss-square navIconSz"
                  style={
                    Active === "QuickUpdateForm1"
                      ? { color: "white" }
                      : { color: "#4D5961" }
                  }
                ></i>

                <span className="menu-title ">Quick Updates</span>
              </NavLink>
            </li>
          </>
          {/* ***** End***** */}

          {/* <span  ><Practice  /></span> */}

          <>
            <li
              className={
                Active === "Practices" ? "nav-item active" : "nav-item"
              }
            >
              <NavLink
                onClick={() => {
                  handleClick("Practices");
                  setDropdownToogle((pre) =>
                    pre === "Practices" ? "" : "Practices"
                  );
                }}
                className={({ isActive }) =>
                  isActive ? "nav-link nav-item" : "nav-link nav-item"
                }
                to={"Practices/ViewPractices"}
              >
                <i
                  className="fas fa-stethoscope navIconSz"
                  style={
                    Active === "Practices"
                      ? { color: "white" }
                      : { color: "#4D5961" }
                  }
                ></i>
                <span className="menu-title">
                  Practices
                  {/* <span style={{ marginLeft: "-3px" }}></span>
                  <i
                    className={
                      dropDownopToogle === "Practices"
                        ? "arrow menu-title up" : "arrow menu-title down"

                    }
                  ></i>{" "} */}
                </span>
              </NavLink>
            </li>

            {/* <div className={dropDownopToogle === "Practices" ? "dropDownop mt-1" : "displayDropdown"}>
              <ul style={{ marginTop: "-1px", marginBottom: "-1px" }} className="nav">
                <li className={Active === "AddPractices" ? "nav-item active" : "nav-item"}>
                  <NavLink
                    onClick={() => handleClick("AddPractices")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item" : "nav-link nav-item"
                    }
                    to={"/Practices/AddPractices"}
                  >
                    <i
                      className="fas fa-regular fa-clipboard pe-3"
                      style={Active === "AddPractices" ? { color: 'white' } : { color: "#0e7490" }}
                    ></i>
                    <span className="menu-title fontSize">Add New Practices</span>
                  </NavLink>
                </li>
                <li className={Active === "ViewPractices" ? "nav-item active" : "nav-item"}>
                  <NavLink
                    onClick={() => handleClick("ViewPractices")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item" : "nav-link nav-item"
                    }
                    to={"/Practices/ViewPractices"}
                  >
                    <i
                      className="fas fa-solid fa-user-plus pe-3"
                      style={Active === "ViewPractices" ? { color: 'white' } : { color: "#0e7490" }}
                    ></i>
                    <span className="menu-title fontSize">View All Practices</span>
                  </NavLink>
                </li>
              </ul>
            </div> */}
          </>

          {/* ***** End***** */}
          {/* // NAST */}

          <>
            <li className={Active === "NAST" ? "nav-item active" : "nav-item"}>
              <NavLink
                onClick={() => {
                  handleClick("NAST");
                  setDropdownToogle((pre) => (pre === "NAST" ? "" : "NAST"));
                }}
                className={({ isActive }) =>
                  isActive ? "nav-link nav-item" : "nav-link nav-item"
                }
                to={"NAST/ViewAllEntry"}
              >
                <i
                  className="fas fa-list-alt navIconSz"
                  style={
                    Active === "NAST"
                      ? { color: "white" }
                      : { color: "#4D5961" }
                  }
                ></i>
                <span className="menu-title pe-3">
                  NAST<span style={{ marginLeft: "21px" }}></span>
                  {/* <i
                    className={
                      dropDownopToogle === "NAST"
                        ? "arrow menu-title up" : "arrow menu-title down"

                    }
                  ></i> */}
                </span>
              </NavLink>
            </li>
          </>

          {/* ***** End***** */}

          {/* // Provider */}

          <>
            <li
              className={
                Active === "Providers" ? "nav-item active" : "nav-item"
              }
            >
              <NavLink
                onClick={() => {
                  handleClick("Providers");
                  setDropdownToogle((pre) =>
                    pre === "Providers" ? "" : "Providers"
                  );
                }}
                className={({ isActive }) =>
                  isActive ? "nav-link nav-item" : "nav-link nav-item"
                }
                to={"/Providers/ViewAllProividers"}
              >
                <i
                  className="fas fa-user-md navIconSz"
                  style={
                    Active === "Providers"
                      ? { color: "white" }
                      : { color: "#4D5961" }
                  }
                ></i>
                <span className="menu-title">Providers</span>
              </NavLink>
            </li>
          </>

          {/* ***** End***** */}

          {/* // Documents */}

          <>
            <li
              className={Active === "Document" ? "nav-item active" : "nav-item"}
            >
              <NavLink
                onClick={() => {
                  handleClick("Document");
                  setDropdownToogle((pre) =>
                    pre === "Document" ? "" : "Document"
                  );
                }}
                className={({ isActive }) =>
                  isActive ? "nav-link nav-item" : "nav-link nav-item"
                }
                to={"Document/ViewAllDocument"}
              >
                <i
                  className="fas fa-file-medical navIconSz"
                  style={
                    Active === "Document"
                      ? { color: "white" }
                      : { color: "#4D5961" }
                  }
                ></i>
                <span className="menu-title">Document </span>
              </NavLink>
            </li>
          </>

          {/* ***** End***** */}
          {/* // Payers */}

          <>
            <li
              className={Active === "Payers" ? "nav-item active" : "nav-item"}
            >
              <NavLink
                onClick={() => {
                  handleClick("Payers");
                  setDropdownToogle((pre) =>
                    pre === "Payers" ? "" : "Payers"
                  );
                }}
                className={({ isActive }) =>
                  isActive ? "nav-link nav-item" : "nav-link nav-item"
                }
                to={"Payers/ViewAllPayers"}
              >
                <i
                  className="fas fa-comment-dollar navIconSz"
                  style={
                    Active === "Payers"
                      ? { color: "white" }
                      : { color: "#4D5961" }
                  }
                ></i>
                <span className="menu-title">Payers</span>
              </NavLink>
            </li>
          </>

          {/* ***** End***** */}

          {/* // Users */}

          <>

          { onLoginCredentialing?.user?.id==2344 ?<></>:
          <>   

          <li
              className={Active === "Users" ? " nav-item active" : " nav-item "}
            >
              <NavLink
                onClick={() => {
                  handleClick("Users");
                  setDropdownToogle((pre) => (pre === "Users" ? "" : "Users"));
                }}
                className={({ isActive }) =>
                  isActive ? "nav-link nav-item " : "nav-link nav-item "
                }
                to={"Users/ViewAllUsers"}
              >
                <i
                  className="fas fa-users navIconSz pe-3"
                  style={
                    Active === "Users"
                      ? { color: "white" }
                      : { color: "#4D5961" }
                  }
                ></i>
                <span className="menu-title">Users</span>
              </NavLink>
            </li>
            <div
              className={
                dropDownopToogle === "Users"
                  ? "dropDown mt-1"
                  : "displayDropdown"
              }
            >
              <ul
                style={{ marginTop: "-1px", marginBottom: "-1px" }}
                className="nav"
              >
                <li
                  className={
                    Active === "ViewAllUsers"
                      ? " nav-item active"
                      : " nav-item "
                  }
                >
                  <NavLink
                    onClick={() => handleClick("ViewAllUsers")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item " : "nav-link nav-item "
                    }
                    to={"Users/ViewAllUsers"}
                  >
                    <i
                      className="fas fa-solid fa-user-plus pe-3"
                      style={
                        Active === "ViewAllUsers"
                          ? { color: "white" }
                          : { color: "#0e7490" }
                      }
                    ></i>
                    <span className="menu-title fontSize">View All Users</span>
                  </NavLink>
                </li>

                {/* <li
                  className={
                    Active === "InactiveUsers"
                      ? " nav-item active"
                      : " nav-item "
                  }
                >
                  <NavLink
                    onClick={() => handleClick("InactiveUsers")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item " : "nav-link nav-item "
                    }
                    to={"Users/InactiveUsers"}
                  >
                    <i
                      className="fas fa-regular fa-clipboard pe-3"
                      style={
                        Active === "InactiveUsers"
                          ? { color: "white" }
                          : { color: "#0e7490" }
                      }
                    ></i>
                    <span className="menu-title fontSize">Inactive Users</span>
                  </NavLink>
                </li> */}
              </ul>
            </div>
            <>
            <li
              className={Active === "Reports" ? "nav-item active" : "nav-item"}
            >
              <NavLink
                onClick={() => {
                  handleClick("AppstatusReports");
                  setDropdownToogle((pre) =>
                    pre === "Reports" ? "" : "Reports"
                  );
                }}
                className={({ isActive }) =>
                  isActive ? "nav-link nav-item" : "nav-link nav-item"
                }
                to={"Reports/AppstatusReports"}
              >
                <i
                  className="fas fa-file-pdf navIconSz"
                  style={
                    Active === "Reports"
                      ? { color: "white" }
                      : { color: "#4D5961" }
                  }
                ></i>
                <span className="menu-title">
                  Reports
                  <span className=" pe-2" style={{ marginLeft: "1px" }}></span>
                  <i
                    className={
                      dropDownopToogle === "Reports"
                        ? "arrow menu-title up"
                        : "arrow menu-title down"
                    }
                  ></i>{" "}
                </span>
              </NavLink>
            </li>

            <div
              className={
                dropDownopToogle === "Reports"
                  ? "dropDownop mt-1"
                  : "displayDropdown"
              }
            >
              <ul
                style={{ marginTop: "-1px", marginBottom: "-1px" }}
                className="nav"
              >
                <li
                  className={
                    Active === "AppstatusReports"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink
                    onClick={() => handleClick("AppstatusReports")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item" : "nav-link nav-item"
                    }
                    to={"Reports/AppstatusReports"}
                  >
                    <i
                      className="fas fa-regular fa-clipboard pe-3"
                      style={
                        Active === "AppstatusReports"
                          ? { color: "white" }
                          : { color: "#0e7490" }
                      }
                    ></i>
                    <span className="menu-title fontSize">
                      App Status Report
                    </span>
                  </NavLink>
                </li>
                <li
                  className={
                    Active === "TeamWiseReports"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink
                    onClick={() => handleClick("TeamWiseReports")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item" : "nav-link nav-item"
                    }
                    to={"Reports/TeamWiseReports"}
                  >
                    <i
                      className="fas fa-solid fa-user-plus pe-3"
                      style={
                        Active === "TeamWiseReports"
                          ? { color: "white" }
                          : { color: "#0e7490" }
                      }
                    ></i>
                    <span className="menu-title fontSize">
                      Team Wise Reports
                    </span>
                  </NavLink>
                </li>

                <li
                  className={
                    Active === "UserProductivity"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink
                    onClick={() => handleClick("UserProductivity")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item" : "nav-link nav-item"
                    }
                    to={"Reports/UserProductivity"}
                  >
                    <i
                      className="fas fa-solid fa-user-plus pe-3"
                      style={
                        Active === "UserProductivity"
                          ? { color: "white" }
                          : { color: "#0e7490" }
                      }
                    ></i>
                    <span className="menu-title fontSize">
                      User Productivity
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </>
          <>
            <li className={Active === "Audit" ? "nav-item active" : "nav-item"}>
              <>
                {" "}
                <NavLink
                  onClick={() => {
                    handleClick("AuditRemarks");
                    setDropdownToogle((pre) =>
                      pre === "Audit" ? "" : "Audit"
                    );
                  }}
                  className={({ isActive }) =>
                    isActive ? "nav-link nav-item" : "nav-link nav-item"
                  }
                  to={"Audit/AuditRemarks"} // Change "Reports" to "Audit" here
                >
                  <i
                    className="fas fa-file-pdf navIconSz"
                    style={
                      Active === "Audit" // Change "Reports" to "Audit" here
                        ? { color: "white" }
                        : { color: "#4D5961" }
                    }
                  ></i>
                  <span className="menu-title">
                    Audit {/* Change "Reports" to "Audit" here */}
                    <span
                      className=" pe-2"
                      style={{ marginLeft: "1px" }}
                    ></span>
                    <i
                      className={
                        dropDownopToogle === "Audit" // Change "Reports" to "Audit" here
                          ? "arrow menu-title up"
                          : "arrow menu-title down"
                      }
                    ></i>{" "}
                  </span>
                </NavLink>
              </>
            </li>

            <div
              className={
                dropDownopToogle === "Audit" // Change "Reports" to "Audit" here
                  ? "dropDownop mt-1"
                  : "displayDropdown"
              }
            >
              <ul
                style={{ marginTop: "-1px", marginBottom: "-1px" }}
                className="nav"
              >
                <li
                  className={
                    Active === "AuditRemarks" ? "nav-item active" : "nav-item"
                  }
                >
                  <NavLink
                    onClick={() => handleClick("AuditRemarks")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item" : "nav-link nav-item"
                    }
                    to={"Audit/AuditRemarks"} // Change "Reports" to "Audit" here
                  >
                    <i
                      className="fas fa-regular fa-clipboard pe-3"
                      style={
                        Active === "AuditRemarks"
                          ? { color: "white" }
                          : { color: "#0e7490" }
                      }
                    ></i>
                    <span className="menu-title fontSize">Remarks</span>
                  </NavLink>
                </li>

                <li
                  className={
                    Active === "ProvidersAudits"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink
                    onClick={() => handleClick("ProvidersAudits")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item" : "nav-link nav-item"
                    }
                    to={"Audit/ProvidersAudits"} // Change "Reports" to "Audit" here
                  >
                    <i
                      className="fas fa-solid fa-user-plus pe-3"
                      style={
                        Active === "ProvidersAudits"
                          ? { color: "white" }
                          : { color: "#0e7490" }
                      }
                    ></i>
                    <span className="menu-title fontSize">Providers</span>
                  </NavLink>
                </li>

                <li
                  className={
                    Active === "UsersAudits" ? "nav-item active" : "nav-item"
                  }
                >
                  <NavLink
                    onClick={() => handleClick("UsersAudits")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item" : "nav-link nav-item"
                    }
                    to={"Audit/UsersAudits"} // Change "Reports" to "Audit" here
                  >
                    <i
                      className="fas fa-solid fa-user-plus pe-3"
                      style={
                        Active === "UsersAudits"
                          ? { color: "white" }
                          : { color: "#0e7490" }
                      }
                    ></i>
                    <span className="menu-title fontSize">User</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </>
            </>
            
            }
         

          
          </>

          {/* ***** End***** */}

          {/* // Reports */}

        

          {/* ***** End***** */}
          {/* Audit */}

       
          {/* audit end */}
          {/* // Activity */}

          <>
            <li
              className={Active === "Activity" ? "nav-item active" : "nav-item"}
            >
              <NavLink
                onClick={() => {
                  handleClick("Activity");
                  setDropdownToogle((pre) =>
                    pre === "Activity" ? "" : "Activity"
                  );
                }}
                className={({ isActive }) =>
                  isActive ? "nav-link nav-item" : "nav-link nav-item"
                }
                to={"Activity/AllActivites"}
              >
                <i
                  className="fas fa-toolbox navIconSz"
                  style={
                    Active === "Activity"
                      ? { color: "white" }
                      : { color: "#4D5961" }
                  }
                ></i>
                <span className="menu-title">
                  Activity
                  <span className=" pe-2" style={{ marginLeft: "6px" }}></span>
                  <i
                    className={
                      dropDownopToogle === "Activity"
                        ? "arrow menu-title up"
                        : "arrow menu-title down"
                    }
                  ></i>{" "}
                </span>
              </NavLink>
            </li>

            <div
              className={
                dropDownopToogle === "Activity"
                  ? "dropDownop mt-1"
                  : "displayDropdown"
              }
            >
              <ul
                style={{ marginTop: "-1px", marginBottom: "-1px" }}
                className="nav"
              >
                <li
                  className={
                    Active === "LogDailyActivity"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink
                    onClick={() => handleClick("LogDailyActivity")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item" : "nav-link nav-item"
                    }
                    to={"Activity/LogDailyActivity"}
                  >
                    <i
                      className="fas fa-regular fa-clipboard pe-3"
                      style={
                        Active === "LogDailyActivity"
                          ? { color: "white" }
                          : { color: "#0e7490" }
                      }
                    ></i>
                    <span className="menu-title fontSize">
                      Log Daily Activity
                    </span>
                  </NavLink>
                </li>
                <li
                  className={
                    Active === "AllActivites" ? "nav-item active" : "nav-item"
                  }
                >
                  <NavLink
                    onClick={() => handleClick("AllActivites")}
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-item" : "nav-link nav-item"
                    }
                    to={"Activity/AllActivites"}
                  >
                    <i
                      className="fas fa-solid fa-user-plus pe-3"
                      style={
                        Active === "AllActivites"
                          ? { color: "white" }
                          : { color: "#0e7490" }
                      }
                    ></i>
                    <span className="menu-title fontSize">All Activities</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </>

          {/* ***** End***** */}

          <li className={Active === "settings" ? " nav-item " : " nav-item "}>
            <NavLink
              onClick={() => handleLogout("logout")}
              className={({ isActive }) =>
                isActive ? "nav-link nav-item " : "nav-link nav-item"
              }
              to="/"
            >
              <i
                className="fas fa-sign-out-alt navIconSz"
                style={{ color: "#4D5961" }}
              ></i>
              <span className="menu-title">{"  "} Logout</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};
