import React, { useState } from "react";
import { createNewPayer, updatePayerName } from "../../../Utils/ApiManager";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader/Loader";

const AddPayer = (props) => {
  const { data } = props;
  console.log(props, "data");
  const [payerName, setPayerName] = useState(data ? data.datUpdate.name : "");
  const [isLoading, setLoading] = useState(false);

  const handlePayerNameChange = (e) => {
    setPayerName(e.target.value);
  };
  const handleSave = () => {
    if (data.type) {
      let id = data.datUpdate.id;
      const trimmedName = payerName.trim(); // Trim spaces from payerName
      const payload = {
        name: trimmedName,
      };
      if (trimmedName !== "") {
        updatePayer(id, payload);
      } else {
        toast.info("Please enter a valid Name");
      }
    } else {
      const trimmedName = payerName.trim(); // Trim spaces from payerName
      const payload = {
        name: trimmedName,
      };
  
      if (trimmedName !== "") {
        createPayer(payload);
      } else {
        toast.info("Please enter a valid Name");
      }
    }
  };
  
  const handleClear = () => {
    setPayerName("");
  };
  const createPayer = async (payload) => {
    setLoading(true);
    try {
      const responce = await createNewPayer(payload);
      console.log(responce);
      if (responce.status == 200) {
        toast.success("new Payer created SuccessFully ");
      }
      setLoading(false);
      props.modalCloseCall()
    } catch (error) {
      setLoading(false);

      console.log("responce", error);
      if (error.response.status == 400) {
        toast.error("Payer Name missing");
      }
    }
  };
  const updatePayer = async (id, newName) => {
    setLoading(true);

    try {
      console.log(id, newName);
      const response = await updatePayerName(id, newName);
      console.log(response, "responce");
      if (response.status == 200) {
        toast.success("Name Updated sucess");
      }
      setLoading(false);
      props.modalCloseCall()

    } catch (error) {
      console.log(error.response, "error");
      if (error.response.status == 400) {
        toast.error("Name is missing");
      }
      setLoading(false);
      props.modalCloseCall()

      // handle the error
    }
  };
  console.log(payerName, "payerName");
  return (
    <div
      className=""
      style={{
        // backgroundColor: "#F0F8FF",
        // paddingRight: "10px",
        // borderRadius: "20px",
        // border: "1px solid #F0F8FF",
        // marginLeft: "10px",
      }}
    >
      <div className="col-sm-6 col-lg-12 mt-3" style={{ width: "500px" }}>
        <div className="form-group form-inline">
          <label className="inputLabel pt-2 w-100" style={{ color: "black" }}>
            Payer Name
          </label>
          <input
            className="form-control inputField w-100"
            type="text"
            name="sbf"
            placeholder=""
            value={payerName}
            onChange={handlePayerNameChange}
          />
        </div>
      </div>
      <div className="col-sm-6 col-lg-12 mt-2">
        <div className="form-group">
          <div className="d-flex justify-content-end mt-4">
            <button onClick={handleClear} className="btnClear mr-1">
              Clear
            </button>
            <button onClick={handleSave} type="button" className="btnSave">
              Save
              {isLoading ? (
                <Loader small={true} large={false} forButton={true} />
              ) : undefined}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPayer;
