import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader/Loader";
import { useNavigate } from "react-router-dom";
// import "../../Assets/css/login.css";
import { LoginCredientials } from "../../Utils/ApiManager";
import { onLoginCredentialing } from "../../Actions/Actions";
import { toast } from "react-toastify";
import LoginSvg from '../../Assets/images/LoginPage.svg'



function Login() {
  //-------------- All Global variable Section-----------
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  //----------Alll States -------------------
  const [username, setUsername] = useState("");

  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingg, setLoadingg] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const openModal = () => {
    setToggleModal(true);
  };



  useEffect(() => {
    if (username) {
    } else {
      setError(false);
    }
  }, [username]);

  const loginUserr = async () => {

    setLoading(true);
    if (username === "") {
      setUserNameError(true);
      setLoading(false);
      return;
    } else {
      setUserNameError(false);
    }
    if (password === "") {
      setPasswordError(true);
      setLoading(false);
      return;
    } else {
      setPasswordError(false);
    }
    const formData = new FormData()
    formData.append('email', username)
    formData.append('password', password)
    LoginCredientials(formData).then((el) => {
      dispatch(onLoginCredentialing(el.data))
      if (el.data.message === 'Successfully logged in') {
        navigate("/Dashboard");
      }
    }).catch((response) => {
      if (response?.message === "Network Error") {
        setLoading(false)
        toast.info(response?.message)
      }

      if (response?.response?.status === 422) {
        setLoading(false)
        toast.info(response.data.message)
      }
      if (response?.response?.status == 404) {
        setLoading(false)
        toast.info(response.response.data.message)
      }
	  else{
		setLoading(false)
        toast.info("Internal Servel Error")
	  }

    })

  };

  const onClear = () => {
    setUsername('')
    setPassword('')
  }


  const isValidName = () => {
    if (username) {
    } else {
      setError(false);
    }
    if (username === '' || username === null) {
      setError(false);
    }
  };
  const handleUserName = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    value = value.toLowerCase();
    if (name === "username") {

      setError(false);
      setUsername(value);
    } else {
      setPassword(value);
    }
  };

  const onEnterLogin = (e) => {
    if (e.keyCode === 13) {
      loginUserr();
    }
  };

  return (
		<>
			{loadingg ? (
				<Loader small={false} large={true} forButton={true} />
			) : (
				<>
					<div className="loginPg">
						<div className="loginPgVertLine"></div>
						<div className="loginPgContent">
							<img src={LoginSvg}></img>
						</div>
						<div className="loginPgForm">
							<form>
								<div className="mb-4"></div>
								<h3 className="mt-4">Login to your account!</h3>
								<div className="formGroup mt-4">
									<label>Enter email</label>

									<input
										className="form-control"
										type="text"
										name="username"
										id="username"
										value={username}
										placeholder="Email "
										onChange={handleUserName}
										onKeyDown={onEnterLogin}
										aria-describedby={userNameError ? "invalid" : "valid"}
										onBlur={() => {
											if (username) isValidName();
										}}
									/>

									<span className="fielderror">
										{userNameError ? <span>UserName is Empty</span> : ""}
									</span>
									<small id="emailHelp" className="form-text text-muted">
										We'll never share your email with anyone else.
									</small>
								</div>
								<div className="formGroup mt-4">
									<label>Enter password</label>
									<div className="d-flex">
										<input
											type={showPassword ? "text" : "password"}
											className="form-control"
											name="password"
											id="your_pass"
											placeholder="Password"
											onChange={handleUserName}
											onKeyDown={onEnterLogin}
											value={password}
											aria-describedby={passwordError ? "invalid" : "valid"}
										/>
										<div className="input-group-prepend">
											<div className="input-group-text">
												<i
													className="fa fa-eye viewIconCol"
													onClick={() => setShowPassword((prev) => !prev)}
													aria-hidden="true"
												></i>
											</div>
										</div>
									</div>

									<span className="fielderror">
										{passwordError ? <span>Password is Empty</span> : ""}
									</span>
									<div className="mt-3">
										<a href="#/"> Forgot Password?</a>
									</div>
								</div>
								<div className="d-flex justify-content-center mt-4">
									<button
										type="button"
										className="btnOutline01 mr-1"
										onClick={onClear}
									>
										Clear
									</button>
									<button
										type="button"
										className="btnFilled02"
										id="loginBtn"
										onClick={loginUserr}
										disabled={isLoading}
									>
										Login
										{isLoading ? (
											<Loader small={true} large={false} forButton={true} />
										) : undefined}
									</button>
								</div>
							</form>
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default Login;
