import React, { useEffect } from "react";
import QuickUpdateForm1 from "./QuickUpdateForm1";


function QuickMain() {



 

 

  return (
    <>
      <div class=" ">
        <div
              class=""
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >

              <>
                <div
                  class="panel panel-default"

                >            

                  <div
                    id="collapseOne"
                    className={

                      "panel-collapse collapse  show"

                    }
                    
                  >

                    <QuickUpdateForm1 />

                  </div>
                </div>
              </>
            </div>
        </div>

    </>

  );
}
export default QuickMain;
