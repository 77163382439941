import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { viewNastDocumnets } from "../Utils/ApiManager";
import Loader from "./Loader/Loader";

const AllDocumentsViewver = ({ folderName, fileName }) => {
  console.log(fileName, "hhhhhhhhhh");
  console.log(folderName, "hhhhhhhhhh");
  const [fileData, setFileData] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [filetype, setFileType] = useState("");
  const [hasRenders, setHasrender] = useState(false);
  const fileParts = fileName.split(".");
  const fileExtension = fileParts[fileParts.length - 1].toLowerCase();

  let mimeType = "";
  let filetype;
  if (fileExtension === "pdf") {
    mimeType = "application/pdf";
    filetype = "pdf";
  } else if (fileExtension === "png") {
    mimeType = "image/png";
    filetype = "png";
  } else if (fileExtension === "docx" || fileExtension === "doc") {
    mimeType = "application/msword";
    filetype = "docx";
  } else if (fileExtension === "xlsx" || fileExtension === "xls") {
    mimeType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    filetype = "xlsx";
  } 

  useEffect(() => {
    if (fileName) {
      setIsLoading(true);

      viewNastDocumnets(folderName, fileName)
        .then((res) => {
          const blob = new Blob([res.data], { type: mimeType });

          if (filetype === "xlsx") {
            const reader = new FileReader();
            reader.onload = (e) => {
              const data = e.target.result;
              const workbook = XLSX.read(data, { type: "array" });
              const firstSheetName = workbook.SheetNames[0];
              const sheetData = XLSX.utils.sheet_to_json(
                workbook.Sheets[firstSheetName],
                { header: 1 }
              );

              setFileData(sheetData);
              setIsLoading(false);
            };

            reader.readAsArrayBuffer(blob);
          } else {
            const url = URL.createObjectURL(blob);
            setFileUrl(url);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  }, [fileName]);

  return (
    <div className="bg-lightGray mt-auto ">
      {isLoading && <Loader large={true} />}

      {["pdf", "docx", "xlsx"].includes(filetype) ? (
        filetype === "xlsx" ? (
          <table className="min-w-full divide-y divide-gray-200">
            <tbody className="bg-white divide-y divide-gray-200">
              {fileData?.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={rowIndex % 2 === 0 ? "bg-gray-50" : ""}
                >
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <iframe
            src={fileUrl}
            title="Embedded Document"
            width="1000"
            height="600"
            frameBorder="0"
            allowFullScreen
            style={{
              width: "100%", // Set the width to 75% of the parent container
              border: "none", // Remove the border if you want
            }}
          ></iframe>
        )
      ) : filetype === "png" ? (
        <img
          src={fileUrl}
          alt="EmbeddedImage"
          style={{ width: "100%", height: "600px" }}
        />
      ) : (
        <div>Unsupported file format</div>
      )}
    </div>
  );
};

export default AllDocumentsViewver;
