import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import "../src/Assets/css/style.css";
import App from "./App";
import "../src/Assets/css/style1.css";
import "../src/Assets/css/sidebar.css";
import "./App.css";
import "../src/Assets/css/base.css";
import { Provider } from 'react-redux'
import Store from "../src/Store/Store"
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from 'react-router-dom'



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <HashRouter>
    <Provider store={Store}>
      <App />
    </Provider>
  </HashRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
