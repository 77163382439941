import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import bg1 from "../../Assets/images/bg/bg1.jpg";
import bg2 from "../../Assets/images/bg/bg2.jpg";
import bg3 from "../../Assets/images/bg/bg3.jpg";
import bg4 from "../../Assets/images/bg/bg4.jpg";
import TopCards from "./TopCards";
import ProjectTables from "./ProjectTables";
import SalesChart from "./Charts";
import Feeds from "./Reports";
import {
  getPracticesForDropDown,
  getUsaSatesCodes,
  gettaxonomyasspecialityAndProvidersAgainstPractices,
  payersForDropDown,
} from "../../Utils/ApiManager";
import { useDispatch } from "react-redux";
import {
  Specialities,
  payerName,
  practiceName,
  statesCodes,
} from "../../Actions/Actions";

function Dashboard() {
  let dispatch = useDispatch();
  const [record, getRecords] = useState({
    providersCount: "",
    documentCount: "",
    payersCount: "",
    usersCount: "",
    practicesCount: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchSpeciality();
    fetchOptions();
    fetchUsaStates();
    getPayerName();
  }, []);

  const getPayerName = async () => {
    try {
      const responce = await payersForDropDown();

      const Dt = responce?.data?.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      getRecords((pev) => ({
        ...pev,
        payersCount: responce?.data?.count,
      }));
      dispatch(payerName(Dt));
      setLoading(false)
    } catch (error) {setLoading(false)}
  };
  const fetchOptions = async () => {
    try {
      const response = await getPracticesForDropDown();

      const data = response?.data?.practices;
      getRecords((pev) => ({
        ...pev,
        practicesCount: response?.data?.practiceCount,
      }));
      const formattedOptions = data.map((item) => ({
        value: item.id, // Replace 'fieldName' with the actual field name from your API response
        label: item.practice_code + "-" + item.practice_name, // Replace 'fieldValue' with the actual field value from your API response
      }));

      dispatch(practiceName(formattedOptions));
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };
  const fetchSpeciality = async () => {
    setLoading(true)
    try {
      gettaxonomyasspecialityAndProvidersAgainstPractices().then((res) => {
        dispatch(Specialities(res?.data?.providers));
        getRecords((prev) => ({
          ...prev,
          providersCount: res?.data?.providerCount,
        }));
      });
    } catch (error) {}
  };

  const fetchUsaStates = async () => {
    try {
      await getUsaSatesCodes().then((res) => {
        dispatch(statesCodes(res?.data));
      });
    } catch (error) {}
  };
  const BlogData = [
    {
      image: bg1,
      title: "This is a simple blog",
      subtitle: "2 comments, 1 Like",
      description:
        "This is a wider card with supporting text below as a natural lead-in to additional content.",
      btnbg: "primary",
    },
    {
      image: bg2,
      title: "Let's be a simple blog",
      subtitle: "2 comments, 1 Like",
      description:
        "This is a wider card with supporting text below as a natural lead-in to additional content.",
      btnbg: "primary",
    },
    {
      image: bg3,
      title: "Don't Lamp blog",
      subtitle: "2 comments, 1 Like",
      description:
        "This is a wider card with supporting text below as a natural lead-in to additional content.",
      btnbg: "primary",
    },
    {
      image: bg4,
      title: "Simple is beautiful",
      subtitle: "2 comments, 1 Like",
      description:
        "This is a wider card with supporting text below as a natural lead-in to additional content.",
      btnbg: "primary",
    },
  ];
  console.log(record, "record");
  return (
    <>
     {loading ? (
        <div className="loaderbgpopup">
          <div className="loaderdivpopup"></div>
        </div>
      ):""}
      <Container fluid >
        <div className="col-sm-12">
          <div className="dashboardCards">
            <div className="dashBrdCard">
              <TopCards
                bg="bg-light-success text-success"
                title="Providers"
                subtitle={record?.providersCount}
                earning="Providers"
                icon="fa fa-medkit"
              />
            </div>
            <div className="dashBrdCard">
              <TopCards
                bg="bg-light-danger text-danger"
                title="Documents"
                subtitle="(6666)"
                earning="Refund given"
                icon="fas fa-file-pdf"
              />
            </div>
            <div className="dashBrdCard">
              <TopCards
                bg="bg-light-warning text-warning"
                title="Payers"
                subtitle={record.payersCount}
                earning="Payers"
                icon="fas fa-paste"
              />
            </div>
            <div className="dashBrdCard">
              <TopCards
                bg="bg-light-info text-info"
                title="Users"
                subtitle="(6666)"
                earning="Users"
                icon="fas fa-user-alt"
              />
            </div>
            <div className="dashBrdCard">
              <TopCards
                bg="bg-light-info text-info"
                title="Practices"
                subtitle={record?.practicesCount}
                earning="Practices"
                icon="fas fa-file-alt"
              />
            </div>
          </div>
        </div>
        {/* Top Cards */}
        {/* <Row className="mb-3 justify-content-center">
          <Col sm="6" lg="2">
            <TopCards
              bg="bg-light-success text-success"
              title="Providers"
              subtitle="(6666)"
              earning="Providers"
              icon="fa fa-medkit"
            />
          </Col>
          <Col sm="6" lg="2">
            <TopCards
              bg="bg-light-danger text-danger"
              title="Documents"
              subtitle="(6666)"
              earning="Refund given"
              icon="fas fa-file-pdf"
            />
          </Col>
          <Col sm="6" lg="2">
            <TopCards
              bg="bg-light-warning text-warning"
              title="Payers"
              subtitle="(6666)"
              earning="Payers"
              icon="fas fa-paste"
            />
          </Col>
          <Col sm="6" lg="2">
            <TopCards
              bg="bg-light-info text-info"
              title="Users"
              subtitle="(6666)"
              earning="Users"
              icon="fas fa-user-alt"
            />
          </Col>
          <Col sm="6" lg="2">
            <TopCards
              bg="bg-light-info text-info"
              title="Practices"
              subtitle="(6666)"
              earning="Practices"
              icon="fas fa-file-alt"
            />
          </Col>
        </Row> */}
      </Container>

      <Container fluid>
        {/* Sales & Feed */}
        <Row className="mb-3">
          <Col sm="12" md="12" xl="7" xxl="8">
            <SalesChart />
          </Col>
          <Col sm="12" md="6" xl="5" xxl="4">
            <Feeds />
          </Col>
        </Row>

        {/* Table */}
        <Row className="mb-3">
          <Col>
            <ProjectTables />
          </Col>
        </Row>

        {/* Blog Cards */}
        <Row>
          {BlogData.map((blg, index) => (
            <Col sm="6" lg="6" xl="3" key={index}>
              {/* <Blog 
                image={blg.image}
                title={blg.title}
                subtitle={blg.subtitle}
                text={blg.description}
                color={blg.btnbg}
              /> */}
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
